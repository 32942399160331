import React from 'react';
import { BaseProps } from '../../types/componentType';
import SimpleEdit from '../../components/organisms/page/SimpleEdit';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useTitle from '../../hooks/generic/titleSet';
import { useMSkillsEdit, useMSkillsShow } from '../../hooks/api/mSkillsHook';
import { useMasterMSkills } from '../../hooks/api/mastersHook';

interface Props extends BaseProps {}

const MOccupationsEdit: React.FC<Props> = (props) => {
  useTitle(props.title);

  const showHooks = useMSkillsShow();
  const editHooks = useMSkillsEdit();
  const mastersHooks = useMasterMSkills();

  const [{ option }, { setOption: doEdit }] = editHooks;
  const [{ isLoading, contents }] = mastersHooks;

  console.log(isLoading);
  const validSchema = Yup.object().shape({
    name: Yup.string().required('必須項目です'),
    category: Yup.string().oneOf(
      (contents.category ?? []).map((obj: any) => obj.id),
      'いずれかを選択してください',
    ),
  });

  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: '',
      category: '',
    },
    resolver: yupResolver(validSchema),
  });

  const submitHandler = (data: any) => {
    const changedParam = {
      ...option.json,
      name: data.name,
      category: data.category,
    };
    doEdit({ ...option, json: changedParam, id: props.match.params.id });
  };

  const formInits = [
    { name: 'name', label: '名前', descendant: 'name', type: 'text' },
    {
      name: 'category',
      label: 'category',
      descendant: 'category',
      type: 'select',
      selects: contents.category,
    },
  ];

  return (
    <>
      <SimpleEdit
        id={props.match.params.id}
        resource={props.resource}
        editHooks={editHooks}
        showHooks={showHooks}
        formHooks={formHooks}
        submitHandler={submitHandler}
        forms={formInits}
        redirect={`/${props.resource}`}
      />
    </>
  );
};

export default MOccupationsEdit;
