import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import BusinessIcon from '@material-ui/icons/Business';
import ComputerIcon from '@material-ui/icons/Computer';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import DnsIcon from '@material-ui/icons/Dns';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';

// ContactMail

export const INIT_MENU_LIST = [
  {
    expanded: true,
    icon: null,
    text: '営業管理',
    children: [
      {
        text: '出向計画一覧',
        component: LibraryBooksIcon,
        href: '/loan_plans',
      },
      {
        text: '出向計画作成',
        component: LibraryAddIcon,
        href: '/loan_plans/create',
      },
      { text: '提案一覧', component: ContactMailIcon, href: '/suggestions' },
      { text: '面談一覧', component: PeopleOutlineIcon, href: '/interviews' },
      { text: '出向一覧', component: AssignmentIndIcon, href: '/loans' },
    ],
  },
  {
    expanded: true,
    icon: null,
    text: '顧客管理',
    children: [
      { text: 'クライアント一覧', component: BusinessIcon, href: '/clients' },
      {
        text: 'クライアント作成',
        component: AddCircleOutlineIcon,
        href: '/clients/create',
      },
      { text: '案件一覧', component: ComputerIcon, href: '/m_projects' },
      {
        text: '案検作成',
        component: AddToQueueIcon,
        href: '/m_projects/create',
      },
    ],
  },
  {
    expanded: true,
    icon: null,
    text: 'スタッフ',
    children: [
      { text: 'スタッフ一覧', component: PersonIcon, href: '/staffs' },
      {
        text: 'スタッフ作成',
        component: PersonAddIcon,
        href: '/staffs/create',
      },
      {
        text: '職種マスタ一覧',
        component: RecentActorsIcon,
        href: '/m_occupations',
      },
      {
        text: '職種マスタ作成',
        component: AddCircleOutlineIcon,
        href: '/m_occupations/create',
      },
      {
        text: '工程マスタ一覧',
        component: DnsIcon,
        href: '/m_milestones',
      },
      {
        text: '工程マスタ作成',
        component: AddCircleOutlineIcon,
        href: '/m_milestones/create',
      },
      {
        text: 'スキルマスタ一覧',
        component: FitnessCenterIcon,
        href: '/m_skills',
      },
      {
        text: 'スキルマスタ作成',
        component: AddCircleOutlineIcon,
        href: '/m_skills/create',
      },
    ],
  },
];
