import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import { CircularProgress } from '@material-ui/core';
import TableSearch from './TableSearch';
import {
  useApiResponseNg,
  useApiResponseOkAndReload,
} from '../../../hooks/api/generic/apiResponseHandling';
import { ApiStates } from '../../../hooks/api/type/ApiStates';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import TablePagination from './TablePagination';
import TableBody, { SimpleTableColumn } from './TableBody';

interface Props {
  children?: React.ReactNode;
  indexHooks: ApiStates<any, any>;
  deleteHooks: ApiStates<any, any>;
  columns: SimpleTableColumn[];
  tableSearchStates: any[];
  handleSearchSubmit: any;
  handleDeleteClick?: any;
  resource: string;
  isLoading?: boolean;
  isCreate?: boolean;
}

const useStyles = makeStyles(
  createStyles({
    box: {
      padding: '24px',
    },
    createButton: {
      paddingRight: '12px',
      paddingBottom: '12px',
      textAlign: 'right',
    },
  }),
);

const SimpleIndex: React.FC<Props> = (props) => {
  const classes = useStyles();

  const [
    { contents, option, isLoading: IIsLoading, isError },
    { setOption },
  ] = props.indexHooks;

  const [
    { response: dResponse, isLoading: dIsLoading, isError: dIsError },
  ] = props.deleteHooks;

  const columns = props.columns;

  useApiResponseOkAndReload(
    dResponse,
    setOption,
    option,
    '削除に成功しました。',
  );
  useApiResponseNg(dIsError, '削除に失敗しました。');
  useApiResponseNg(isError, '取得に失敗しました。');

  const history = useHistory();
  const createClickHandler = () => {
    history.push(`/${props.resource}/create`);
  };

  return (
    <Card>
      <Box className={classes.box}>
        {IIsLoading || dIsLoading || props.isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <TableSearch
              rows={props.tableSearchStates}
              handler={props.handleSearchSubmit}
            />
            <Grid>
              <Grid item xs={12} className={classes.createButton}>
                {props.isCreate ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={createClickHandler}
                  >
                    作成
                  </Button>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
            <TableBody
              rows={contents.data}
              columns={columns}
              option={option}
              setOption={setOption}
              contents={contents}
              deleteOnClick={props.handleDeleteClick}
              resource={props.resource}
            />
            <TablePagination
              option={option}
              setOption={setOption}
              contents={contents}
            />
          </>
        )}
      </Box>
    </Card>
  );
};

export default SimpleIndex;
