import React from 'react';

import DetailTable from '../../molecules/detailTable/DetailTable';
import { ApiStates } from '../../../hooks/api/type/ApiStates';

interface Props {
  children?: React.ReactNode;
  id: number;
  resource: string;
  showHooks: ApiStates<any, any>;
}

const StaffSalesSummaryTable: React.FC<Props> = (props) => {
  const columns = [
    { id: 'sales_summary', label: '営業サマリ', style: { textAlign: 'left' } },
  ];

  return (
    <>
      <DetailTable
        columns={columns}
        showHooks={props.showHooks}
        id={props.id}
        resource={props.resource}
        title={'スタッフ詳細情報'}
      />
    </>
  );
};

export default StaffSalesSummaryTable;
