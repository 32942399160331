import React from 'react';

import DetailTable from '../../molecules/detailTable/DetailTable';
import { getDescendant } from '../../../utils/format';
import { useMasterLoans } from '../../../hooks/api/mastersHook';
import { ApiStates } from '../../../hooks/api/type/ApiStates';

interface Props {
  children?: React.ReactNode;
  id: number;
  resource: string;
  showHooks: ApiStates<any, any>;
}

const LoanDefaultInfoTable: React.FC<Props> = (props) => {
  const mastersHooks = useMasterLoans();

  const [{ contents }] = mastersHooks;

  const columns = [
    [
      { id: 'staff.kana_name', label: 'スタッフ（ふりがな）' },
      { id: 'staff.name', label: 'スタッフ' },
      { id: 'user_in_charge.name', label: '担当営業' },
      { id: 'last_client.name', label: '上位' },
      { id: 'end_client.name', label: 'エンド' },
      { id: 'contract_end_date', label: '契約終了予定日' },
      { id: 'next_contract_update_date', label: 'スキルシート予定日' },
      { id: 'next_contract_update_date', label: '次回契約更新日' },
    ],
    [
      { id: 'contract_date', label: '契約日' },
      { id: 'start_date', label: '出向開始日' },
      {
        id: 'contract_period',
        label: '契約期間',
        option: {
          unit: {
            last: 'ヶ月',
          },
        },
      },
      { id: 'end_date', label: '終了日' },
      { id: 'settlement_width', label: '精算幅' },
      { id: 'charge', label: '単価' },
      {
        id: 'payment_site',
        label: '支払サイト',
        option: {
          unit: {
            last: '日サイト',
          },
        },
      },
      {
        id: 'risk_level',
        label: '終了リスク',
        minWidth: 100,
        option: {
          colorChange: {
            color: 'red',
            callback: (content: any) =>
              getDescendant(content, 'risk_level') === 'HIGH',
          },
          conversion: contents.risk_level,
        },
      },
    ],
  ];

  return (
    <>
      <DetailTable
        columns={columns}
        showHooks={props.showHooks}
        id={props.id}
        resource={props.resource}
        title={'出向基本情報'}
      />
    </>
  );
};

export default LoanDefaultInfoTable;
