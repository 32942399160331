import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import {
  useApiResponseNgAndErrorDialog,
  useApiResponseOkAndTransition,
} from '../../../hooks/api/generic/apiResponseHandling';
import { ApiStates } from '../../../hooks/api/type/ApiStates';
import { UseFormReturn } from 'react-hook-form/dist/types';
import FormSubmit from './FormSubmit';
import CircularProgress from '../../molecules/common/CircularProgress';
import CustomValidFormItem, { CustomForm } from './CustomValidFormItem';
import CustomValidFormChildrenItem from './CustomValidFormChildrenItem';

interface SubmitHandler {
  (data: any): void;
}

interface Props {
  id: any;
  children?: React.ReactNode;
  createHooks: ApiStates<any, any>;
  formHooks: UseFormReturn<any>;
  submitHandler: SubmitHandler;
  forms: CustomForm[][];
  childrenForms: CustomForm[];
  resource: string;
  isLoading?: boolean;
  redirect?: string;
}

const CustomEdit: React.FC<Props> = (props) => {
  const classes = makeStyles({
    card: {
      padding: '24px',
    },
  })();

  const [{ contents, response, isLoading, isError }] = props.createHooks;

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = props.formHooks;

  useApiResponseOkAndTransition(
    response,
    '登録に成功しました。',
    props.redirect ?? `/${props.resource}/${props.id}`,
  );

  useApiResponseNgAndErrorDialog(response, contents, isError);

  // useEffect(() => {
  //   console.log(errors);
  // }, [errors]);

  return (
    <>
      <Card className={classes.card}>
        <CircularProgress isLoading={props.isLoading}>
          <form onSubmit={handleSubmit(props.submitHandler)}>
            <CustomValidFormItem
              control={control}
              register={register}
              setValue={setValue}
              errors={errors}
              rows={props.forms}
            />
            <CustomValidFormChildrenItem
              control={control}
              register={register}
              setValue={setValue}
              errors={errors}
              rows={props.childrenForms}
            />
            <FormSubmit isLoading={isLoading}>更新</FormSubmit>
          </form>
        </CircularProgress>
      </Card>
    </>
  );
};

export default CustomEdit;
