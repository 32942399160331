import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Control } from 'react-hook-form/dist/types/form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { getDescendant } from '../../../utils/format';

interface Props {
  children?: React.ReactNode;
  control: Control<any, any>;
  errors: FieldErrors<any>;
  name: string;
  label?: string;
  disabled?: boolean;
  items: FormSelects[];
  onChange?: (e: any, setValue: any) => void;
  setValue?: any;
}

export interface FormSelects {
  id: string | number;
  name: string;
}

const Selectbox: React.FC<Props> = (props) => {
  const onChange = props.onChange ?? (() => {});
  const setValue = props.setValue ?? (() => {});
  const MenuItems = () =>
    props.items.map((item: FormSelects) => (
      <MenuItem value={item.id} key={item.id}>
        {item.name}
      </MenuItem>
    ));

  const error = getDescendant(props.errors, props.name);

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field }) => (
        <TextField
          {...field}
          label={props.label}
          fullWidth
          margin="normal"
          id="select"
          error={error}
          helperText={error?.message}
          select
          variant="outlined"
          disabled={props.disabled ?? false}
          onChange={(e) => {
            field.onChange(e);
            console.log(field);
            onChange(e, setValue);
          }}
        >
          {MenuItems()}
        </TextField>
      )}
    />
  );
};

export default Selectbox;
