import * as Yup from 'yup';

export const validLoanPlans = (master: any) => {
  const staffs = master.staffs ?? [];
  const users = master.users ?? [];
  return {
    staff_id: Yup.number().oneOf(
      staffs.map((obj: any) => obj.id),
      'いずれかを選択してください',
    ),
    user_in_charge_id: Yup.number().oneOf(
      users.map((obj: any) => obj.id),
      'いずれかを選択してください',
    ),
    start_date: Yup.date().required('必須項目です'),
    expected_loan_date: Yup.date().required('必須項目です'),
    expected_end_date: Yup.date().required('必須項目です'),
    target_charge: Yup.number().required('必須項目です'),
    // memo_: Yup.string().required('必須項目です'),
  };
};
