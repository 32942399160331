import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import { Control } from 'react-hook-form/dist/types/form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { getDescendant } from '../../../utils/format';
// import { InputAdornment } from '@material-ui/core';

interface Props {
  children?: React.ReactNode;
  control: Control<any, any>;
  errors: FieldErrors<any>;
  name: string;
  label?: string;
  placeholder?: string;
}

const MultilineTextArea: React.FC<Props> = (props) => {
  const error = getDescendant(props.errors, props.name);

  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={{ required: true }}
      render={({ field }) => (
        <TextField
          {...field}
          label={props.label}
          fullWidth
          margin="normal"
          // placeholder={props.label}
          helperText={error?.message}
          error={error}
          multiline
          rows={3}
          rowsMax={30}
          variant="outlined"
          // InputProps={{
          //   startAdornment: <InputAdornment position="start"></InputAdornment>,
          // }}
        />
      )}
    />
  );
};

export default MultilineTextArea;
