import { useState } from 'react';

interface InputOption {
  selects: any;
  isMultiple: boolean;
}

export const useInput = (
  initialValue: any,
  type: 'text' | 'select' | 'checkbox' | 'date_between',
  label: string,
  option?: InputOption,
) => {
  const [value, set] = useState(initialValue);
  const { selects, isMultiple } = option ?? {};
  return {
    value,
    type,
    label,
    selects,
    isMultiple,
    onChange: (e: any) => {
      if (type === 'checkbox') {
        return set(e.target.checked);
      }
      return set(e.target.value);
    },
    onChangeFrom: (e: any) => {
      return set({ ...value, from: e });
    },
    onChangeTo: (e: any) => {
      return set({ ...value, to: e });
    },
  };
};
