import React from 'react';
import TextField from '@material-ui/core/TextField';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface Props {
  children?: React.ReactNode;
  value: Date | null;
  onChange: any;
}

const useStyles = makeStyles(
  createStyles({
    dateBox: {
      width: '40%',
    },
  }),
);

const DateSingle: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <LocalizationProvider dateAdapter={DateFnsUtils}>
      <DatePicker
        value={props.value}
        inputFormat="yyyy-MM-dd"
        onChange={props.onChange}
        renderInput={(props) => (
          <TextField
            className={classes.dateBox}
            {...{ ...props, ...{ helperText: '' } }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateSingle;
