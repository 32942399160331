import React from 'react';

import DetailTable from '../../molecules/detailTable/DetailTable';
import { ApiStates } from '../../../hooks/api/type/ApiStates';

interface Props {
  children?: React.ReactNode;
  id: number;
  resource: string;
  showHooks: ApiStates<any, any>;
}

const StaffAbilityTable: React.FC<Props> = (props) => {
  const columns = [
    {
      id: 'm_occupations',
      label: '職種',
      option: {
        explode: {
          delimiter: '/',
          target: 'name',
        },
      },
    },
    {
      id: 'm_milestones',
      label: '工程',
      option: {
        explode: {
          delimiter: '/',
          target: 'name',
        },
      },
    },
    {
      id: 'm_skills',
      label: 'スキル',
      option: {
        explode: {
          delimiter: '/',
          target: 'name',
        },
      },
    },
  ];

  return (
    <>
      <DetailTable
        columns={columns}
        showHooks={props.showHooks}
        id={props.id}
        resource={props.resource}
      />
    </>
  );
};

export default StaffAbilityTable;
