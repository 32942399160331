import { ApiStates } from './type/ApiStates';
import ApiIndexContents from './type/ApiContents';
import {
  useApiBase,
  useApiCreateBase,
  useApiDeleteBase,
  useApiEditBase,
  useApiIndexBase,
  useApiShowBase,
} from './generic/apiBaseHooks';
import { ApiIndexOptions } from './type/ApiIndex';
import { ApiShowOptions } from './type/ApiShow';
import { useContext, useState } from 'react';
import baseKy from '../../kys';
import { PersistStoreContext } from '../../stores/persist/store';

const resource = 'loans';
// 一覧
export const useLoansIndex = (): ApiStates<
  ApiIndexContents<any>,
  ApiIndexOptions
> => {
  return useApiIndexBase(resource);
};

// 詳細
export const useLoansShow = (): ApiStates<any, ApiShowOptions> => {
  return useApiShowBase(resource);
};

// 作成
export const useLoansCreate = (): ApiStates<any, any> => {
  return useApiCreateBase(resource);
};

// 更新
export const useLoansEdit = (): ApiStates<any, any> => {
  return useApiEditBase(resource);
};

// 更新
export const useLoansEnd = (): ApiStates<any, any> => {
  const [option, setOption] = useState({ json: {}, id: 0 });

  const check = () => {
    const length = Object.keys(option.json).length;
    return !(option.id === 0 || length === 0);
  };

  const { state } = useContext(PersistStoreContext);

  const doApi = async () => {
    return new baseKy(state.users?.token).client.patch(
      `${resource}/${option.id}/end`,
      option,
    );
  };

  return useApiBase(option, setOption, doApi, check);
};

// 削除
export const useLoansDelete = (): ApiStates<any, any> => {
  return useApiDeleteBase(resource);
};
