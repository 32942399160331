import { GridSize } from '@material-ui/core';
import { CustomForm } from '../components/organisms/page/CustomValidFormItem';
import { isDateObject } from './check';

export const getDescendant = (target: any, str: string) => {
  const keys = str.split('.');
  let result = target;
  keys.map((key) => (result = result[key] ?? ''));

  return result;
};

// 配列から {keyName: valueName}の形で取得する
export const extractKeyValue = (array: any[], key: string, value: string) => {
  let result: any = {};
  array.forEach((form) => {
    result[form[key] as keyof any] = form[value];
  });
  return result;
};

// 2次元配列から {keyName: valueName}の形で取得する
export const extractKeyValueByNest = (
  array: any[],
  keyName: string,
  valueName: string,
) => {
  return array.reduce((prev: any, rows: CustomForm[]) => {
    const result = extractKeyValue(rows, keyName, valueName);
    return { ...prev, ...result };
  }, {});
};

export const extractKeyValueByObject = (
  array: any[],
  object: any,
  key: string,
  ignores?: Array<string>,
) => {
  let result: any = {};

  array.forEach((form) => {
    if ((ignores ?? []).indexOf(form[key]) === -1) {
      if (form.disabled ?? false) return;
      const target = object[form[key] as keyof any];
      result[form[key] as keyof any] = target;
      if (form.type === 'date' && isDateObject(target)) {
        result[form[key] as keyof any] = `${target.getFullYear()}-${(
          target.getMonth() + 1
        )
          .toString()
          .padStart(2, '0')}-${target.getDate().toString().padStart(2, '0')}`;
      }
    }
  });
  return result;
};

export const timeStampToDatetime = (target: any, type: 'date' | 'datetime') => {
  if (isDateObject(target)) {
    if (type === 'date') {
      return `${target.getFullYear()}-${(target.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${target.getDate().toString().padStart(2, '0')}`;
    }
    // datetime
    return `${target.getFullYear()}-${(target.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${target
      .getDate()
      .toString()
      .padStart(2, '0')} ${target
      .getHours()
      .toString()
      .padStart(2, '0')}:${target
      .getMinutes()
      .toString()
      .padStart(2, '0')}:${target.getSeconds().toString().padStart(2, '0')}`;
  }
  return target;
};
export const apiDateFormat = (d: Date | null) => {
  if (d === null) return '';

  return `${d.getFullYear()}-${(d.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`.replace(
    /\n|\r/g,
    '',
  );
};

export const ReplaceUrl = (str: string, contents: any) => {
  const reg = str.match(/{:.+?}/g);
  return (
    reg?.reduce((p, row: string) => {
      const target = row.replace('{:', '').replace('}', '');
      const val = getDescendant(contents, target);

      return p.replace(row, val);
    }, str) ?? str
  );
};

export const formatGridSize = (num: number): GridSize => {
  switch (num) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    case 4:
      return 4;
    case 5:
      return 5;
    case 6:
      return 6;
    case 7:
      return 7;
    case 8:
      return 8;
    case 9:
      return 9;
    case 10:
      return 10;
    case 11:
      return 11;
    case 12:
      return 12;
    default:
      return 12;
  }
};

export const formatApiDataAll = (params: any) => {
  Object.keys(params).forEach(function (key: string) {
    if (params[key] === null) {
      return;
    }
    if (typeof params[key] === 'object') {
      params[key] = formatApiDataAll(params[key]);
    }
    if (key.indexOf('datetime') !== -1) {
    }

    const type = key.indexOf('datetime') !== -1 ? 'datetime' : 'date';

    params[key] = timeStampToDatetime(params[key], type);
  });
  return params;
};
