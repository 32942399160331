import React, { useEffect } from 'react';
import { BaseProps } from '../../types/componentType';
import useTitle from '../../hooks/generic/titleSet';
import { CustomForm } from '../../components/organisms/page/CustomValidFormItem';
import * as Yup from 'yup';
import { useMasterStaffs } from '../../hooks/api/mastersHook';
import * as valids from 'constants/valids';
import StoreErrorDialog from '../../components/organisms/event/StoreErrorDialog';
import CustomEdit from '../../components/organisms/page/CustomEdit';
import useCustomForm from '../../hooks/generic/customForm';
import { useStaffsEdit, useStaffsShow } from '../../hooks/api/staffsHooks';

interface Props extends BaseProps {}

const StaffsEdit: React.FC<Props> = (props) => {
  useTitle(props.title);

  const mastersHooks = useMasterStaffs();
  const showHooks = useStaffsShow();
  const editHooks = useStaffsEdit();

  const [{ isLoading, contents }] = mastersHooks;
  const [
    { isLoading: sIsLoading, contents: sContents },
    { setOption: sDoFetch },
  ] = showHooks;
  const [{ option }, { setOption: doSubmit }] = editHooks;

  const forms: CustomForm[][] = [
    [
      {
        name: 'first_name_kana',
        label: '苗字（ふりがな）',
        type: 'text',
        selects: contents.users,
        defaultValue: '',
        descendant: 'first_name_kana',
        valid: Yup.string().required(valids.MSG_REQUIRES),
      },
      {
        name: 'last_name_kana',
        label: '名前（ふりがな）',
        type: 'text',
        selects: contents.users,
        defaultValue: '',
        descendant: 'last_name_kana',
        valid: Yup.string().required(valids.MSG_REQUIRES),
      },
      {
        name: 'first_name',
        label: '苗字',
        type: 'text',
        defaultValue: '',
        descendant: 'first_name',
        valid: Yup.string().required(valids.MSG_REQUIRES),
      },
      {
        name: 'last_name',
        label: '名前',
        type: 'text',
        defaultValue: '',
        descendant: 'last_name',
        valid: Yup.string().required(valids.MSG_REQUIRES),
      },
      {
        name: 'contract',
        label: '契約',
        type: 'select',
        selects: contents.contract,
        defaultValue: '',
        descendant: 'contract',
        valid: Yup.string()
          .required(valids.MSG_REQUIRES)
          .oneOf(
            (contents.contract ?? []).map((obj: any) => obj.id),
            valids.MSG_IN,
          ),
      },
      {
        name: 'mail',
        label: 'メールアドレス',
        type: 'text',
        defaultValue: '',
        descendant: 'mail',
        valid: Yup.string()
          .email()
          .nullable()
          .transform((value, originalValue) =>
            String(originalValue).trim() === '' ? null : value,
          ),
      },
      {
        name: 'phone_number',
        label: '電話番号',
        type: 'text',
        defaultValue: '',
        descendant: 'phone_number',
        valid: Yup.string()
          .max(16)
          .matches(valids.REG_TEL, valids.MSG_TEL)
          .nullable()
          .transform((value, originalValue) =>
            String(originalValue).trim() === '' ? null : value,
          ),
      },
      {
        name: 'address',
        label: 'アドレス',
        type: 'text',
        defaultValue: '',
        descendant: 'address',
        valid: Yup.string()
          .nullable()
          .transform((value, originalValue) =>
            String(originalValue).trim() === '' ? null : value,
          ),
      },
    ],
    [
      {
        name: 'birthday',
        label: '生年月日',
        type: 'date',
        defaultValue: new Date(),
        descendant: 'birthday',
        valid: Yup.date().required(valids.MSG_REQUIRES),
      },
      {
        name: 'hire_date',
        label: '入社日',
        type: 'date',
        defaultValue: new Date(),
        descendant: 'hire_date',
        valid: Yup.date().required(valids.MSG_REQUIRES),
      },
      {
        name: 'retirement_date',
        label: '退職日',
        type: 'date',
        defaultValue: null,
        descendant: 'retirement_date',
        valid: Yup.date().nullable(),
      },
      {
        name: 'nearest_station',
        label: '最寄駅',
        type: 'text',
        defaultValue: '',
        descendant: 'nearest_station',
        valid: Yup.string().required(valids.MSG_REQUIRES),
      },
      {
        name: 'client_id',
        label: '所属',
        type: 'select',
        selects: contents.clients,
        defaultValue: 0,
        descendant: 'client_id',
        valid: Yup.number()
          .required(valids.MSG_REQUIRES)
          .oneOf(
            (contents.clients ?? []).map((obj: any) => obj.id),
            valids.MSG_IN,
          ),
      },
      {
        name: 'status',
        label: 'ステータス',
        type: 'select',
        selects: contents.status,
        defaultValue: 0,
        descendant: 'status',
        valid: Yup.string()
          .required(valids.MSG_REQUIRES)
          .oneOf(
            (contents.status ?? []).map((obj: any) => obj.id),
            valids.MSG_IN,
          ),
      },
      {
        name: 'sales_summary',
        label: '営業サマリ',
        type: 'textarea',
        defaultValue: '',
        descendant: 'sales_summary',
        valid: Yup.string().required(valids.MSG_REQUIRES),
      },
    ],
  ];

  const childrenForms: CustomForm[] = [
    {
      name: 'staff_skills',
      label: 'スキル',
      type: 'array',
      xs: 3,
      descendant: 'm_skills',
      params: [
        {
          name: 'm_skill_id',
          label: 'スキル名',
          type: 'select',
          selects: contents.m_skills as any,
          defaultValue: 0,
          descendant: 'id',
          valid: Yup.number()
            .required(valids.MSG_REQUIRES)
            .oneOf(
              (contents.m_skills ?? []).map((obj: any) => obj.id),
              valids.MSG_IN,
            ),
        },
      ],
    },
    {
      name: 'staff_milestones',
      label: '工程名',
      type: 'array',
      xs: 3,
      descendant: 'm_milestones',
      params: [
        {
          name: 'm_milestone_id',
          label: '工程名',
          type: 'select',
          selects: contents.m_milestones as any,
          defaultValue: 0,
          descendant: 'id',
          valid: Yup.number()
            .required(valids.MSG_REQUIRES)
            .oneOf(
              (contents.m_milestones ?? []).map((obj: any) => obj.id),
              valids.MSG_IN,
            ),
        },
      ],
    },
    {
      name: 'staff_occupations',
      label: '役職',
      type: 'array',
      xs: 3,
      descendant: 'm_occupations',
      params: [
        {
          name: 'm_occupation_id',
          label: '役職名',
          type: 'select',
          selects: contents.m_occupations as any,
          defaultValue: 0,
          descendant: 'id',
          valid: Yup.number()
            .required(valids.MSG_REQUIRES)
            .oneOf(
              (contents.m_occupations ?? []).map((obj: any) => obj.id),
              valids.MSG_IN,
            ),
        },
      ],
    },
  ];

  // 初期ロード時、のみ実行をする。
  useEffect(() => {
    sDoFetch({ id: props.match.params.id });
  }, [props.match.params.id, sDoFetch]);

  const customForm = useCustomForm(
    forms,
    childrenForms,
    isLoading || sIsLoading,
    sContents,
  );
  const formHooks = customForm.formHooks;
  return (
    <>
      <StoreErrorDialog />
      <CustomEdit
        id={props.match.params.id}
        createHooks={editHooks}
        formHooks={formHooks}
        submitHandler={customForm.toSubmitHandler(
          doSubmit,
          option,
          props.match.params.id,
        )}
        forms={customForm.value}
        childrenForms={customForm.childrenValue}
        resource={props.resource}
        isLoading={isLoading}
      />
    </>
  );
};

export default StaffsEdit;
