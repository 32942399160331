import { ApiStates } from './type/ApiStates';
import { useApiBase } from './generic/apiBaseHooks';
import { ApiShowOptions } from './type/ApiShow';
import { useState } from 'react';
import baseKy from '../../kys';

// const resource = 'users';

// 詳細
export const useUserAuth = (): ApiStates<any, ApiShowOptions> => {
  const [option, setOption] = useState({});

  const check = () => {
    return !!Object.keys(option).length;
  };

  const doApi = async () => {
    return new baseKy('').client.post(`login`, option);
  };

  return useApiBase(option, setOption, doApi, check, {});
};
