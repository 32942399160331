import React from 'react';
import { Link } from 'react-router-dom';

interface Props {}
interface State {}

class Default extends React.Component<Props, State> {
  render() {
    return (
      <div>
        <div>サンドボックスのヘッダー</div>
        {this.props.children}
        <div>
          <Link to="/sandbox/first">First</Link>
          <br />
          <Link to="/sandbox/second">Second</Link>
          <br />
          <Link to="/sandbox/redux">Redux</Link>
          <br />
          <Link to="/sandbox/hooksStore">HooksStore</Link>
        </div>
      </div>
    );
  }
}

export default Default;
