import { useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { FormInits } from '../../components/organisms/page/SimpleEdit';
import { getDescendant } from '../../utils/format';

export default function useInitFormSet(
  rows: FormInits[],
  content: any,
  setValue: UseFormSetValue<any>,
) {
  useEffect(() => {
    rows.map((row: FormInits) =>
      setValue(row.name, getDescendant(content, row.descendant)),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);
}
