import React from 'react';

interface Props {}
interface State {}

class Default extends React.Component<Props, State> {
  render() {
    return (
      <div>
        エラーページ
        {this.props.children}
      </div>
    );
  }
}

export default Default;
