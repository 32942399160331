import React from 'react';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../assets/styles/ts/theme';
import { getDescendant } from '../../../utils/format';

interface Props {
  children?: React.ReactNode;
  column: any;
  content: any;
}

const useStyles = makeStyles(
  createStyles({
    editButton: {
      background: theme.palette.warning.main,
      color: 'white',
      '&:hover': {
        background: theme.palette.warning.dark,
      },
      textDecoration: 'none',
    },
  }),
);

const DlButtonTableCell: React.FC<Props> = (props) => {
  const classes = useStyles();

  let path = getDescendant(props.content, props.column.id);

  return (
    <a href={`${process.env.REACT_APP_FILE_PATH}${path}`}>
      <Button variant="contained" className={classes.editButton} size="small">
        DLする
      </Button>
    </a>
  );
};

export default DlButtonTableCell;
