import React from 'react';

import DetailTable from '../../molecules/detailTable/DetailTable';
import { ApiStates } from '../../../hooks/api/type/ApiStates';
import { useMasterLoanPlans } from '../../../hooks/api/mastersHook';

interface Props {
  children?: React.ReactNode;
  id: number;
  resource: string;
  showHooks: ApiStates<any, any>;
}

const LoanPlansDefaultInfoTable: React.FC<Props> = (props) => {
  const mastersHooks = useMasterLoanPlans();
  const [{ contents }] = mastersHooks;

  const columns = [
    [
      { id: 'staff.kana_name', label: 'スタッフ名（ふりがな）' },
      { id: 'staff.name', label: 'スタッフ名' },
      { id: 'user.name', label: '営業担当者' },
      { id: 'start_date', label: '営業開始日' },
      { id: 'start_end', label: '営業終了日' },
    ],
    [
      { id: 'target_charge', label: '目標単価' },
      { id: 'loan.charge', label: '実績単価' },
      {
        id: 'progress',
        label: '進捗',
        minWidth: 100,
        option: { conversion: contents.progress },
      },
      { id: 'expected_loan_date', label: '出向開始予定日' },
      { id: 'expected_end_date', label: '営業終了予定日' },
      { id: 'memo', label: 'メモ', style: { textAlign: 'left' } },
    ],
  ];

  return (
    <>
      <DetailTable
        columns={columns}
        showHooks={props.showHooks}
        id={props.id}
        resource={props.resource}
        title={'出向計画基本情報'}
      />
    </>
  );
};

export default LoanPlansDefaultInfoTable;
