import React, { useEffect, useState } from 'react';

import useTitle from '../../hooks/generic/titleSet';
import { BaseProps } from '../../types/componentType';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import LinkButton from '../../components/molecules/common/LinkButton';
import Grid from '@material-ui/core/Grid';
import LoanPlansDefaultInfoTable from '../../components/organisms/loanPlans/LoanPlansDefaultInfoTable';
import { useSuggestionsShow } from '../../hooks/api/suggestionsHooks';
import InterviewTable from '../../components/organisms/loanPlans/InterviewTable';
import { useLoanPlansShow } from '../../hooks/api/loanPlansHooks';
import StaffSalesSummaryTable from '../../components/organisms/staffs/StaffSalesSummaryTable';
import StaffAbilityTable from '../../components/organisms/staffs/StaffAbilityTable';
import { useStaffsShow } from '../../hooks/api/staffsHooks';
import SuggestionDefaultInfoTable from '../../components/organisms/suggestion/SuggestionDefaultInfoTable';
import ClientTable from '../../components/organisms/suggestion/ClientTable';
import CircularProgress from '../../components/molecules/common/CircularProgress';

interface Props extends BaseProps {}

const useStyles = makeStyles(
  createStyles({
    box: {
      padding: '24px',
    },
    linkButton: {
      marginRight: '12px',
    },
    gridRecord: {
      marginTop: '24px',
      marginBottom: '24px',
      paddingRight: '24px',
      textAlign: 'right',
      width: '100%',
    },
    table: {
      marginTop: '24px',
      marginBottom: '24px',
    },
  }),
);

const SuggestionsDetail: React.FC<Props> = (props) => {
  useTitle(props.title);

  const classes = useStyles();

  const loanPlansShowHooks = useLoanPlansShow();
  const suggestionShowHooks = useSuggestionsShow();
  const userShowHooks = useStaffsShow();

  const [{}, { setOption: lpDoFetch }] = loanPlansShowHooks;

  const [{ contents }, { setOption: sDoFetch }] = suggestionShowHooks;
  const uDoFetch = userShowHooks[1].setOption;

  const [buttonLoading, setButtonLoading] = useState(true);

  useEffect(() => {
    sDoFetch({ id: props.match.params.id });
  }, [props.match.params.id, sDoFetch]);

  useEffect(() => {
    if (isNaN(contents?.loan_plan_id)) return;
    lpDoFetch({ id: contents?.loan_plan_id });
  }, [contents?.loan_plan_id, lpDoFetch]);
  useEffect(() => {
    if (isNaN(contents?.staff?.id)) return;
    uDoFetch({ id: contents?.staff?.id });
  }, [contents?.staff?.id, uDoFetch]);

  useEffect(() => {
    if (
      parseInt(props.match.params.id) > 0 &&
      parseInt(contents.loan_plan_id) > 0
    ) {
      setButtonLoading(false);
    }
  }, [props.match.params.id, contents.loan_plan_id]);
  return (
    <>
      <Card>
        <Grid container className={classes.gridRecord}>
          <Grid item xs={12}>
            <CircularProgress isLoading={buttonLoading}>
              <LinkButton
                to={`/suggestions/${props.match.params.id}/completed`}
                type={'info'}
              >
                提案辞退
              </LinkButton>
              <LinkButton
                to={`/suggestions/${props.match.params.id}/loans/create`}
                type={'info'}
              >
                出向確定
              </LinkButton>
              <LinkButton
                to={`/loan_plans/${contents.loan_plan_id}/suggestions/${props.match.params.id}/interviews/create`}
                type={'info'}
              >
                面談作成
              </LinkButton>
              <LinkButton
                to={`/${props.resource}/${props.match.params.id}/edit`}
                type={'warning'}
              >
                編集
              </LinkButton>
            </CircularProgress>
          </Grid>
        </Grid>

        <Box className={classes.box}>
          <div className={classes.table}>
            <LoanPlansDefaultInfoTable
              id={props.match.params.id}
              resource={props.resource}
              showHooks={loanPlansShowHooks}
            />
          </div>
          <div className={classes.table}>
            <StaffSalesSummaryTable
              id={props.match.params.id}
              resource={props.resource}
              showHooks={userShowHooks}
            />
            <StaffAbilityTable
              id={props.match.params.id}
              resource={props.resource}
              showHooks={userShowHooks}
            />
          </div>
          <div className={classes.table}>
            <SuggestionDefaultInfoTable
              id={props.match.params.id}
              resource={props.resource}
              showHooks={suggestionShowHooks}
            />
          </div>
          <div className={classes.table}>
            <ClientTable
              id={props.match.params.id}
              resource={props.resource}
              showHooks={suggestionShowHooks}
            />
          </div>
          <div className={classes.table}>
            <InterviewTable
              id={props.match.params.id}
              resource={props.resource}
              showHooks={suggestionShowHooks}
            />
          </div>
        </Box>
      </Card>
    </>
  );
};

export default SuggestionsDetail;
