import React, { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Drawer, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { theme } from 'assets/styles/ts/theme';

import SidebarList from 'components/organisms/layout/SidebarList';
import { PersistStoreContext } from '../../../stores/persist/store';
const drawerWidth = 240; //TODO:定数化

interface Props {
  children?: React.ReactNode;
  open: any;
}

const useStyles = makeStyles(
  createStyles({
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      // borderBottom: '1px solid rgba(0, 0, 0, .125)',
      ...theme.mixins.toolbar,
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      // width: theme.spacing(7),
      width: `0px`,
      [theme.breakpoints.up('sm')]: {
        // width: theme.spacing(9),
        width: `0px`,
      },
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
  }),
);

const Sidebar: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { state } = useContext(PersistStoreContext);
  const user = state.users;
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(
          classes.drawerPaper,
          !props.open && classes.drawerPaperClose,
        ),
      }}
      open={props.open}
    >
      <div className={classes.toolbarIcon}>
        <Typography>
          {process.env.REACT_APP_NAME} {process.env.REACT_APP_ENV}
        </Typography>
      </div>
      <Divider />
      <div className={classes.toolbarIcon}>
        <Typography>{user.user?.name}</Typography>
      </div>
      <SidebarList />
    </Drawer>
  );
};

export default Sidebar;
