import React from 'react';

import SimpleIndex from '../../components/organisms/page/SimpleIndex';
import { useInput } from '../../hooks/generic/inputHook';
import useTitle from '../../hooks/generic/titleSet';
import { BaseProps } from '../../types/componentType';
import { useMasterLoanPlans } from '../../hooks/api/mastersHook';
import {
  useLoanPlansDelete,
  useLoanPlansIndex,
} from '../../hooks/api/loanPlansHooks';
import { apiDateFormat, getDescendant } from '../../utils/format';

interface Props extends BaseProps {}

const MMilestonesIndex: React.FC<Props> = (props) => {
  useTitle(props.title);

  const indexHooks = useLoanPlansIndex();
  const deleteHooks = useLoanPlansDelete();
  const mastersHooks = useMasterLoanPlans();

  const [{ isLoading, contents }] = mastersHooks;

  const [{ option }, { setOption }] = indexHooks;
  /* eslint-disable no-empty-pattern */
  const [{}, { setOption: doDelete }] = deleteHooks;

  const columns = [
    { id: 'id', label: 'ID', minWidth: 20 },
    { id: 'staff.name', label: 'スタッフ名', minWidth: 100 },
    { id: 'user.name', label: '担当者', minWidth: 100 },
    {
      id: 'start_date',
      label: '営業開始日',
      minWidth: 120,
      option: {
        colorChange: {
          color: 'red',
          callback: (content: any) =>
            getDescendant(content, 'is_start_date_alert'),
        },
      },
    },
    { id: 'expected_loan_date', label: '出向開始予定日', minWidth: 140 },
    { id: 'expected_end_date', label: '営業終了予定日', minWidth: 140 },
    {
      id: 'progress',
      label: '進捗',
      minWidth: 100,
      option: { conversion: contents.progress },
    },
    { id: 'target_charge', label: '目標単価', minWidth: 100 },
    {
      id: 'loan.charge',
      label: '実績単価',
      minWidth: 100,
      option: {
        colorChange: {
          color: 'red',
          callback: (content: any) =>
            getDescendant(content, 'is_not_achieve_charge'),
        },
      },
    },
    { id: 'loan.start_date', label: '出向開始日', minWidth: 140 },
    // { id: 'created_at', label: '作成日', minWidth: 170 },
    { id: 'detail_button', label: '詳細', minWidth: 50 },
    {
      id: 'suggestions_status',
      label: '提案状況',
      minWidth: 300,
      type: 'list',
      option: {
        target: 'suggestions',
        text: '{end_client.name}',
        path: '/suggestions/{id}',
        // text: '{index}: {end_client.name} ({next_action})',
      },
    },
    {
      id: 'interviews_status',
      label: '面談状況',
      minWidth: 300,
      type: 'list',
      option: {
        target: 'interviews',
        text: '{interviews.end_client.name} ({start_datetime})',
        path: '/interviews/{id}',
      },
    },
  ];

  const staffId = useInput('', 'select', 'スタッフ', {
    selects: contents.staffs ?? [],
    isMultiple: false,
  });

  const userInChargeId = useInput('', 'select', 'ユーザ', {
    selects: contents.users ?? [],
    isMultiple: false,
  });

  const progress = useInput([], 'select', '進捗', {
    selects: contents.progress ?? [],
    isMultiple: true,
  });

  const startDate = useInput(
    { from: null, to: null },
    'date_between',
    '営業開始日',
  );

  const expectedLoanDate = useInput(
    { from: null, to: null },
    'date_between',
    '出向開始予定日',
  );

  const expectedEndDate = useInput(
    { from: null, to: null },
    'date_between',
    '営業終了予定日',
  );

  const progressWishDone = useInput(false, 'checkbox', '完了済みも表示');

  const handleSearchSubmit = () => {
    const changedSearchParam = {
      ...option.searchParams,
      staff_id_eq: staffId.value,
      user_in_charge_id_eq: userInChargeId.value,
      progress_in: progress.value,
      progress_wish_done: progressWishDone.value,
      start_date_gt: apiDateFormat(startDate.value.from),
      start_date_lt: apiDateFormat(startDate.value.to),
      expected_loan_date_gt: apiDateFormat(expectedLoanDate.value.from),
      expected_loan_date_lt: apiDateFormat(expectedLoanDate.value.to),
      expected_end_date_gt: apiDateFormat(expectedEndDate.value.from),
      expected_end_date_lt: apiDateFormat(expectedEndDate.value.to),
    };

    setOption({ ...option, searchParams: changedSearchParam });
  };

  const handleDeleteClick = (id: number) => {
    return () => {
      if (window.confirm('削除してもよろしいですか？')) {
        doDelete({ id: id });
      }
    };
  };

  return (
    <>
      <SimpleIndex
        isCreate={true}
        indexHooks={indexHooks}
        deleteHooks={deleteHooks}
        columns={columns}
        tableSearchStates={[
          staffId,
          userInChargeId,
          startDate,
          expectedLoanDate,
          expectedEndDate,
          progress,
          progressWishDone,
        ]}
        handleSearchSubmit={handleSearchSubmit}
        handleDeleteClick={handleDeleteClick}
        resource={props.resource}
        isLoading={isLoading}
      />
    </>
  );
};

export default MMilestonesIndex;
