import React, { useEffect, useState } from 'react';

import useTitle from '../../hooks/generic/titleSet';
import { BaseProps } from '../../types/componentType';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import UserDefaultInfoTable from '../../components/organisms/staffs/UserDefaultInfoTable';
import StaffSalesSummaryTable from '../../components/organisms/staffs/StaffSalesSummaryTable';
import StaffAbilityTable from '../../components/organisms/staffs/StaffAbilityTable';
import LoanHistoryTable from '../../components/organisms/staffs/LoanHistoryTable';
import MonthlyLoanHistoriesTable from '../../components/organisms/staffs/MonthlyLoanHistoriesTable';
import SkillsheetHistoriesTable from '../../components/organisms/staffs/SkillsheetHistoriesTable';
import LinkButton from '../../components/molecules/common/LinkButton';
import Grid from '@material-ui/core/Grid';
import { useStaffsShow } from '../../hooks/api/staffsHooks';
import CircularProgress from '../../components/molecules/common/CircularProgress';

interface Props extends BaseProps {}

const useStyles = makeStyles(
  createStyles({
    box: {
      padding: '24px',
    },
    createButton: {
      paddingRight: '12px',
      paddingBottom: '12px',
      textAlign: 'right',
    },
    linkButton: {
      marginRight: '12px',
    },
    gridRecord: {
      marginTop: '24px',
      marginBottom: '24px',
      paddingRight: '24px',
      textAlign: 'right',
      width: '100%',
    },
    table: {
      marginTop: '24px',
      marginBottom: '24px',
    },
  }),
);

const StaffDetail: React.FC<Props> = (props) => {
  useTitle(props.title);

  const classes = useStyles();

  const userShowHooks = useStaffsShow();
  const userShowByLoanHistoriesHooks = useStaffsShow();
  const contents = userShowHooks[0].contents;
  const sDoFetch = userShowHooks[1].setOption;
  const slDoFetch = userShowByLoanHistoriesHooks[1].setOption;

  const [buttonLoading, setButtonLoading] = useState(true);
  useEffect(() => {
    sDoFetch({ id: props.match.params.id });
  }, [props.match.params.id, sDoFetch]);

  useEffect(() => {
    slDoFetch({
      id: props.match.params.id,
      searchParams: { monthly_loan_histories_year: new Date().getFullYear() },
    });
  }, [props.match.params.id, slDoFetch]);

  useEffect(() => {
    if (parseInt(props.match.params.id) > 0 && parseInt(contents.id) > 0) {
      setButtonLoading(false);
    }
  }, [props.match.params.id, contents.id]);
  return (
    <>
      <Card>
        <Grid container className={classes.gridRecord}>
          <Grid item xs={12}>
            {/*<LinkButton*/}
            {/*  to={'/staffs/1/edit' + props.match.params.id}*/}
            {/*  type={'info'}*/}
            {/*>*/}
            {/*  {' '}*/}
            {/*  スタッフ画像追加{' '}*/}
            {/*</LinkButton>*/}
            {/*<LinkButton*/}
            {/*  to={'/staffs/1/images/edit' + props.match.params.id}*/}
            {/*  type={'info'}*/}
            {/*>*/}
            {/*  {' '}*/}
            {/*  出向計画を立てる{' '}*/}
            {/*</LinkButton>*/}
            <CircularProgress isLoading={buttonLoading}>
              <LinkButton
                to={`/staffs/${props.match.params.id}/edit`}
                type={'warning'}
              >
                {' '}
                編集{' '}
              </LinkButton>
            </CircularProgress>
          </Grid>
        </Grid>

        <Box className={classes.box}>
          {/*<Carousel id={props.match.params.id} />*/}
          <div className={classes.table}>
            <UserDefaultInfoTable
              id={props.match.params.id}
              resource={props.resource}
              showHooks={userShowHooks}
            />
          </div>
          <div className={classes.table}>
            <StaffSalesSummaryTable
              id={props.match.params.id}
              resource={props.resource}
              showHooks={userShowHooks}
            />
            <StaffAbilityTable
              id={props.match.params.id}
              resource={props.resource}
              showHooks={userShowHooks}
            />
          </div>
          <div className={classes.table}>
            <LoanHistoryTable
              id={props.match.params.id}
              resource={props.resource}
              showHooks={userShowHooks}
            />
          </div>
          <div className={classes.table}>
            <MonthlyLoanHistoriesTable
              id={props.match.params.id}
              resource={props.resource}
              showHooks={userShowByLoanHistoriesHooks}
            />
          </div>
          <div className={classes.table}>
            <SkillsheetHistoriesTable
              id={props.match.params.id}
              resource={props.resource}
              is_skillsheet={true}
              showHooks={userShowHooks}
            />
          </div>
          <div className={classes.table}>
            <SkillsheetHistoriesTable
              id={props.match.params.id}
              resource={props.resource}
              is_skillsheet={false}
              showHooks={userShowHooks}
            />
          </div>
        </Box>
      </Card>
    </>
  );
};

export default StaffDetail;
