import React, { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { PersistStoreContext } from '../stores/persist/store';

// PrivateRouteの実装
// NOTE: ここもクラス使った方がいいのか
export const PrivateRoute: React.FC<RouteProps> = ({ ...props }) => {
  const { state } = useContext(PersistStoreContext);
  const user = state.users;

  const isAuthenticated = user.token !== null; //認証されているかの判定

  if (!isAuthenticated) {
    // TODO: スナックバーとか出すようにする
    console.log(
      `ログインしていないユーザーは${props.path}へはアクセスできません`,
    );
    return <Redirect to="/login" />;
  }

  return <Route {...props} />;
};
