import React from 'react';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../assets/styles/ts/theme';

interface Props {
  children?: React.ReactNode;
  column: any;
  content: any;
  deleteOnClick: any;
}

const useStyles = makeStyles(
  createStyles({
    link: {
      textDecoration: 'none　!important',
    },
    deleteButton: {
      background: theme.palette.error.main,
      color: 'white',
      '&:hover': {
        background: theme.palette.error.dark,
      },
      textDecoration: 'none',
    },
  }),
);

const DeleteTableCell: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={classes.deleteButton}
      size="small"
      onClick={props.deleteOnClick(props.content.id)}
    >
      削除
    </Button>
  );
};

export default DeleteTableCell;
