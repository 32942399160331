import {
  actionCreatorFactory,
  ActionCreatorFactory,
  ActionCreator,
} from 'typescript-fsa';

export interface DialogOnPayload {
  title: string;
  content: string;
}

export interface Actions {
  dialogOn: ActionCreator<DialogOnPayload>;
  dialogOff: ActionCreator<void>;
}

const actionCreator: ActionCreatorFactory = actionCreatorFactory();

const actions: Actions = {
  dialogOn: actionCreator('DIALOG_ON'),
  dialogOff: actionCreator('DIALOG_OFF'),
};

export default actions;
