import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MTableBody from '@material-ui/core/TableBody';
import { theme } from 'assets/styles/ts/theme';
import CustomTableCell from '../../molecules/tabelCell/CustomTableCell';

export interface SimpleTableColumn {
  id: string;
  label: string;
  minWidth: number;
  type?: string;
  // TableCellPropsType
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify';
  option?: any;
  isBool?: boolean;
  style?: any;
}

interface Props {
  children?: React.ReactNode;
  rows: Array<any>;
  columns: SimpleTableColumn[];
  option: any;
  setOption: React.Dispatch<React.SetStateAction<any>>;
  contents: any;
  deleteOnClick?: any;
  resource: string;
  title?: string;
}

const useStyles = makeStyles(
  createStyles({
    root: {
      width: '100%',
    },
    container: {
      border: '1px solid rgba(224, 224, 224, 1);',
    },
    box: {
      padding: '24px',
    },
    link: {
      textDecoration: 'none　!important',
    },
    editButton: {
      background: theme.palette.warning.main,
      color: 'white',
      '&:hover': {
        background: theme.palette.warning.dark,
      },
      textDecoration: 'none',
    },
    listContent: {
      width: '100%',
    },
    tableTitle: theme.mixins.detailTable.title,
  }),
);

const TableBody: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      {props.title ? (
        <div className={classes.tableTitle}>{props.title}</div>
      ) : (
        ''
      )}
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props.columns.map((column: any) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <MTableBody>
            {props.rows.map((row: any, i: number) => {
              return (
                // FIXME: 変な依存のさせ方してるので本来はよくない
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {props.columns.map((column: any, index: number) => {
                    return (
                      <CustomTableCell
                        key={index}
                        column={column}
                        deleteOnClick={props.deleteOnClick}
                        resource={props.resource}
                        content={row}
                      />
                    );
                  })}
                </TableRow>
              );
            })}
          </MTableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableBody;
