import React from 'react';
import { BaseProps } from '../../types/componentType';
import SimpleEdit from '../../components/organisms/page/SimpleEdit';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useTitle from '../../hooks/generic/titleSet';
import { useMasterMProjects } from '../../hooks/api/mastersHook';
import {
  useMProjectsEdit,
  useMProjectsShow,
} from '../../hooks/api/mProjectsHook';
import { extractKeyValue, extractKeyValueByObject } from '../../utils/format';
import { EditForms } from '../../components/organisms/page/ValidFormItem';
import { validMProject } from './valid';

interface Props extends BaseProps {}

const MOccupationsEdit: React.FC<Props> = (props) => {
  useTitle(props.title);

  const showHooks = useMProjectsShow();
  const editHooks = useMProjectsEdit();
  const mastersHooks = useMasterMProjects();

  const [{ option }, { setOption: doEdit }] = editHooks;

  const [{ isLoading, contents }] = mastersHooks;

  const forms: EditForms[] = [
    { name: 'name', label: '名前', descendant: 'name', type: 'text' },
    {
      name: 'client_id',
      label: 'クライアント',
      type: 'select',
      descendant: 'client_id',
      selects: contents.clients,
      defaultValue: 0,
    },
    {
      name: 'summary',
      label: '案件概要',
      type: 'textarea',
      descendant: 'summary',
      defaultValue: '',
    },
    {
      name: 'nearest_station',
      label: '最寄り駅',
      type: 'text',
      descendant: 'nearest_station',
      defaultValue: '',
    },
    {
      name: 'skill_summary',
      label: 'スキル',
      type: 'textarea',
      descendant: 'skill_summary',
      defaultValue: '',
    },
    {
      name: 'memo_',
      label: '備考',
      type: 'textarea',
      descendant: 'memo',
      defaultValue: '',
    },
  ];

  const validSchema = Yup.object().shape(validMProject(contents));

  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: extractKeyValue(forms, 'name', 'defaultValue'),
    resolver: yupResolver(validSchema),
  });

  const submitHandler = (data: any) => {
    const changedParam = {
      ...option.json,
      ...extractKeyValueByObject(forms, data, 'name', ['memo_']),
      // NOTE: memoだと予約言葉らしいので「_」をつける
      memo: data.memo_,
    };
    doEdit({ ...option, json: changedParam, id: props.match.params.id });
  };

  return (
    <>
      <SimpleEdit
        id={props.match.params.id}
        resource={props.resource}
        editHooks={editHooks}
        showHooks={showHooks}
        formHooks={formHooks}
        submitHandler={submitHandler}
        forms={forms}
        isLoading={isLoading}
        redirect={`/${props.resource}`}
      />
    </>
  );
};

export default MOccupationsEdit;
