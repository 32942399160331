import React, { useEffect, useState } from 'react';

import useTitle from '../../hooks/generic/titleSet';
import { BaseProps } from '../../types/componentType';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import LinkButton from '../../components/molecules/common/LinkButton';
import Grid from '@material-ui/core/Grid';
import { useInterviewsShow } from '../../hooks/api/interviewsHooks';
import InterviewDefaultInfoTable from '../../components/organisms/interview/InterviewDefaultInfoTable';
import StaffSalesSummaryTable from '../../components/organisms/staffs/StaffSalesSummaryTable';
import StaffAbilityTable from '../../components/organisms/staffs/StaffAbilityTable';
import { useStaffsShow } from '../../hooks/api/staffsHooks';
import { useLoanPlansShow } from '../../hooks/api/loanPlansHooks';
import LoanPlansDefaultInfoTable from '../../components/organisms/loanPlans/LoanPlansDefaultInfoTable';
import ClientTable from '../../components/organisms/suggestion/ClientTable';
import CircularProgress from '../../components/molecules/common/CircularProgress';

interface Props extends BaseProps {}

const useStyles = makeStyles(
  createStyles({
    box: {
      padding: '24px',
    },
    linkButton: {
      marginRight: '12px',
    },
    gridRecord: {
      marginTop: '24px',
      marginBottom: '24px',
      paddingRight: '24px',
      textAlign: 'right',
      width: '100%',
    },
    table: {
      marginTop: '24px',
      marginBottom: '24px',
    },
  }),
);

const InterviewsDetail: React.FC<Props> = (props) => {
  useTitle(props.title);

  const classes = useStyles();

  const interviewsShowHooks = useInterviewsShow();
  const userShowHooks = useStaffsShow();
  const loanPlansShowHooks = useLoanPlansShow();

  const [{ contents }, { setOption: lDoFetch }] = interviewsShowHooks;
  const sDoFetch = userShowHooks[1].setOption;
  const lpDoFetch = loanPlansShowHooks[1].setOption;

  const [buttonLoading, setButtonLoading] = useState(true);

  useEffect(() => {
    lDoFetch({ id: props.match.params.id });
  }, [props.match.params.id, lDoFetch]);

  useEffect(() => {
    if (isNaN(contents?.loanPlans?.staff?.id)) return;
    sDoFetch({ id: contents?.loanPlans?.staff?.id });
  }, [contents?.loanPlans?.staff?.id, sDoFetch]);

  useEffect(() => {
    if (isNaN(contents?.loanPlans?.id)) return;
    lpDoFetch({ id: contents?.loanPlans?.id });
  }, [contents?.loanPlans?.id, lDoFetch]);

  useEffect(() => {
    if (parseInt(props.match.params.id) > 0) {
      setButtonLoading(false);
    }
  }, [props.match.params.id]);

  return (
    <>
      <Card>
        <Grid container className={classes.gridRecord}>
          <Grid item xs={12}>
            <CircularProgress isLoading={buttonLoading}>
              <LinkButton
                to={`/${props.resource}/${props.match.params.id}/edit`}
                type={'warning'}
              >
                編集
              </LinkButton>
            </CircularProgress>
          </Grid>
        </Grid>

        <Box className={classes.box}>
          <div className={classes.table}>
            <LoanPlansDefaultInfoTable
              id={props.match.params.id}
              resource={props.resource}
              showHooks={loanPlansShowHooks}
            />
          </div>
          <div className={classes.table}>
            <InterviewDefaultInfoTable
              id={props.match.params.id}
              resource={props.resource}
              showHooks={interviewsShowHooks}
            />
          </div>
          <div className={classes.table}>
            <StaffSalesSummaryTable
              id={props.match.params.id}
              resource={props.resource}
              showHooks={userShowHooks}
            />
            <StaffAbilityTable
              id={props.match.params.id}
              resource={props.resource}
              showHooks={userShowHooks}
            />
          </div>
          <div className={classes.table}>
            <ClientTable
              id={props.match.params.id}
              resource={props.resource}
              showHooks={interviewsShowHooks}
            />
          </div>
        </Box>
      </Card>
    </>
  );
};

export default InterviewsDetail;
