import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { theme } from 'assets/styles/ts/theme';
import Header from 'components/organisms/layout/Header';
import Sidebar from 'components/organisms/layout/Sidebar';
import Contents from 'components/organisms/layout/Contents';

const useStyles = makeStyles(
  createStyles({
    root: {
      display: 'flex',
    },
  }),
);

export interface GenericTemplateProps {
  children: React.ReactNode;
}

const GenericTemplate: React.FC<GenericTemplateProps> = ({ children }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerToggle = (prevState: any) => {
    setOpen(!prevState);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <Header handleDrawerToggle={handleDrawerToggle} open={open}></Header>
        <Sidebar open={open}></Sidebar>
        <Contents> {children} </Contents>
      </div>
    </ThemeProvider>
  );
};

export default GenericTemplate;
