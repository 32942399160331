import React from 'react';
import { BaseProps } from '../../types/componentType';
import SimpleEdit from '../../components/organisms/page/SimpleEdit';
import {
  useMOccupationsEdit,
  useMOccupationsShow,
} from '../../hooks/api/mOccupationsHook';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useTitle from '../../hooks/generic/titleSet';

interface Props extends BaseProps {}

const MOccupationsEdit: React.FC<Props> = (props) => {
  useTitle(props.title);

  const showHooks = useMOccupationsShow();
  const editHooks = useMOccupationsEdit();
  const [{ option }, { setOption: doEdit }] = editHooks;

  const validSchema = Yup.object().shape({
    name: Yup.string().required('必須項目です'),
  });

  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(validSchema),
  });

  const submitHandler = (data: any) => {
    const changedParam = {
      ...option.json,
      name: data.name,
    };
    doEdit({ ...option, json: changedParam, id: props.match.params.id });
  };

  const formInits = [
    { name: 'name', label: '名前', descendant: 'name', type: 'text' },
  ];

  return (
    <>
      <SimpleEdit
        id={props.match.params.id}
        resource={props.resource}
        editHooks={editHooks}
        showHooks={showHooks}
        formHooks={formHooks}
        submitHandler={submitHandler}
        forms={formInits}
        redirect={`/${props.resource}`}
      />
    </>
  );
};

export default MOccupationsEdit;
