import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {
  useApiResponseNgAndErrorDialog,
  useApiResponseNgAndTransition,
  useApiResponseOkAndTransition,
} from '../../../hooks/api/generic/apiResponseHandling';
import { ApiStates } from '../../../hooks/api/type/ApiStates';
import { UseFormReturn } from 'react-hook-form/dist/types';
import useInitFormSet from '../../../hooks/generic/initFormSet';
import ValidFormItem, { EditForms } from './ValidFormItem';
import FormSubmit from './FormSubmit';

interface SubmitHandler {
  (data: any): void;
}

export interface FormInits {
  name: string;
  label: string;
  type: string;
  selects?: any;
  descendant: string;
}

interface Props {
  children?: React.ReactNode;
  id: any;
  resource: string;
  forms: EditForms[];
  editHooks: ApiStates<any, any>;
  showHooks: ApiStates<any, any>;
  formHooks: UseFormReturn<any>;
  submitHandler: SubmitHandler;
  isLoading?: boolean;
  redirect?: string;
}

const SimpleEdit: React.FC<Props> = (props) => {
  const classes = makeStyles({
    card: {
      padding: '24px',
    },
    gridRecord: {
      margin: '24px',
      textAlign: 'right',
    },
  })();

  const [
    { contents: sContents, isLoading: sIsLoading, isError: sIsError },
    { setOption: sDoFetch },
  ] = props.showHooks;

  const [{ contents, response, isLoading, isError }] = props.editHooks;

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = props.formHooks;

  // 初期ロード時、のみ実行をする。
  useEffect(() => {
    sDoFetch({ id: props.id });
  }, [props.id, sDoFetch]);

  useInitFormSet(props.forms, sContents, setValue);

  useApiResponseOkAndTransition(
    response,
    '変更に成功しました。',
    props.redirect ?? `/${props.resource}/${props.id}`,
  );

  useApiResponseNgAndTransition(
    sIsError,
    '初期取得に失敗しました',
    props.redirect ?? `/${props.resource}/${props.id}`,
  );

  // useApiResponseNg(isError, '更新に失敗しました。');
  useApiResponseNgAndErrorDialog(response, contents, isError);
  return (
    <>
      <Card className={classes.card}>
        {props.isLoading || isLoading || sIsLoading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSubmit(props.submitHandler)}>
            <Grid container className={classes.gridRecord}>
              <ValidFormItem
                control={control}
                errors={errors}
                rows={props.forms}
              />
            </Grid>
            <FormSubmit isLoading={isLoading}> 変更 </FormSubmit>
          </form>
        )}
      </Card>
    </>
  );
};

export default SimpleEdit;
