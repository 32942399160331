import React from 'react';
import SimpleCreate from '../../components/organisms/page/SimpleCreate';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useTitle from '../../hooks/generic/titleSet';
import { BaseProps } from '../../types/componentType';
import { useMasterLoanPlans } from '../../hooks/api/mastersHook';
import { CreateForm } from '../../components/organisms/page/ValidFormItem';
import { extractKeyValue, extractKeyValueByObject } from '../../utils/format';
import { validLoanPlans } from './valid';
import { useLoanPlansCreate } from '../../hooks/api/loanPlansHooks';
import StoreErrorDialog from '../../components/organisms/event/StoreErrorDialog';

interface Props extends BaseProps {}

const LoanPlansCreate: React.FC<Props> = (props) => {
  useTitle(props.title);

  const createHooks = useLoanPlansCreate();
  const mastersHooks = useMasterLoanPlans();

  const [{ option }, { setOption: doCreate }] = createHooks;

  const [{ isLoading, contents }] = mastersHooks;
  const validSchema = Yup.object().shape(validLoanPlans(contents));

  const forms: CreateForm[] = [
    {
      name: 'staff_id',
      label: 'スタッフ名',
      type: 'select',
      selects: contents.not_have_active_loan_plans,
      defaultValue: 0,
    },
    {
      name: 'user_in_charge_id',
      label: '営業担当者',
      type: 'select',
      selects: contents.users,
      defaultValue: 0,
    },
    {
      name: 'start_date',
      label: '営業開始日',
      type: 'date',
      defaultValue: new Date(),
    },
    {
      name: 'expected_loan_date',
      label: '出向開始予定日',
      type: 'date',
      defaultValue: new Date(),
    },
    {
      name: 'expected_end_date',
      label: '営業終了予定日',
      type: 'date',
      defaultValue: new Date(),
    },
    {
      name: 'target_charge',
      label: '目標単価',
      type: 'text',
      defaultValue: 500000,
    },
    { name: 'memo_', label: '備考', type: 'textarea', defaultValue: '' },
  ];

  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: extractKeyValue(forms, 'name', 'defaultValue'),
    resolver: yupResolver(validSchema),
  });

  const submitHandler = (data: any) => {
    const changedParam = {
      ...option.json,
      ...extractKeyValueByObject(forms, data, 'name'),
      // NOTE: memoだと予約言葉らしいので「_」をつける
      memo: data.memo_,
    };
    doCreate({ ...option, json: changedParam });
  };

  return (
    <>
      <StoreErrorDialog />
      <SimpleCreate
        createHooks={createHooks}
        formHooks={formHooks}
        submitHandler={submitHandler}
        forms={forms}
        resource={props.resource}
        isLoading={isLoading}
      />
    </>
  );
};

export default LoanPlansCreate;
