import React from 'react';

interface UploadFile {
  file?: File;
  base64: string;
}

interface Props {
  children?: React.ReactNode;
  uploadFile?: UploadFile;
  setUploadFile: any;
}

const UploadButton: React.FC<Props> = (props) => {
  const handleFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null || event.target.files.length === 0) {
      return;
    }
    // const files = Object.values(event.target.files).concat();
    // props.setFile(files[0]);

    let file = event.target.files[0];
    let fr = new FileReader();

    fr.onload = (e: any) => {
      const base64 = e.target.result;
      props.setUploadFile({ file: file, base64: base64 });
    };
    fr.readAsDataURL(file);
  };
  return (
    <>
      <input
        type="file"
        className="inputFileBtnHide"
        onChange={handleFile}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet/*"
      />
    </>
  );
};

export default UploadButton;
