import React, { useEffect } from 'react';
import { BaseProps } from '../../types/componentType';
import useTitle from '../../hooks/generic/titleSet';
import { CustomForm } from '../../components/organisms/page/CustomValidFormItem';
import * as Yup from 'yup';
import { useMasterLoans } from '../../hooks/api/mastersHook';
import * as valids from 'constants/valids';
import StoreErrorDialog from '../../components/organisms/event/StoreErrorDialog';
import CustomEdit from '../../components/organisms/page/CustomEdit';
import useCustomForm from '../../hooks/generic/customForm';

import { useLoansEdit, useLoansShow } from '../../hooks/api/loansHooks';

interface Props extends BaseProps {}

const LoansEdit: React.FC<Props> = (props) => {
  useTitle(props.title);

  const mastersHooks = useMasterLoans();
  const showHooks = useLoansShow();
  const editHooks = useLoansEdit();

  const [{ isLoading, contents }] = mastersHooks;
  const [
    { isLoading: sIsLoading, contents: sContents },
    { setOption: sDoFetch },
  ] = showHooks;
  const [{ option }, { setOption: doSubmit }] = editHooks;

  const forms: CustomForm[][] = [
    [
      {
        name: 'user_in_charge_id',
        label: '営業担当者',
        type: 'select',
        selects: contents.users,
        defaultValue: 0,
        descendant: 'user_in_charge_id',
        valid: Yup.number()
          .required(valids.MSG_REQUIRES)
          .oneOf(
            (contents.users ?? []).map((obj: any) => obj.id),
            valids.MSG_IN,
          ),
      },
    ],
    [
      {
        name: 'charge',
        label: '初期単価',
        type: 'num',
        defaultValue: 500000,
        descendant: 'charge',
        valid: Yup.number().required(valids.MSG_REQUIRES),
      },
      {
        name: 'payment_charge',
        label: '支払単価',
        type: 'num',
        defaultValue: 150000,
        descendant: 'payment_charge',
        valid: Yup.number().required(valids.MSG_REQUIRES),
      },
      {
        name: 'settlement_unit',
        label: '精算単位',
        type: 'num',
        defaultValue: 15,
        descendant: 'settlement_unit',
        valid: Yup.number().required(valids.MSG_REQUIRES),
      },
      {
        name: 'settlement_lower',
        label: '精算下限',
        type: 'num',
        defaultValue: 140,
        descendant: 'settlement_lower',
        valid: Yup.number().required(valids.MSG_REQUIRES),
      },
      {
        name: 'settlement_upper',
        label: '精算上限',
        type: 'num',
        defaultValue: 180,
        descendant: 'settlement_upper',
        valid: Yup.number().required(valids.MSG_REQUIRES),
      },
      {
        name: 'payment_site',
        label: '支払サイト',
        type: 'num',
        defaultValue: 50,
        descendant: 'payment_site',
        valid: Yup.number().required(valids.MSG_REQUIRES),
      },
      {
        name: 'risk_level',
        label: '終了リスク',
        type: 'select',
        selects: contents.risk_level,
        defaultValue: 0,
        descendant: 'risk_level',
        valid: Yup.string()
          .required(valids.MSG_REQUIRES)
          .oneOf(
            (contents.risk_level ?? []).map((obj: any) => obj.id),
            valids.MSG_IN,
          ),
      },
    ],
  ];

  const childrenForms: CustomForm[] = [];

  // 初期ロード時、のみ実行をする。
  useEffect(() => {
    sDoFetch({ id: props.match.params.id });
  }, [props.match.params.id, sDoFetch]);

  const customForm = useCustomForm(
    forms,
    childrenForms,
    isLoading || sIsLoading,
    sContents,
  );
  const formHooks = customForm.formHooks;
  return (
    <>
      <StoreErrorDialog />
      <CustomEdit
        id={props.match.params.id}
        createHooks={editHooks}
        formHooks={formHooks}
        submitHandler={customForm.toSubmitHandler(
          doSubmit,
          option,
          props.match.params.id,
        )}
        forms={customForm.value}
        childrenForms={customForm.childrenValue}
        resource={props.resource}
        isLoading={isLoading || sIsLoading}
      />
    </>
  );
};

export default LoansEdit;
