import React, { useEffect } from 'react';

import TableBody, { SimpleTableColumn } from '../page/TableBody';
import { CircularProgress, Grid } from '@material-ui/core';
import FileUpload from '../../molecules/upload/FileUpload';
import { StaffFileType } from '../../../constants/staffFiles';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../assets/styles/ts/theme';
import { ApiStates } from '../../../hooks/api/type/ApiStates';

interface Props {
  children?: React.ReactNode;
  id: number;
  resource: string;
  is_skillsheet: boolean;
  showHooks: ApiStates<any, any>;
}

const useStyles = makeStyles(
  createStyles({
    tableUpload: theme.mixins.detailTable.main,
  }),
);

const SkillsheetHistoriesTable: React.FC<Props> = (props) => {
  const classes = useStyles();

  const [
    { contents, option, isLoading },
    { setOption: sDoFetch },
  ] = props.showHooks;

  const columns: SimpleTableColumn[] = [
    { id: 'id', label: 'ID', minWidth: 100 },
    { id: 'name', label: 'ファイル名', minWidth: 100 },
    { id: 'created_at', label: '作成日', minWidth: 100 },
    { id: 'path', label: '作成日', minWidth: 100, type: 'dl_button' },
  ];

  const rows = props.is_skillsheet
    ? contents?.skill_sheet_staff_files ?? []
    : contents?.pr_sheet_staff_files ?? [];
  const title = props.is_skillsheet
    ? 'スキルシート履歴'
    : 'エンジニア紹介シート';
  const type = props.is_skillsheet
    ? StaffFileType.SKILL_SHEET
    : StaffFileType.PR_SHEET;

  //初回取得
  useEffect(() => {
    sDoFetch({
      id: props.id,
      searchParams: { monthly_loan_histories_year: new Date().getFullYear() },
    });
  }, [props.id, sDoFetch]);
  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.tableUpload}>
                <FileUpload staffId={props.id} type={type} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <TableBody
                rows={rows}
                columns={columns}
                option={option}
                setOption={sDoFetch}
                contents={contents}
                resource={props.resource}
                title={title}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default SkillsheetHistoriesTable;
