import React from 'react';
import { CircularProgress as MuiCircularProgress } from '@material-ui/core';

interface Props {
  children?: React.ReactNode;
  isLoading?: boolean;
}

const CircularProgress: React.FC<Props> = (props) => {
  return <>{props.isLoading ? <MuiCircularProgress /> : props.children}</>;
};

export default CircularProgress;
