import React, { useReducer, useContext, createContext, Dispatch } from 'react';
import { reducer, initialState, State } from 'stores/unPersist/reducers';
import { Action } from 'typescript-fsa';

interface StoreContextProps {
  state: State;
  dispatch: Dispatch<Action<any>>; //FIXME: いつかユニオンにしたい<string or number or boolean or hogeType>みたいなの作る
}

interface StoreProviderProps {
  children: JSX.Element | JSX.Element[];
}

export const StoreProvider: React.FC<StoreProviderProps> = (
  props: StoreProviderProps,
): JSX.Element => {
  const { children } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useAppContext = (): React.ContextType<typeof StoreContext> =>
  useContext(StoreContext);

export const StoreContext = createContext({} as StoreContextProps);
