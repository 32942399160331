import React, { useEffect } from 'react';
import { BaseProps } from '../../types/componentType';
import useTitle from '../../hooks/generic/titleSet';
import CustomCreate from '../../components/organisms/page/CustomCreate';
import { CustomForm } from '../../components/organisms/page/CustomValidFormItem';
import useCustomForm from '../../hooks/generic/customForm';
import * as Yup from 'yup';
import {
  useMasterLoans,
  useMasterSuggestions,
} from '../../hooks/api/mastersHook';
import * as valids from 'constants/valids';
import StoreErrorDialog from '../../components/organisms/event/StoreErrorDialog';
import { useLoansCreate } from '../../hooks/api/loansHooks';

interface Props extends BaseProps {}

const LoansCreate: React.FC<Props> = (props) => {
  useTitle(props.title);

  const SuggestionMastersHooks = useMasterSuggestions(false);
  const LoanMastersHooks = useMasterLoans();
  const createHooks = useLoansCreate();
  const [
    { isLoading: SIsLoading, contents: SContents },
    { setOption: SDoMaster },
  ] = SuggestionMastersHooks;
  const [{ isLoading: LIsLoading, contents: LContents }] = LoanMastersHooks;
  const [{ option }, { setOption: doCreate }] = createHooks;

  useEffect(() => {
    SDoMaster({
      searchParams: { suggestion_id: props.match.params.suggestion_id },
    });
  }, []);

  const forms: CustomForm[][] = [
    [
      // {
      //   name: 'suggestion_id',
      //   label: '提案ID',
      //   type: 'hidden',
      //   defaultValue: parseInt(props.match.params.suggestion_id),
      // },
      {
        name: 'user_in_charge_id',
        label: '営業担当者',
        type: 'select',
        selects: SContents.users,
        defaultValue: 0,
        valid: Yup.number()
          .required(valids.MSG_REQUIRES)
          .oneOf(
            (SContents.users ?? []).map((obj: any) => obj.id),
            valids.MSG_IN,
          ),
      },
      {
        name: 'suggestion_project_id',
        label: '案件名',
        type: 'select',
        selects: SContents.suggestion_project,
        defaultValue: 0,
        valid: Yup.number()
          .required(valids.MSG_REQUIRES)
          .oneOf(
            (SContents.suggestion_project ?? []).map((obj: any) => obj.id),
            valids.MSG_IN,
          ),
      },
      {
        name: 'start_date',
        label: '出向開始日',
        type: 'date',
        defaultValue: new Date(),
        valid: Yup.date().required(valids.MSG_REQUIRES),
      },
      {
        name: 'contract_end_date',
        label: '契約終了日',
        type: 'date',
        defaultValue: new Date(),
        valid: Yup.date().required(valids.MSG_REQUIRES),
      },
    ],
    [
      {
        name: 'charge',
        label: '初期単価',
        type: 'num',
        defaultValue: 500000,
        valid: Yup.number().required(valids.MSG_REQUIRES),
      },
      {
        name: 'payment_charge',
        label: '支払単価',
        type: 'num',
        defaultValue: 150000,
        valid: Yup.number().required(valids.MSG_REQUIRES),
      },
      {
        name: 'settlement_unit',
        label: '精算単位',
        type: 'num',
        defaultValue: 15,
        valid: Yup.number().required(valids.MSG_REQUIRES),
      },
      {
        name: 'settlement_lower',
        label: '精算下限',
        type: 'num',
        defaultValue: 140,
        valid: Yup.number().required(valids.MSG_REQUIRES),
      },
      {
        name: 'settlement_upper',
        label: '精算上限',
        type: 'num',
        defaultValue: 180,
        valid: Yup.number().required(valids.MSG_REQUIRES),
      },
      {
        name: 'payment_site',
        label: '支払サイト',
        type: 'num',
        defaultValue: 50,
        valid: Yup.number().required(valids.MSG_REQUIRES),
      },
      {
        name: 'risk_level',
        label: '終了リスク',
        type: 'select',
        selects: LContents.risk_level,
        defaultValue: 0,
        valid: Yup.string()
          .required(valids.MSG_REQUIRES)
          .oneOf(
            (LContents.risk_level ?? []).map((obj: any) => obj.id),
            valids.MSG_IN,
          ),
      },
    ],
  ];

  const childrenForms: CustomForm[] = [];

  const customForm = useCustomForm(
    forms,
    childrenForms,
    SIsLoading || LIsLoading,
  );
  const formHooks = customForm.formHooks;
  return (
    <>
      <StoreErrorDialog />
      <CustomCreate
        createHooks={createHooks}
        formHooks={formHooks}
        submitHandler={customForm.toSubmitHandler(doCreate, option)}
        forms={customForm.value}
        childrenForms={customForm.childrenValue}
        resource={props.resource}
        isLoading={SIsLoading || LIsLoading}
      />
    </>
  );
};

export default LoansCreate;
