import React from 'react';

import { BaseProps } from '../../types/componentType';
import useTitle from '../../hooks/generic/titleSet';
import { useMasterLoanPlans } from '../../hooks/api/mastersHook';
import { EditForms } from '../../components/organisms/page/ValidFormItem';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { extractKeyValue, extractKeyValueByObject } from '../../utils/format';
import { yupResolver } from '@hookform/resolvers/yup';
import SimpleEdit from '../../components/organisms/page/SimpleEdit';
import {
  useLoanPlansEdit,
  useLoanPlansShow,
} from '../../hooks/api/loanPlansHooks';
import { validLoanPlans } from './valid';
import StoreErrorDialog from '../../components/organisms/event/StoreErrorDialog';

interface Props extends BaseProps {}

const LoanPlansEdit: React.FC<Props> = (props) => {
  useTitle(props.title);

  const showHooks = useLoanPlansShow();
  const editHooks = useLoanPlansEdit();
  const mastersHooks = useMasterLoanPlans();

  const [{ option }, { setOption: doEdit }] = editHooks;
  const [{ contents: sContents }] = showHooks;
  const [{ isLoading, contents }] = mastersHooks;

  const validSchema = Yup.object().shape(validLoanPlans(contents));

  const forms: EditForms[] = [
    {
      name: 'staff_id',
      label: 'スタッフ名',
      type: 'select',
      descendant: 'staff_id',
      selects: [
        { id: sContents?.staff?.id ?? 0, name: sContents?.staff?.name ?? '' },
      ], //TODO: 自分自身は返さないといけないな
      disabled: true,
      defaultValue: 0,
    },
    {
      name: 'user_in_charge_id',
      label: '営業担当者',
      type: 'select',
      descendant: 'user_in_charge_id',
      selects: contents.users,
      defaultValue: 0,
    },
    {
      name: 'start_date',
      label: '営業開始日',
      type: 'date',
      descendant: 'start_date',
      defaultValue: new Date(),
    },
    {
      name: 'expected_loan_date',
      label: '出向開始予定日',
      type: 'date',
      descendant: 'expected_loan_date',
      defaultValue: new Date(),
    },
    {
      name: 'expected_end_date',
      label: '営業終了予定日',
      type: 'date',
      descendant: 'expected_end_date',
      defaultValue: new Date(),
    },
    {
      name: 'target_charge',
      label: '目標単価',
      type: 'text',
      descendant: 'target_charge',
      defaultValue: 500000,
    },
    {
      name: 'memo_',
      label: '備考',
      type: 'textarea',
      descendant: 'memo',
      defaultValue: '',
    },
  ];

  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: extractKeyValue(forms, 'name', 'defaultValue'),
    resolver: yupResolver(validSchema),
  });

  const submitHandler = (data: any) => {
    const changedParam = {
      ...option.json,
      ...extractKeyValueByObject(forms, data, 'name', ['memo_']),
      // NOTE: memoだと予約言葉らしいので「_」をつける
      memo: data.memo_,
    };
    doEdit({ ...option, json: changedParam, id: props.match.params.id });
  };

  return (
    <>
      <StoreErrorDialog />
      <SimpleEdit
        id={props.match.params.id}
        resource={props.resource}
        editHooks={editHooks}
        showHooks={showHooks}
        formHooks={formHooks}
        submitHandler={submitHandler}
        forms={forms}
        isLoading={isLoading}
      />
    </>
  );
};

export default LoanPlansEdit;
