import {
  reducerWithInitialState,
  ReducerBuilder,
} from 'typescript-fsa-reducers';
import Actions from './actions';

export interface State {
  inputValue: string;
  clickCount: number;
}

export const initialState: State = {
  inputValue: 'デフォルト値',
  clickCount: 0,
};

export const reducer: ReducerBuilder<State> = reducerWithInitialState(
  initialState,
)
  .case(Actions.updateTextInputValue, (state: State, inputValue: string) => {
    return { ...state, inputValue };
  })
  .case(Actions.updateClickCount, (state: State) => {
    return { ...state, clickCount: state.clickCount + 1 };
  });
