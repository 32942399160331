import { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { StoreContext } from 'stores/unPersist/store';
import StoreDialog from '../../components/organisms/event/StoreDialog';
import actions from 'stores/unPersist/dialog/actions';

export default function Redux() {
  const { dispatch } = useContext(StoreContext);

  const buttonStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },
  })();

  const onClick = (): void => {
    console.log(dispatch);
    console.log('store');
    dispatch(
      actions.dialogOn({
        title: 'タイトル',
        content: 'コンテンツ',
      }),
    );
  };

  return (
    <div>
      サンドボックス second ページ
      <br />
      <Button className={buttonStyles.root} onClick={onClick}>
        ストア！
      </Button>
      <br />
      <StoreDialog />
    </div>
  );
}
