import React from 'react';

import SimpleIndex from '../../components/organisms/page/SimpleIndex';
import { useInput } from '../../hooks/generic/inputHook';
import useTitle from '../../hooks/generic/titleSet';
import { BaseProps } from '../../types/componentType';
import { useMasterLoans } from '../../hooks/api/mastersHook';
import { apiDateFormat, getDescendant } from '../../utils/format';
import { useLoansDelete, useLoansIndex } from '../../hooks/api/loansHooks';

interface Props extends BaseProps {}

const LoanIndex: React.FC<Props> = (props) => {
  useTitle(props.title);

  const indexHooks = useLoansIndex();
  const mastersHooks = useMasterLoans();
  const deleteHooks = useLoansDelete();

  const [{ isLoading, contents }] = mastersHooks;
  const [{ option }, { setOption }] = indexHooks;

  const columns = [
    { id: 'id', label: 'ID', minWidth: 20 },
    { id: 'staff.name', label: 'スタッフ', minWidth: 100 },
    { id: 'user_in_charge.name', label: '担当者', minWidth: 100 },
    { id: 'start_date', label: '出向開始日', minWidth: 120 },
    {
      id: 'contract_period',
      label: '契約期間',
      minWidth: 100,
      option: {
        unit: {
          last: 'ヶ月',
        },
      },
    }, //計算
    { id: 'contract_end_date', label: '契約終了予定日', minWidth: 150 },
    { id: 'last_client.name', label: '上位', minWidth: 170 },
    { id: 'end_client.name', label: 'エンド', minWidth: 170 },
    { id: 'm_project.name', label: '案件名', minWidth: 180 },
    { id: 'm_project.nearest_station', label: '最寄り駅', minWidth: 120 },
    {
      id: 'skill_sheet_update_schedule',
      label: 'スキルS更新予定',
      minWidth: 180,
    },
    { id: 'next_contract_update_date', label: '次回契約更新', minWidth: 120 },
    { id: 'settlement_width', label: '生産幅', minWidth: 100 }, //計算 settlement_lower - settlement_upper
    { id: 'contract_date', label: '契約更新日', minWidth: 120 },
    {
      id: 'risk_level',
      label: '終了リスク',
      minWidth: 120,
      option: {
        colorChange: {
          color: 'red',
          callback: (content: any) =>
            getDescendant(content, 'risk_level') === 'HIGH',
        },
        conversion: contents.risk_level,
      },
    },
    { id: 'charge', label: '実績単価', minWidth: 100 },
    { id: 'end_date', label: '出向終了日', minWidth: 150 },
    // { id: 'created_at', label: '作成日', minWidth: 100 },
    { id: 'detail_button', label: '詳細', minWidth: 50 },
  ];

  const lastClientId = useInput('', 'select', '上位', {
    selects: contents.last_clients ?? [],
    isMultiple: false,
  });

  const endClientId = useInput('', 'select', 'エンド', {
    selects: contents.end_clients ?? [],
    isMultiple: false,
  });

  const staffId = useInput('', 'select', 'スタッフ', {
    selects: contents.staffs ?? [],
    isMultiple: false,
  });

  const userId = useInput('', 'select', '担当者', {
    selects: contents.users ?? [],
    isMultiple: false,
  });

  const startDate = useInput(
    { from: null, to: null },
    'date_between',
    '出向開始日',
  );

  // const endDate = useInput(
  //   { from: null, to: null },
  //   'date_between',
  //   '終了予定日',
  // );

  const contractEndDate = useInput(
    { from: null, to: null },
    'date_between',
    '契約終了予定日',
  );

  const skillSheetUpdateScheduleDate = useInput(
    { from: null, to: null },
    'date_between',
    'スキルシート予定日',
  );

  const nextContractUpdateDate = useInput(
    { from: null, to: null },
    'date_between',
    '次回契約更新日',
  );

  const wishDone = useInput(false, 'checkbox', '完了済みも表示');
  const handleSearchSubmit = () => {
    const changedSearchParam = {
      ...option.searchParams,
      sales_route_last_client_id_eq: lastClientId.value,
      sales_route_end_client_id_eq: endClientId.value,
      staff_id_eq: staffId.value,
      user_in_charge_id_eq: userId.value,
      start_date_gt: apiDateFormat(startDate.value.from),
      start_date_lt: apiDateFormat(startDate.value.to),
      // end_date_gt: apiDateFormat(endDate.value.from),
      // end_date_lt: apiDateFormat(endDate.value.to),
      contract_end_date_gt: apiDateFormat(contractEndDate.value.from),
      contract_end_date_lt: apiDateFormat(contractEndDate.value.to),
      skill_sheet_update_schedule_gt: apiDateFormat(
        skillSheetUpdateScheduleDate.value.from,
      ),
      skill_sheet_update_schedule_lt: apiDateFormat(
        skillSheetUpdateScheduleDate.value.to,
      ),
      next_contract_update_date_gt: apiDateFormat(
        nextContractUpdateDate.value.from,
      ),
      next_contract_update_date_lt: apiDateFormat(
        nextContractUpdateDate.value.to,
      ),

      wish_done: wishDone.value,
    };

    setOption({ ...option, searchParams: changedSearchParam });
  };

  return (
    <>
      <SimpleIndex
        indexHooks={indexHooks}
        deleteHooks={deleteHooks}
        columns={columns}
        tableSearchStates={[
          lastClientId,
          endClientId,
          staffId,
          userId,
          startDate,
          // endDate,
          contractEndDate,
          skillSheetUpdateScheduleDate,
          nextContractUpdateDate,
          wishDone,
        ]}
        handleSearchSubmit={handleSearchSubmit}
        resource={props.resource}
        isLoading={isLoading}
      />
    </>
  );
};

export default LoanIndex;
