import React from 'react';

import { BaseProps } from '../../types/componentType';
import { useFieldArray, useForm } from 'react-hook-form';

interface Props extends BaseProps {}
interface sampleFormInterface {
  firstName: string;
  lastName: string;
}

const SandboxFields: React.FC<Props> = (props) => {
  console.log(props);
  const {
    register,
    handleSubmit,
    control, // 追加
  } = useForm<sampleFormInterface>();

  // 追加
  const { fields, append } = useFieldArray({
    control,
    name: 'sampleForm',
    keyName: 'key', // デフォルトではidだが、keyに変更。
  } as any);

  const onSubmit = (data: unknown) => {
    console.log(data);
  };

  // 追加
  const addInputForm = () => {
    append({ firstName: '', lastName: '' });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* 変更　*/}
        <ul>
          {fields.map((field: any, index: number) => (
            <li key={field.key} className="flex space-x-5">
              <input
                {...register(`sampleForm.${index}.firstName` as any)}
                placeholder="苗字"
              />
              <input
                {...register(`sampleForm.${index}.lastName` as any)}
                placeholder="名前"
              />
              {console.log(control)}
            </li>
          ))}
        </ul>
      </form>
      <button className="p-2 rounded-full bg-yellow-400" onClick={addInputForm}>
        追加ボタン
      </button>
    </>
  );
};

export default SandboxFields;
