import { useContext, useEffect } from 'react';
import actions from '../../../stores/unPersist/snackbar/actions';
import { StoreContext } from '../../../stores/unPersist/store';
import { useHistory } from 'react-router-dom';
import errorDialogAction from '../../../stores/unPersist/errorDialog/actions';

export const useApiResponseOk = (dResponse: any, message: string) => {
  const { dispatch } = useContext(StoreContext);

  useEffect(() => {
    if (dResponse.status === 200) {
      dispatch(
        actions.snackbarOn({
          severity: 'success',
          message: message,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dResponse, dispatch]);
};

export const useApiResponseOkAndTransition = (
  response: any,
  message: string,
  path: string,
) => {
  const { dispatch } = useContext(StoreContext);
  const history = useHistory();

  useEffect(() => {
    if (response.status === 200) {
      dispatch(
        actions.snackbarOn({
          severity: 'success',
          message: message,
        }),
      );
      history.push(path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, dispatch]);
};

export const useApiResponseOkAndReload = (
  dResponse: any,
  setOption: any,
  option: any,
  message: string,
) => {
  const { dispatch } = useContext(StoreContext);

  useEffect(() => {
    if (dResponse.status === 200) {
      dispatch(
        actions.snackbarOn({
          severity: 'success',
          message: message,
        }),
      );
      setOption({ ...option });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dResponse, dispatch]);
};

export const useApiResponseNg = (isError: boolean, message: string) => {
  const { dispatch } = useContext(StoreContext);

  useEffect(() => {
    if (isError) {
      dispatch(
        actions.snackbarOn({
          severity: 'error',
          message: message,
        }),
      );
    }
  }, [isError, dispatch, message]);
};

export const useApiResponseNgAndTransition = (
  isError: boolean,
  message: string,
  path: string,
) => {
  const { dispatch } = useContext(StoreContext);
  const history = useHistory();

  useEffect(() => {
    if (isError) {
      dispatch(
        actions.snackbarOn({
          severity: 'error',
          message: message,
        }),
      );
      history.push(path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
};

export const useApiResponseNgAndErrorDialog = (
  response: any,
  contents: any,
  isError: boolean,
) => {
  const { dispatch } = useContext(StoreContext);
  useEffect(() => {
    if (response.status === 400) {
      if (isError) {
        dispatch(
          errorDialogAction.errorDialogOn({
            errors: contents.errors,
          }),
        );
      }
    }
    if (response.status === 403) {
      if (isError) {
        dispatch(
          errorDialogAction.errorDialogOn({
            errors: [contents.errors],
          }),
        );
      }
    }
    // if (response.status === 401) {
    //   dispatch(
    //     actions.snackbarOn({
    //       severity: 'error',
    //       message: contents.errors,
    //     }),
    //   );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
};
