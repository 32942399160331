import {
  actionCreatorFactory,
  ActionCreatorFactory,
  ActionCreator,
} from 'typescript-fsa';

export interface Actions {
  updateTextInputValue: ActionCreator<string>;
  updateClickCount: ActionCreator<string>;
}

const actionCreator: ActionCreatorFactory = actionCreatorFactory();

const actions: Actions = {
  updateTextInputValue: actionCreator('ACTIONS_UPDATE_TEXT_INPUT_VALUE'),
  updateClickCount: actionCreator('ACTION_UPDATE_CLICK_COUNT'),
};

export default actions;
