import {
  actionCreatorFactory,
  ActionCreatorFactory,
  ActionCreator,
} from 'typescript-fsa';

export interface Actions {
  userSignin: ActionCreator<SetUserByLoginPayload>;
  userSignout: ActionCreator<null>;
}

export interface SetUserByLoginPayload {
  token: string | null;
  user: any;
}

const actionCreator: ActionCreatorFactory = actionCreatorFactory();

const actions: Actions = {
  userSignin: actionCreator('ACTIONS_USER_SIGNIN'),
  userSignout: actionCreator('ACTIONS_USER_SIGNUOUT'),
};

export default actions;
