import React from 'react';

import SimpleIndex from '../../components/organisms/page/SimpleIndex';
import { useInput } from '../../hooks/generic/inputHook';
import useTitle from '../../hooks/generic/titleSet';
import { BaseProps } from '../../types/componentType';
import { useMasterClients } from '../../hooks/api/mastersHook';
import {
  useClientsIndex,
  useClientsDelete,
} from '../../hooks/api/clientsHooks';

interface Props extends BaseProps {}

const MMilestonesIndex: React.FC<Props> = (props) => {
  useTitle(props.title);

  const indexHooks = useClientsIndex();
  const deleteHooks = useClientsDelete();
  const mastersHooks = useMasterClients();

  const [{ isLoading, contents }] = mastersHooks;

  const [{ option }, { setOption }] = indexHooks;
  /* eslint-disable no-empty-pattern */
  const [{}, { setOption: doDelete }] = deleteHooks;

  const columns = [
    { id: 'id', label: 'ID', minWidth: 30 },
    { id: 'name', label: '名前', minWidth: 180 },
    {
      id: 'type',
      label: 'タイプ',
      minWidth: 160,
      option: { conversion: contents.type },
    },
    {
      id: 'contract',
      label: '契約',
      minWidth: 100,
      option: { conversion: contents.contract },
    },
    { id: 'phone_number', label: '本社電話番号', minWidth: 130 },
    // { id: 'fax', label: 'ファックス', minWidth: 100 },
    { id: 'address', label: '住所', minWidth: 100 },
    // { id: 'created_at', label: '作成日', minWidth: 150 },
    { id: 'edit_button', label: '編集', minWidth: 100 },
    { id: 'delete_button', label: '削除', minWidth: 100 },
  ];

  const type = useInput([], 'select', 'タイプ', {
    selects: contents.type ?? [],
    isMultiple: true,
  });
  const name = useInput('', 'text', '名前');

  const handleSearchSubmit = () => {
    const changedSearchParam = {
      ...option.searchParams,
      type_in: type.value,
      name_like: name.value,
    };

    setOption({ ...option, searchParams: changedSearchParam });
  };

  const handleDeleteClick = (id: number) => {
    return () => {
      if (window.confirm('削除してもよろしいですか？')) {
        doDelete({ id: id });
      }
    };
  };

  return (
    <>
      <SimpleIndex
        indexHooks={indexHooks}
        deleteHooks={deleteHooks}
        columns={columns}
        tableSearchStates={[type, name]}
        handleSearchSubmit={handleSearchSubmit}
        handleDeleteClick={handleDeleteClick}
        resource={props.resource}
        isLoading={isLoading}
      />
    </>
  );
};

export default MMilestonesIndex;
