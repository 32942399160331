import React, { useContext } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { theme } from 'assets/styles/ts/theme';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Copyright from './Copyright';
import { StoreContext } from '../../../stores/unPersist/store';

interface Props {
  children?: React.ReactNode;
}

const useStyles = makeStyles(
  createStyles({
    appBarSpacer: theme.mixins.toolbar,
    main: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
      padding: theme.spacing(3),
    },
    paper: {
      minHeight: `calc(100vh - 172px)`,
      width: '100%',
    },
    pageTitle: {
      marginBottom: theme.spacing(2),
      textAlign: 'left',
    },
  }),
);

const Contents: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  const { state } = useContext(StoreContext);

  return (
    <main className={classes.main}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth={false} className={classes.container}>
        <Typography
          component="h6"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.pageTitle}
        >
          {state.ui.title}
        </Typography>
        <div className={classes.paper}>{children}</div>
      </Container>
      <Copyright />
    </main>
  );
};

export default Contents;
