import { Control } from 'react-hook-form/dist/types';
import { FormSelects } from '../../molecules/form/Selectbox';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { GridSize } from '@material-ui/core';
import { formatGridSize } from '../../../utils/format';
import CustomFormByType from '../../molecules/form/CustomFormByType';

export interface Props {
  control: Control<any, any>;
  register: any;
  setValue: any;
  errors: any;
  rows: CustomForm[][];
}

export interface CustomForm {
  name: string;
  label: string;
  type:
    | 'text'
    | 'select'
    | 'textarea'
    | 'date'
    | 'datetime'
    | 'array'
    | 'single_checkbox'
    | 'order'
    | 'num'
    | 'hidden';
  params?: CustomChildrenForm[];
  selects?: FormSelects[];
  defaultValue?: any;
  valid?: any;
  descendant?: string; //editの時しか使わない
  initAppendValue?: any; //editの時しか使わない
  disabled?: boolean;
  xs?: any;
  isStatic?: boolean;
  isNotSend?: boolean;
  onChange?: (e: any, setValue: any) => void;
}

export interface CustomChildrenForm {
  name: string;
  label: string;
  type:
    | 'text'
    | 'select'
    | 'textarea'
    | 'date'
    | 'hidden'
    | 'order'
    | 'single_checkbox';
  selects?: FormSelects[];
  defaultValue?: any;
  descendant?: string; //editの時しか使わない
  disabled?: boolean;
  valid?: any;
  onChange?: (e: any, setValue: any) => void;
}

const CustomValidFormItem: React.FC<Props> = (props) => {
  const classes = makeStyles({
    gridRecord: {
      // margin: '24px',
      textAlign: 'right',
    },
    gridItem: {
      paddingLeft: '12px',
      paddingRight: '12px',
    },
  })();

  const xs: GridSize = formatGridSize(
    Array.isArray(props.rows[0]) ? 12 / props.rows.length : 12,
  );

  return (
    <>
      <Grid container className={classes.gridRecord}>
        {props.rows.map((rows: any, targetIndex: number) => (
          <Grid item xs={xs} className={classes.gridItem} key={targetIndex}>
            {rows.map((form: CustomForm, index: number) => (
              <React.Fragment key={index}>
                <CustomFormByType
                  control={props.control}
                  register={props.register}
                  setValue={props.setValue}
                  errors={props.errors}
                  rows={form}
                />
              </React.Fragment>
            ))}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default CustomValidFormItem;
