import { useContext, useEffect } from 'react';
import actions from '../../stores/unPersist/ui/actions';
import { StoreContext } from '../../stores/unPersist/store';

export default function useTitle(title: string) {
  const { dispatch } = useContext(StoreContext);

  useEffect(() => {
    dispatch(
      actions.setTitle({
        title: title,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
