import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import {
  useApiResponseNgAndErrorDialog,
  useApiResponseOkAndTransition,
} from '../../../hooks/api/generic/apiResponseHandling';
import { ApiStates } from '../../../hooks/api/type/ApiStates';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { CircularProgress } from '@material-ui/core';
import ValidFormItem, { CreateForm } from './ValidFormItem';
import FormSubmit from './FormSubmit';

interface SubmitHandler {
  (data: any): void;
}

interface Props {
  children?: React.ReactNode;
  createHooks: ApiStates<any, any>;
  formHooks: UseFormReturn<any>;
  submitHandler: SubmitHandler;
  forms: CreateForm[];
  resource: string;
  isLoading?: boolean;
}

const SimpleCreate: React.FC<Props> = (props) => {
  const classes = makeStyles({
    card: {
      padding: '24px',
    },
  })();

  const [{ contents, response, isLoading, isError }] = props.createHooks;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = props.formHooks;

  useApiResponseOkAndTransition(
    response,
    '登録に成功しました。',
    '/' + props.resource,
  );

  // useApiResponseNgAndTransition(
  //   isError,
  //   '初期取得に失敗しました',
  //   `/${props.resource}`,
  // );

  useApiResponseNgAndErrorDialog(response, contents, isError);

  return (
    <>
      <Card className={classes.card}>
        {props.isLoading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSubmit(props.submitHandler)}>
            <ValidFormItem
              control={control}
              errors={errors}
              rows={props.forms}
            />
            <FormSubmit isLoading={isLoading}>登録</FormSubmit>
          </form>
        )}
      </Card>
    </>
  );
};

export default SimpleCreate;
