import React, { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { theme } from 'assets/styles/ts/theme';
import { Button } from '@material-ui/core';
import userActions from '../../../stores/persist/users/actions';
import { PersistStoreContext } from '../../../stores/persist/store';
import { useHistory } from 'react-router-dom';

const drawerWidth = 240; //TODO:

interface Props {
  children?: React.ReactNode;
  handleDrawerToggle: any;
  open: any;
}

const useStyles = makeStyles(
  createStyles({
    toolbar: {
      paddingRight: 24,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      // width: `calc(100% - ${theme.spacing(7)}px)`,
      width: `calc(100%)`,
      [theme.breakpoints.up('sm')]: {
        // width: `calc(100% - ${theme.spacing(9)}px)`,
        width: `calc(100%)`,
      },
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: -1,
    },
    titleText: {
      lineHeight: '100%',
    },
    logoutButton: {
      textAlign: 'right',
    },
  }),
);

const Header: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { dispatch: persistDispatch } = useContext(PersistStoreContext);
  const history = useHistory();
  const logout = () => {
    persistDispatch(userActions.userSignout(null));
    history.push('/login');
  };
  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, props.open && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => props.handleDrawerToggle(props.open)}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Button
          variant="contained"
          className={classes.logoutButton}
          onClick={logout}
        >
          ログアウト
        </Button>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
