import { Reducer } from 'react';
import combineReducers from 'react-combine-reducers';

import { Action } from 'typescript-fsa';

import {
  reducer as snackbarReducer,
  initialState as snackbarInitialState,
  State as SnackbarState,
} from 'stores/unPersist/snackbar/reducer';

import {
  reducer as dialogReducer,
  initialState as dialogInitialState,
  State as DialogState,
} from 'stores/unPersist/dialog/reducer';

import {
  reducer as errorDialogReducer,
  initialState as errorDialogInitialState,
  State as ErrorDialogState,
} from 'stores/unPersist/errorDialog/reducer';

import {
  reducer as uiReducer,
  initialState as uiInitialState,
  State as UiState,
} from 'stores/unPersist/ui/reducer';

export interface State {
  snackbar: SnackbarState;
  dialog: DialogState;
  errorDialog: ErrorDialogState;
  ui: UiState;
}

export const [reducer, initialState]: [
  Reducer<State, Action<any>>,
  State,
] = combineReducers({
  snackbar: [snackbarReducer, snackbarInitialState],
  dialog: [dialogReducer, dialogInitialState],
  errorDialog: [errorDialogReducer, errorDialogInitialState],
  ui: [uiReducer, uiInitialState],
});
