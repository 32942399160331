import * as Yup from 'yup';

export const validMProject = (master: any) => {
  const clients = master.clients ?? [];
  console.log(clients);
  return {
    name: Yup.string().required('必須項目です'),
    client_id: Yup.number().oneOf(
      clients.map((obj: any) => obj.id),
      'いずれかを選択してください',
    ),
    summary: Yup.string().required('必須項目です'),
    nearest_station: Yup.string().required('必須項目です'),
    skill_summary: Yup.string().required('必須項目です'),
    memo_: Yup.string().required('必須項目です'),
  };
};
