import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../assets/styles/ts/theme';
import { ApiStates } from '../../../hooks/api/type/ApiStates';
import { CircularProgress, GridSize } from '@material-ui/core';
import { useApiResponseNgAndTransition } from '../../../hooks/api/generic/apiResponseHandling';
import Grid from '@material-ui/core/Grid';
import { formatGridSize, getDescendant } from '../../../utils/format';
import CustomTableCell from '../tabelCell/CustomTableCell';
import { SimpleTableColumn } from '../../organisms/page/TableBody';

interface Props {
  children?: React.ReactNode;
  showHooks: ApiStates<any, any>;
  columns: any;
  id: number;
  resource: string;
  title?: string;
  rows?: any;
}

const useStyles = makeStyles(
  createStyles({
    table: theme.mixins.detailTable.main,
    tableHeader: theme.mixins.detailTable.header,
    tableBody: theme.mixins.detailTable.body,
    tableRow: {
      // width: '100%'
    },
    tableTitle: theme.mixins.detailTable.title,
  }),
);

const DetailTable: React.FC<Props> = (props) => {
  const classes = useStyles();

  const [
    { contents, isLoading: sIsLoading, isError: sIsError },
  ] = props.showHooks;

  const rows = props.rows ?? contents ?? [];

  useApiResponseNgAndTransition(
    sIsError,
    '初期取得に失敗しました',
    `/${props.resource}`,
  );

  const xs: GridSize = formatGridSize(
    Array.isArray(props.columns[0]) ? 12 / props.columns.length : 12,
  );
  const target = Array.isArray(props.columns[0])
    ? props.columns
    : [props.columns];

  const headerText = (rows: any, column: SimpleTableColumn) => {
    let label = column.label;
    if (column.option?.headerConversion?.length > 0) {
      let value = getDescendant(rows, column.label);
      label = value;
      const target = column.option.headerConversion.find(
        (e: { id: string; name: string }) => {
          return e.id === value;
        },
      );
      if (typeof target !== 'undefined') {
        label = target.name;
      }
    }
    return label;
  };
  return (
    <>
      {sIsLoading || !contents.id ? (
        <CircularProgress />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            {props.title ? (
              <div className={classes.tableTitle}>{props.title}</div>
            ) : (
              ''
            )}
          </Grid>
          {target.map((column: any, index: number) => {
            const cell = () => {
              return column.map((item: any, i: number) => (
                <table
                  className={classes.table}
                  key={('000' + index).slice(-3) + ('000' + i).slice(-3)}
                >
                  <tr className={classes.tableRow}>
                    <th className={classes.tableHeader}>
                      {headerText(rows, item)}
                    </th>
                    <td className={classes.tableBody}>
                      <CustomTableCell
                        // 2次元のため重複の可能性あり
                        column={item}
                        content={rows}
                        resource={props.resource}
                        isBeside={true}
                      />
                    </td>
                  </tr>
                </table>
              ));
            };
            return (
              <Grid item xs={xs}>
                {cell()}
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default DetailTable;
