import React from 'react';
import MTablePagination from '@material-ui/core/TablePagination';

interface Props {
  children?: React.ReactNode;
  option: any;
  setOption: React.Dispatch<React.SetStateAction<any>>;
  contents: any;
}

const TablePagination: React.FC<Props> = (props) => {
  const undefinedFormat = (target: any, format: any) => {
    return typeof target !== 'undefined' ? target : format;
  };

  const handleChangeRowsPerPage = (event: any) => {
    const changedSearchParam = {
      ...props.option.searchParams,
      page: 1,
      limit: +event.target.value,
    };
    props.setOption({ ...props.option, searchParams: changedSearchParam });
  };

  //@ts-ignore 'event' is declared but its value is never read.
  const handleChangePage = (event: any, newPage: any) => {
    const changedSearchParam = {
      ...props.option.searchParams,
      page: newPage + 1,
    };
    props.setOption({ ...props.option, searchParams: changedSearchParam });
  };

  return (
    <>
      <MTablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={undefinedFormat(props.contents.total, 0)}
        rowsPerPage={props.option.searchParams.limit}
        page={props.option.searchParams.page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default TablePagination;
