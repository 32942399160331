import React, { useEffect } from 'react';
import { BaseProps } from '../../types/componentType';
import useTitle from '../../hooks/generic/titleSet';
import { CustomForm } from '../../components/organisms/page/CustomValidFormItem';
import * as Yup from 'yup';
import * as valids from 'constants/valids';
import StoreErrorDialog from '../../components/organisms/event/StoreErrorDialog';
import CustomEdit from '../../components/organisms/page/CustomEdit';
import useCustomForm from '../../hooks/generic/customForm';

import { useLoansEdit, useLoansShow } from '../../hooks/api/loansHooks';

interface Props extends BaseProps {}

const LoansUpdateContract: React.FC<Props> = (props) => {
  useTitle(props.title);

  const showHooks = useLoansShow();
  const editHooks = useLoansEdit();

  const [
    { isLoading: sIsLoading, contents: sContents },
    { setOption: sDoFetch },
  ] = showHooks;
  const [{ option }, { setOption: doSubmit }] = editHooks;

  const forms: CustomForm[][] = [
    [
      {
        name: 'contract_date',
        label: '契約更新日',
        type: 'date',
        descendant: 'contract_date',
        defaultValue: new Date(),
        valid: Yup.date().required(valids.MSG_REQUIRES),
      },
      {
        name: 'contract_end_date',
        label: '契約終了予定日',
        type: 'date',
        descendant: 'contract_end_date',
        defaultValue: new Date(),
        valid: Yup.date().required(valids.MSG_REQUIRES),
      },
    ],
    [],
  ];

  const childrenForms: CustomForm[] = [];

  // 初期ロード時、のみ実行をする。
  useEffect(() => {
    sDoFetch({ id: props.match.params.id });
  }, [props.match.params.id, sDoFetch]);

  const customForm = useCustomForm(forms, childrenForms, sIsLoading, sContents);
  const formHooks = customForm.formHooks;
  return (
    <>
      <StoreErrorDialog />
      <CustomEdit
        id={props.match.params.id}
        createHooks={editHooks}
        formHooks={formHooks}
        submitHandler={customForm.toSubmitHandler(
          doSubmit,
          option,
          props.match.params.id,
        )}
        forms={customForm.value}
        childrenForms={customForm.childrenValue}
        resource={props.resource}
        isLoading={sIsLoading}
      />
    </>
  );
};

export default LoansUpdateContract;
