import {
  reducerWithInitialState,
  ReducerBuilder,
} from 'typescript-fsa-reducers';
import Actions, { DialogOnPayload } from 'stores/unPersist/dialog/actions';

export interface State {
  isOpen: boolean;
  title: string;
  content: string;
}

export const initialState: State = {
  isOpen: false,
  title: '',
  content: '',
};

export const reducer: ReducerBuilder<State> = reducerWithInitialState(
  initialState,
) //@ts-ignore 'state' is declared but its value is never read.
  .case(Actions.dialogOn, (state: State, payload: DialogOnPayload) => {
    return {
      isOpen: true,
      title: payload.title,
      content: payload.content,
    };
  })
  .case(Actions.dialogOff, () => {
    return initialState;
  });
