import React from 'react';

import SimpleIndex from '../../components/organisms/page/SimpleIndex';
import { useInput } from '../../hooks/generic/inputHook';
import useTitle from '../../hooks/generic/titleSet';
import { BaseProps } from '../../types/componentType';
import { useMasterInterviews } from '../../hooks/api/mastersHook';
import { apiDateFormat } from '../../utils/format';
import {
  useInterviewsDelete,
  useInterviewsIndex,
} from '../../hooks/api/interviewsHooks';

interface Props extends BaseProps {}

const MMilestonesIndex: React.FC<Props> = (props) => {
  useTitle(props.title);

  const indexHooks = useInterviewsIndex();
  const deleteHooks = useInterviewsDelete();
  const mastersHooks = useMasterInterviews();

  const [{ isLoading, contents }] = mastersHooks;

  const [{ option }, { setOption }] = indexHooks;
  /* eslint-disable no-empty-pattern */
  const [{}, { setOption: doDelete }] = deleteHooks;

  const columns = [
    { id: 'id', label: 'ID', minWidth: 20 },
    { id: 'last_client.name', label: '上位', minWidth: 170 },
    { id: 'end_client.name', label: 'エンド', minWidth: 170 },
    { id: 'loan_plan.staff.name', label: 'スタッフ', minWidth: 100 },
    { id: 'user_in_charge.name', label: '担当者', minWidth: 100 },
    { id: 'attendance_user.name', label: '面談同席者', minWidth: 120 },
    { id: 'order', label: 'X次面談', minWidth: 100 },
    { id: 'start_datetime', label: '面談開始時間', minWidth: 170 },
    { id: 'end_datetime', label: '面談終了時間', minWidth: 170 },

    // { id: 'created_at', label: '作成日', minWidth: 100 },
    { id: 'detail_button', label: '詳細', minWidth: 50 },
    {
      id: 'm_project_status',
      label: '案件名 / 最寄り駅',
      minWidth: 300,
      type: 'list',
      option: {
        target: 'm_projects',
        text: '{name} / {nearest_station}',
      },
    },
  ];

  const lastClientId = useInput('', 'select', '上位', {
    selects: contents.last_clients ?? [],
    isMultiple: false,
  });

  const endClientId = useInput('', 'select', 'エンド', {
    selects: contents.end_clients ?? [],
    isMultiple: false,
  });

  const staffId = useInput('', 'select', 'スタッフ', {
    selects: contents.staffs ?? [],
    isMultiple: false,
  });

  const userId = useInput('', 'select', '担当者', {
    selects: contents.users ?? [],
    isMultiple: false,
  });

  const attendanceUserId = useInput('', 'select', '面談同席者', {
    selects: contents.users ?? [],
    isMultiple: false,
  });

  const startDate = useInput(
    { from: null, to: null },
    'date_between',
    '面談開始時間',
  );

  const wishDone = useInput(false, 'checkbox', '完了済みも表示');
  const handleSearchSubmit = () => {
    const changedSearchParam = {
      ...option.searchParams,
      sales_route_last_client_id_eq: lastClientId.value,
      sales_route_end_client_id_eq: endClientId.value,
      loan_plan_staff_id_eq: staffId.value,
      user_in_charge_id_eq: userId.value,
      attendance_user_id_eq: attendanceUserId.value,
      start_datetime_gt: apiDateFormat(startDate.value.from),
      start_datetime_lt: apiDateFormat(startDate.value.to),
      wish_done: wishDone.value,
    };

    setOption({ ...option, searchParams: changedSearchParam });
  };

  const handleDeleteClick = (id: number) => {
    return () => {
      if (window.confirm('削除してもよろしいですか？')) {
        doDelete({ id: id });
      }
    };
  };

  return (
    <>
      <SimpleIndex
        indexHooks={indexHooks}
        deleteHooks={deleteHooks}
        columns={columns}
        tableSearchStates={[
          lastClientId,
          endClientId,
          staffId,
          userId,
          attendanceUserId,
          startDate,
          wishDone,
        ]}
        handleSearchSubmit={handleSearchSubmit}
        handleDeleteClick={handleDeleteClick}
        resource={props.resource}
        isLoading={isLoading}
      />
    </>
  );
};

export default MMilestonesIndex;
