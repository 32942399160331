import React from 'react';
import ReactDOM from 'react-dom';
import Router from './router';
import './index.css';
import 'assets/styles/App.css';
import { StoreProvider } from './stores/unPersist/store';
import { PersistStoreProvider } from './stores/persist/store';
import reportWebVitals from './reportWebVitals';
import StoreSnackBar from './components/organisms/event/StoreSnackBar';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

declare module '@material-ui/core/styles/createMixins' {
  interface Mixins {
    detailTable: {
      main: CSSProperties;
      header: CSSProperties;
      body: CSSProperties;
      title: CSSProperties;
    };
    formBorder: {
      main: CSSProperties;
    };
  }
}
// declare module '@material-ui/core/styles/createPalette' {
//   interface Palette {
//     border?: PaletteColor;
//   }
//   interface PaletteOptions {
//     border?: PaletteColorOptions;
//   }
// }
const StrictMode = React.StrictMode;
// const StrictMode = React.Fragment;
ReactDOM.render(
  <StrictMode>
    <PersistStoreProvider>
      <StoreProvider>
        <div className="App">
          <Router />
          <StoreSnackBar />
        </div>
      </StoreProvider>
    </PersistStoreProvider>
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Noto+Sans+JP&subset=japanese"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
    />
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
