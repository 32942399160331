import {
  reducerWithInitialState,
  ReducerBuilder,
} from 'typescript-fsa-reducers';
import Actions, { SetUserByLoginPayload } from 'stores/persist/users/actions';

export interface State {
  token: string | null;
  user: any;
}

export const initialState: State = {
  token: null,
  user: null,
};

export const reducer: ReducerBuilder<State> = reducerWithInitialState(
  initialState,
)
  .case(Actions.userSignin, (state: State, payload: SetUserByLoginPayload) => {
    console.log(state);
    return { ...payload };
  })
  .case(Actions.userSignout, (state: State) => {
    console.log(state);
    return { ...initialState };
  });
