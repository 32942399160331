import React from 'react';
import EditTableCell from './EditTableCell';
import DeleteTableCell from './DeleteTableCell';
import ListTableCell from './ListTableCell';
import { SimpleTableColumn } from '../../organisms/page/TableBody';
import TableCell from '@material-ui/core/TableCell';
import DefaultTableCell from './DefaultTableCell';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import DlButtonTableCell from './DlButtonTableCell';
import DetailTableCell from './DetailTableCell';

interface Props {
  children?: React.ReactNode;
  column: SimpleTableColumn;
  content: any;
  resource: string;
  deleteOnClick?: any;
  isBeside?: boolean;
}

const useStyles = makeStyles(
  createStyles({
    link: {
      textDecoration: 'none　!important',
    },
  }),
);

const CustomTableCell: React.FC<Props> = (props) => {
  const classes = useStyles();

  const component = () => {
    if (props.column.id === 'detail_button') {
      return (
        <DetailTableCell
          column={props.column}
          content={props.content}
          resource={props.resource}
        />
      );
    }

    if (props.column.id === 'edit_button') {
      return (
        <EditTableCell
          column={props.column}
          content={props.content}
          resource={props.resource}
        />
      );
    }
    if (props.column.id === 'delete_button') {
      return (
        <DeleteTableCell
          column={props.column}
          content={props.content}
          deleteOnClick={props.deleteOnClick}
        />
      );
    }

    if (props.column.type === 'dl_button') {
      return (
        <DlButtonTableCell column={props.column} content={props.content} />
      );
    }

    if (props.column.type === 'list') {
      return <ListTableCell column={props.column} content={props.content} />;
    }
    return (
      <div style={props.column.style}>
        <DefaultTableCell column={props.column} content={props.content} />
      </div>
    );
  };

  return props.isBeside ? (
    <>{component()}</>
  ) : (
    <TableCell align={props.column.align} className={classes.link}>
      {' '}
      {component()}{' '}
    </TableCell>
  );
};

export default CustomTableCell;
