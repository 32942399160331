import React from 'react';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../assets/styles/ts/theme';
import { Link } from 'react-router-dom';

interface Props {
  children?: React.ReactNode;
  column: any;
  content: any;
  resource: string;
}

const useStyles = makeStyles(
  createStyles({
    editButton: {
      background: theme.palette.warning.main,
      color: 'white',
      '&:hover': {
        background: theme.palette.warning.dark,
      },
      textDecoration: 'none',
    },
  }),
);

const EditTableCell: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <Link to={`/${props.resource}/edit/${props.content.id}`}>
      <Button variant="contained" className={classes.editButton} size="small">
        編集
      </Button>
    </Link>
  );
};

export default EditTableCell;
