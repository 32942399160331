import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Login from 'pages/login';
import Default from 'router/layouts/default';
import Sandbox from 'router/layouts/sandbox';

import Error from 'router/layouts/error';
import { PrivateRoute } from './rules';

interface Props {
  children?: React.ReactNode;
}
interface State {}

class Router extends React.Component<Props, State> {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          {/*Switchにしてるので上から順に最初に引っかかったところにいく*/}
          <Route exact path="/login" component={Login} />
          <Route path="/sandbox" component={Sandbox} />
          <Route path="/error" component={Error} />
          <PrivateRoute path="/" component={Default} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Router;
