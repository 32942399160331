import { Route, Switch } from 'react-router-dom';
import Default from 'layouts/default';
import NotFound from 'pages/error/notfound';

// 出向計画
import LoanPlansIndex from 'pages/loan_plans/index';
import LoanPlansDetail from '../../pages/loan_plans/detail';
import LoanPlansCreate from '../../pages/loan_plans/create';
import LoanPlansEdit from '../../pages/loan_plans/edit';
// 提案
import SuggestionsIndex from 'pages/suggestions/index';
import SuggestionsDetail from '../../pages/suggestions/detail';
import SuggestionsCreate from '../../pages/suggestions/create';
import SuggestionsEdit from '../../pages/suggestions/edit';
import SuggestionsCompleted from '../../pages/suggestions/completed';
// 面談
import InterviewsIndex from 'pages/interviews/index';
import InterviewsDetail from 'pages/interviews/detail';
import InterviewsCreate from '../../pages/interviews/create';
import InterviewsEdit from '../../pages/interviews/edit';
// 出向
import LoansIndex from 'pages/loans/index';
import LoansDetail from 'pages/loans/detail';
import LoansCreate from '../../pages/loans/create';
import LoansEdit from '../../pages/loans/edit';
import LoansCompleted from '../../pages/loans/completed';
// スタッフ
import StaffsIndex from 'pages/staffs/index';
import StaffsDetail from 'pages/staffs/detail';
import StaffsCreate from '../../pages/staffs/create';
import StaffsEdit from '../../pages/staffs/edit';
// クライアント
import ClientsIndex from 'pages/clients/index';
import ClientsCreate from '../../pages/clients/create';
import ClientsEdit from '../../pages/clients/edit';
import ClientsSandbox from '../../pages/clients/sandbox';
// スタッフ役職
import MOccupationsIndex from 'pages/m_occupations/index';
import MOccupationsCreate from 'pages/m_occupations/create';
import MOccupationsEdit from 'pages/m_occupations/edit';
// スタッフ工程
import MMilestonesIndex from 'pages/m_milestones';
import MMilestonesCreate from 'pages/m_milestones/create';
import MMilestonesEdit from 'pages/m_milestones/edit';
// スタッフスキル
import MSkillsIndex from 'pages/m_skills';
import MSkillsCreate from 'pages/m_skills/create';
import MSkillsEdit from 'pages/m_skills/edit';
// 案件
import MProjectsIndex from 'pages/m_projects';
import MProjectsCreate from 'pages/m_projects/create';
import MProjectsEdit from 'pages/m_projects/edit';

import React from 'react';
import LoansUpdateContract from '../../pages/loans/updateContract';

interface Router {
  path: string;
  title: string;
  component: React.FC<any>;
}

interface Routers {
  staffs: Router[];
  m_occupations: Router[];
  m_milestones: Router[];
  m_skills: Router[];
  m_projects: Router[];
  loan_plans: Router[];
  suggestions: Router[];
  interviews: Router[];
}

export default function DefaultRouter() {
  // https://qiita.com/nostrict/items/53a68156e444b9334a9e/

  const render = (
    Component: React.FC<any>,
    resource: string,
    title: string,
  ) => {
    return ({ match }: { match: any }) => (
      <Component resource={resource} title={title} match={match} />
    );
  };

  const routers: any = {
    loan_plans: [
      {
        path: '/loan_plans',
        title: '出向計画一覧',
        component: LoanPlansIndex,
      },
      {
        path: '/loan_plans/create',
        title: '出向計画作成',
        component: LoanPlansCreate,
      },
      {
        path: '/loan_plans/:id/edit',
        title: '出向計画編集',
        component: LoanPlansEdit,
      },
      {
        path: '/loan_plans/:id',
        title: '出向計画詳細',
        component: LoanPlansDetail,
      },
    ],
    suggestions: [
      {
        path: '/suggestions',
        title: '提案一覧',
        component: SuggestionsIndex,
      },
      {
        path: '/loan_plans/:loan_plan_id/suggestions/create',
        title: '提案作成',
        component: SuggestionsCreate,
      },
      {
        path: '/suggestions/:id/edit',
        title: '提案編集',
        component: SuggestionsEdit,
      },
      {
        path: '/suggestions/:id',
        title: '提案詳細',
        component: SuggestionsDetail,
      },
      {
        path: '/suggestions/:id/completed',
        title: '提案辞退',
        component: SuggestionsCompleted,
      },
    ],
    interviews: [
      {
        path: '/interviews',
        title: '面談一覧',
        component: InterviewsIndex,
      },
      {
        path:
          '/loan_plans/:loan_plan_id/suggestions/:suggestion_id/interviews/create',
        title: '面談作成',
        component: InterviewsCreate,
      },
      {
        path: '/interviews/:id/edit',
        title: '面談編集',
        component: InterviewsEdit,
      },
      {
        path: '/interviews/:id',
        title: '面談詳細',
        component: InterviewsDetail,
      },
    ],
    loans: [
      {
        path: '/loans',
        title: '出向一覧',
        component: LoansIndex,
      },
      {
        path: '/loans/:id',
        title: '出向詳細',
        component: LoansDetail,
      },
      {
        path: '/loans/:id/completed',
        title: '出向終了',
        component: LoansCompleted,
      },
      {
        path: '/loans/:id/updateContract',
        title: '出向終了',
        component: LoansUpdateContract,
      },
      {
        path: '/loans/:id/edit',
        title: '出向終了',
        component: LoansEdit,
      },
      {
        path: '/suggestions/:suggestion_id/loans/create',
        title: '参画確定',
        component: LoansCreate,
      },
    ],
    staffs: [
      {
        path: '/staffs',
        title: 'スタッフ一覧',
        component: StaffsIndex,
      },
      {
        path: '/staffs/create',
        title: 'スタッフ作成',
        component: StaffsCreate,
      },
      {
        path: '/staffs/:id/edit',
        title: 'スタッフ詳細',
        component: StaffsEdit,
      },
      {
        path: '/staffs/:id',
        title: 'スタッフ詳細',
        component: StaffsDetail,
      },
    ],
    clients: [
      {
        path: '/clients',
        title: 'クライアント一覧',
        component: ClientsIndex,
      },
      {
        path: '/clients/create',
        title: 'クライアント作成',
        component: ClientsCreate,
      },
      {
        path: '/clients/edit/:id',
        title: 'クライアント編集',
        component: ClientsEdit,
      },
      {
        path: '/clients/sandbox',
        title: 'クライアントサンドボックス',
        component: ClientsSandbox,
      },
    ],
    m_occupations: [
      {
        path: '/m_occupations',
        title: '職種マスタ一覧',
        component: MOccupationsIndex,
      },
      {
        path: '/m_occupations/create',
        title: '職種マスタ作成',
        component: MOccupationsCreate,
      },
      {
        path: '/m_occupations/edit/:id',
        title: '職種マスタ編集',
        component: MOccupationsEdit,
      },
    ],
    m_milestones: [
      {
        path: '/m_milestones',
        title: '工程マスタ一覧',
        component: MMilestonesIndex,
      },
      {
        path: '/m_milestones/create',
        title: '工程マスタ作成',
        component: MMilestonesCreate,
      },
      {
        path: '/m_milestones/edit/:id',
        title: '工程マスタ編集',
        component: MMilestonesEdit,
      },
    ],
    m_skills: [
      {
        path: '/m_skills',
        title: 'スキルマスタ一覧',
        component: MSkillsIndex,
      },
      {
        path: '/m_skills/create',
        title: 'スキルマスタ作成',
        component: MSkillsCreate,
      },
      {
        path: '/m_skills/edit/:id',
        title: 'スキルマスタ編集',
        component: MSkillsEdit,
      },
    ],
    m_projects: [
      {
        path: '/m_projects',
        title: '案件一覧',
        component: MProjectsIndex,
      },
      {
        path: '/m_projects/create',
        title: '案件作成',
        component: MProjectsCreate,
      },
      {
        path: '/m_projects/edit/:id',
        title: '案件編集',
        component: MProjectsEdit,
      },
    ],
  };

  return (
    <div>
      <Default>
        <Switch>
          {/*ダッシュボード*/}
          <Route exact path="/" component={LoanPlansIndex} />

          {Object.keys(routers).map((resource: string) => {
            return routers[
              resource as keyof Routers
            ].map((r: Router, i: number) => (
              <Route
                exact
                path={r.path}
                render={render(r.component, resource, r.title)}
                key={i}
              />
            ));
          })}
          <Route component={NotFound} />
        </Switch>
      </Default>
    </div>
  );
}
