import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DateBetween from '../../molecules/searchForm/DateBetween';
import Select from '../../molecules/searchForm/Select';

interface Props {
  children?: React.ReactNode;
  rows: any;
  handler: any;
}

const useStyles = makeStyles(
  createStyles({
    searchBox: {
      paddingBottom: '24px',
    },
    searchTextBox: {
      paddingBottom: '12px',
      paddingLeft: '6px',
      textAlign: 'left',
    },
    searchSubmit: {
      paddingLeft: '6px',
      paddingRight: '12px',
      textAlign: 'right',
    },
    selectBox: {
      width: '70%',
    },
    dateBox: {
      width: '40%',
    },
  }),
);

const TableSearch: React.FC<Props> = (props) => {
  const classes = useStyles();

  const createGrid = 2 - (props.rows.length % 3);

  const addGrid = () => {
    let addGrid = [];
    for (let i = 0; i < createGrid; i++) {
      addGrid.push(<Grid item xs={4} key={i} />);
    }
    return addGrid;
  };

  const rowGrid = (row: any, index: number) => {
    switch (row.type) {
      case 'text':
        return (
          <Grid item xs={4} className={classes.searchTextBox} key={index}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              {row.label}
            </InputLabel>
            <TextField
              id="outlined-search"
              type="search"
              onChange={row.onChange}
              value={row.value}
            />
          </Grid>
        );
      case 'select':
        return (
          <Grid item xs={4} className={classes.searchTextBox}>
            <Select row={row} />
          </Grid>
        );

      case 'checkbox':
        return (
          <Grid item xs={4} className={classes.searchTextBox} key={index}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              {row.label}
            </InputLabel>
            <Checkbox
              onChange={row.onChange}
              checked={row.value}
              color="primary"
            />
          </Grid>
        );

      case 'date_between':
        return (
          <Grid item xs={4} className={classes.searchTextBox} key={index}>
            <DateBetween
              value={row.value}
              onChangeFrom={row.onChangeFrom}
              onChangeTo={row.onChangeTo}
              label={row.label}
            />
          </Grid>
        );
      default:
        return <div />;
    }
  };

  return (
    <Grid
      container
      justify="flex-start"
      alignItems="flex-end"
      className={classes.searchBox}
    >
      {props.rows.map((row: any, index: number) => rowGrid(row, index))}
      {addGrid()}

      <Grid item xs={4} className={classes.searchSubmit}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SearchIcon />}
          onClick={props.handler}
        >
          検索
        </Button>
      </Grid>
    </Grid>
  );
};

export default TableSearch;
