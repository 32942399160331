import React from 'react';
import { BaseProps } from '../../types/componentType';
import useTitle from '../../hooks/generic/titleSet';
import CustomCreate from '../../components/organisms/page/CustomCreate';
import {
  CustomChildrenForm,
  CustomForm,
} from '../../components/organisms/page/CustomValidFormItem';
import useCustomForm from '../../hooks/generic/customForm';
import * as Yup from 'yup';
import { useMasterSuggestions } from '../../hooks/api/mastersHook';
import * as valids from 'constants/valids';
import StoreErrorDialog from '../../components/organisms/event/StoreErrorDialog';
import { useSuggestionsCreate } from '../../hooks/api/suggestionsHooks';

interface Props extends BaseProps {}

const SuggestionsCreate: React.FC<Props> = (props) => {
  useTitle(props.title);

  const mastersHooks = useMasterSuggestions();
  const createHooks = useSuggestionsCreate();
  const [{ isLoading, contents }] = mastersHooks;
  const [{ option }, { setOption: doCreate }] = createHooks;

  const formsChildren: CustomChildrenForm[] = [
    {
      name: 'type',
      label: '商流タイプ',
      type: 'select',
      selects: contents.sales_routes_type,
      defaultValue: '',
      valid: Yup.string()
        .required(valids.MSG_REQUIRES)
        .oneOf(
          (contents.sales_routes_type ?? []).map((obj: any) => obj.id),
          valids.MSG_IN,
        ),
    },
    {
      name: 'client_id',
      label: 'クライアント',
      type: 'select',
      selects: contents.clients,
      defaultValue: 0,
      valid: Yup.number()
        .required(valids.MSG_REQUIRES)
        .oneOf(
          (contents.clients ?? []).map((obj: any) => obj.id),
          valids.MSG_IN,
        ),
    },
    {
      name: 'order',
      label: '順番',
      type: 'order',
      defaultValue: 0,
    },
  ];

  const forms: CustomForm[][] = [
    [
      {
        name: 'user_in_charge_id',
        label: '営業担当者',
        type: 'select',
        selects: contents.users,
        defaultValue: 0,
        valid: Yup.number()
          .required(valids.MSG_REQUIRES)
          .oneOf(
            (contents.users ?? []).map((obj: any) => obj.id),
            valids.MSG_IN,
          ),
      },
      {
        name: 'next_action',
        label: '次回アクション',
        type: 'select',
        selects: contents.next_action,
        defaultValue: '',
        valid: Yup.string()
          .required(valids.MSG_REQUIRES)
          .oneOf(
            (contents.next_action ?? []).map((obj: any) => obj.id),
            valids.MSG_IN,
          ),
      },
      {
        name: 'loan_plan_id',
        label: '出向計画ID',
        type: 'hidden',
        defaultValue: parseInt(props.match.params.loan_plan_id),
      },
    ],
  ];

  const childrenForms: CustomForm[] = [
    {
      name: 'sales_routes',
      label: '商流',
      type: 'array',
      xs: 3,
      params: formsChildren,
    },
  ];

  const customForm = useCustomForm(forms, childrenForms, isLoading);
  const formHooks = customForm.formHooks;
  return (
    <>
      <StoreErrorDialog />
      <CustomCreate
        createHooks={createHooks}
        formHooks={formHooks}
        submitHandler={customForm.toSubmitHandler(doCreate, option)}
        forms={customForm.value}
        childrenForms={customForm.childrenValue}
        resource={props.resource}
        isLoading={isLoading}
      />
    </>
  );
};

export default SuggestionsCreate;
