import { Route, Switch } from 'react-router-dom';

import Sandbox from 'layouts/sandbox';
import SandboxFirst from 'pages/sandbox/first';
import SandboxSecond from 'pages/sandbox/second';

import SandboxHooksStore from 'pages/sandbox/hooksStore';

import NotFound from '../../pages/error/notfound';

export default function SandboxRouter() {
  return (
    <div>
      <Sandbox>
        <Switch>
          <Route exact path="/sandbox/first" component={SandboxFirst} />
          <Route exact path="/sandbox/second" component={SandboxSecond} />
          <Route
            exact
            path="/sandbox/hooksStore"
            component={SandboxHooksStore}
          />
          <Route component={NotFound} />
        </Switch>
      </Sandbox>
    </div>
  );
}
