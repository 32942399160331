import React from 'react';

import DetailTable from '../../molecules/detailTable/DetailTable';
import { ApiStates } from '../../../hooks/api/type/ApiStates';

interface Props {
  children?: React.ReactNode;
  id: number;
  resource: string;
  showHooks: ApiStates<any, any>;
}

const ClientTable: React.FC<Props> = (props) => {
  const [{ contents }] = props.showHooks;

  const columns = [
    [
      { id: 'id', label: 'ID', minWidth: 50 },
      { id: 'name', label: '案件名', minWidth: 100 },
      {
        id: 'summary',
        label: '案件概要',
        minWidth: 100,
        style: { textAlign: 'left' },
      },
    ],
    [
      {
        id: 'nearest_station',
        label: '最寄り駅',
        minWidth: 100,
        style: { textAlign: 'left' },
      },
      {
        id: 'skill_summary',
        label: 'スキル',
        minWidth: 100,
        style: { textAlign: 'left' },
      },
    ],
  ];

  return (
    <>
      {contents?.m_projects?.map((item: any, index: number) => (
        <DetailTable
          columns={columns}
          showHooks={props.showHooks}
          id={props.id}
          resource={props.resource}
          title={`案件情報: ${index + 1}`}
          rows={item}
        />
      ))}
    </>
  );
};

export default ClientTable;
