interface ApiIndexContents<data> {
  current_page: number;
  data: Array<data> | [];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: any;
  path: string;
  per_page: number;
  prev_page_url: string;
  to: number;
  total: number;
}

export const initContents = {
  current_page: 1,
  data: [],
  first_page_url: '',
  from: 0,
  last_page: 0,
  last_page_url: '',
  links: [],
  path: '',
  per_page: 0,
  prev_page_url: '',
  to: 0,
  total: 10,
};

export default ApiIndexContents;
