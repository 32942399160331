import { Route, Switch } from 'react-router-dom';

import Error from 'layouts/error';
import NotFound from 'pages/error/notfound';

export default function ErrorRouter() {
  return (
    <div>
      <Error>
        <Switch>
          <Route exact path="/error/notfound" component={NotFound} />
        </Switch>
      </Error>
    </div>
  );
}
