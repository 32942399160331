import {
  actionCreatorFactory,
  ActionCreatorFactory,
  ActionCreator,
} from 'typescript-fsa';
import { Color } from '@material-ui/lab/Alert';

export interface SnackBarOnPayload {
  severity: Color;
  message: string;
}

export interface Actions {
  snackbarOn: ActionCreator<SnackBarOnPayload>;
  snackbarOff: ActionCreator<void>;
}

const actionCreator: ActionCreatorFactory = actionCreatorFactory();

const actions: Actions = {
  snackbarOn: actionCreator('SNACK_BAR_ON'),
  snackbarOff: actionCreator('SNACK_BAR_OFF'),
};

export default actions;
