import React from 'react';
import { BaseProps } from '../../types/componentType';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useTitle from '../../hooks/generic/titleSet';
import { useSuggestionsEdit } from '../../hooks/api/suggestionsHooks';
import { useMasterSuggestions } from '../../hooks/api/mastersHook';
import * as valids from '../../constants/valids';
import SimpleCreate from '../../components/organisms/page/SimpleCreate';
import { CreateForm } from '../../components/organisms/page/ValidFormItem';

interface Props extends BaseProps {}

const SuggestionsCompleted: React.FC<Props> = (props) => {
  useTitle(props.title);

  const editHooks = useSuggestionsEdit();
  const mastersHooks = useMasterSuggestions();
  const [{ option }, { setOption: doEdit }] = editHooks;

  const [{ isLoading, contents }] = mastersHooks;

  const validSchema = Yup.object().shape({
    completion_reason: Yup.string()
      .required(valids.MSG_REQUIRES)
      .oneOf(
        (contents.completion_reason ?? []).map((obj: any) => obj.id),
        valids.MSG_IN,
      ),
    completion_memo: Yup.string().required(valids.MSG_REQUIRES),
    end_date: Yup.date().required(valids.MSG_REQUIRES),
  });

  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: {
      completion_reason: '',
      completion_memo: '',
      end_date: new Date(),
    },
    resolver: yupResolver(validSchema),
  });

  const submitHandler = (data: any) => {
    const changedParam = {
      ...option.json,
      completion_reason: data.completion_reason,
      completion_memo: data.completion_memo,
      end_date: data.end_date,
    };

    doEdit({ ...option, json: changedParam, id: props.match.params.id });
  };

  const forms: CreateForm[] = [
    {
      name: 'completion_reason',
      label: '完了理由',
      type: 'select',
      selects: contents.completion_reason,
    },
    { name: 'completion_memo', label: '完了理由詳細', type: 'textarea' },
    { name: 'end_date', label: '完了日', type: 'date' },
  ];

  return (
    <>
      <SimpleCreate
        createHooks={editHooks}
        formHooks={formHooks}
        submitHandler={submitHandler}
        forms={forms}
        resource={props.resource}
        isLoading={isLoading}
      />
    </>
  );
};

export default SuggestionsCompleted;
