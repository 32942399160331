import React, { useEffect, useState } from 'react';

import useTitle from '../../hooks/generic/titleSet';
import { BaseProps } from '../../types/componentType';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import StaffSalesSummaryTable from '../../components/organisms/staffs/StaffSalesSummaryTable';
import StaffAbilityTable from '../../components/organisms/staffs/StaffAbilityTable';
import MonthlyLoanHistoriesTable from '../../components/organisms/staffs/MonthlyLoanHistoriesTable';
import LinkButton from '../../components/molecules/common/LinkButton';
import Grid from '@material-ui/core/Grid';
import LoanDefaultInfoTable from '../../components/organisms/loans/LoanDefaultInfoTable';
import { useLoansShow } from '../../hooks/api/loansHooks';
import { useStaffsShow } from '../../hooks/api/staffsHooks';
import CircularProgress from '../../components/molecules/common/CircularProgress';

interface Props extends BaseProps {}

const useStyles = makeStyles(
  createStyles({
    box: {
      padding: '24px',
    },
    linkButton: {
      marginRight: '12px',
    },
    gridRecord: {
      marginTop: '24px',
      marginBottom: '24px',
      paddingRight: '24px',
      textAlign: 'right',
      width: '100%',
    },
    table: {
      marginTop: '24px',
      marginBottom: '24px',
    },
  }),
);

const LoanDetail: React.FC<Props> = (props) => {
  useTitle(props.title);

  const classes = useStyles();

  const loanShowHooks = useLoansShow();
  const staffShowHooks = useStaffsShow();
  const userShowByLoanHistoriesHooks = useStaffsShow();

  const [{ contents }, { setOption: lDoFetch }] = loanShowHooks;

  const sDoFetch = staffShowHooks[1].setOption;
  const slDoFetch = userShowByLoanHistoriesHooks[1].setOption;

  const [buttonLoading, setButtonLoading] = useState(true);

  useEffect(() => {
    lDoFetch({ id: props.match.params.id });
  }, [props.match.params.id, lDoFetch]);

  useEffect(() => {
    if (isNaN(contents?.staff?.id)) return;
    sDoFetch({ id: contents?.staff?.id });
  }, [contents?.staff?.id, sDoFetch]);

  useEffect(() => {
    if (isNaN(contents?.staff?.id)) return;
    slDoFetch({
      id: contents?.staff?.id,
      searchParams: { monthly_loan_histories_year: new Date().getFullYear() },
    });
  }, [contents.id, slDoFetch]);

  useEffect(() => {
    if (parseInt(props.match.params.id) > 0) {
      setButtonLoading(false);
    }
  }, [props.match.params.id]);

  return (
    <>
      <Card>
        <Grid container className={classes.gridRecord}>
          <Grid item xs={12}>
            <CircularProgress isLoading={buttonLoading}>
              <LinkButton
                to={`/${props.resource}/${props.match.params.id}/updateContract`}
                type={'warning'}
              >
                契約更新
              </LinkButton>

              {!contents.end_date ? (
                <LinkButton
                  to={`/${props.resource}/${props.match.params.id}/completed`}
                  type={'warning'}
                >
                  出向終了
                </LinkButton>
              ) : (
                <></>
              )}

              <LinkButton
                to={`/${props.resource}/${props.match.params.id}/edit`}
                type={'warning'}
              >
                編集
              </LinkButton>
            </CircularProgress>
          </Grid>
        </Grid>

        <Box className={classes.box}>
          <div className={classes.table}>
            <LoanDefaultInfoTable
              id={props.match.params.id}
              resource={props.resource}
              showHooks={loanShowHooks}
            />
          </div>
          <div className={classes.table}>
            <StaffSalesSummaryTable
              id={props.match.params.id}
              resource={props.resource}
              showHooks={staffShowHooks}
            />
            <StaffAbilityTable
              id={props.match.params.id}
              resource={props.resource}
              showHooks={staffShowHooks}
            />
          </div>
          <div className={classes.table}>
            <MonthlyLoanHistoriesTable
              id={contents?.staff?.id}
              resource={props.resource}
              showHooks={userShowByLoanHistoriesHooks}
            />
          </div>
        </Box>
      </Card>
    </>
  );
};

export default LoanDetail;
