import {
  reducerWithInitialState,
  ReducerBuilder,
} from 'typescript-fsa-reducers';
import Actions, {
  ErrorDialogOnPayload,
} from 'stores/unPersist/errorDialog/actions';

export interface State {
  isOpen: boolean;
  errors: any;
}

export const initialState: State = {
  isOpen: false,
  errors: '',
};

export const reducer: ReducerBuilder<State> = reducerWithInitialState(
  initialState,
)
  .case(
    Actions.errorDialogOn,
    //@ts-ignore 'state' is declared but its value is never read.
    (state: State, payload: ErrorDialogOnPayload) => {
      return {
        isOpen: true,
        errors: payload.errors,
      };
    },
  )
  .case(Actions.errorDialogOff, () => {
    return initialState;
  });
