import React from 'react';

import SimpleIndex from '../../components/organisms/page/SimpleIndex';
import { useInput } from '../../hooks/generic/inputHook';
import useTitle from '../../hooks/generic/titleSet';
import { BaseProps } from '../../types/componentType';
import { useMasterSuggestions } from '../../hooks/api/mastersHook';
import {
  useSuggestionsDelete,
  useSuggestionsIndex,
} from '../../hooks/api/suggestionsHooks';
import { apiDateFormat } from '../../utils/format';

interface Props extends BaseProps {}

const MMilestonesIndex: React.FC<Props> = (props) => {
  useTitle(props.title);

  const indexHooks = useSuggestionsIndex();
  const deleteHooks = useSuggestionsDelete();
  const mastersHooks = useMasterSuggestions();

  const [{ isLoading, contents }] = mastersHooks;

  const [{ option }, { setOption }] = indexHooks;
  /* eslint-disable no-empty-pattern */
  const [{}, { setOption: doDelete }] = deleteHooks;

  console.log(contents);
  const columns = [
    { id: 'id', label: 'ID', minWidth: 20 },
    { id: 'last_client.name', label: '上位', minWidth: 170 },
    { id: 'end_client.name', label: 'エンド', minWidth: 170 },
    { id: 'staff.name', label: 'スタッフ', minWidth: 100 },
    { id: 'user_in_charge.name', label: '担当者', minWidth: 100 },
    { id: 'loan_plan.start_date', label: '営業開始日', minWidth: 120 },
    {
      id: 'next_action',
      label: '次回アクション',
      minWidth: 130,
      option: { conversion: contents.next_action },
    },
    {
      id: 'isInterviewPractice',
      label: '面談練習',
      minWidth: 100,
      isBool: true,
    },
    {
      id: 'completion_reason',
      label: '完了理由',
      minWidth: 100,
      option: { conversion: contents.completion_reason },
    },
    // { id: 'm_projects.name', label: '案件名 / 最寄り駅', minWidth: 100 },
    { id: 'end_date', label: '終了日', minWidth: 120 },
    // { id: 'created_at', label: '作成日', minWidth: 100 },
    { id: 'detail_button', label: '詳細', minWidth: 50 },
  ];

  const lastClientId = useInput('', 'select', '上位', {
    selects: contents.last_clients ?? [],
    isMultiple: false,
  });

  const endClientId = useInput('', 'select', 'エンド', {
    selects: contents.end_clients ?? [],
    isMultiple: false,
  });

  const staffId = useInput('', 'select', 'スタッフ', {
    selects: contents.staffs ?? [],
    isMultiple: false,
  });

  const userId = useInput('', 'select', '担当者', {
    selects: contents.users ?? [],
    isMultiple: false,
  });

  const startDate = useInput(
    { from: null, to: null },
    'date_between',
    '営業開始日',
  );

  const nextAction = useInput('', 'select', '次回アクション', {
    selects: contents.next_action ?? [],
    isMultiple: false,
  });

  const completionReason = useInput('', 'select', '完了理由', {
    selects: contents.completion_reason ?? [],
    isMultiple: false,
  });

  const wishDone = useInput(false, 'checkbox', '完了済みも表示');
  const handleSearchSubmit = () => {
    const changedSearchParam = {
      ...option.searchParams,
      //上位
      sales_route_last_client_id_eq: lastClientId.value,
      //エンド
      sales_route_end_client_id_eq: endClientId.value,
      staff_id_eq: staffId.value,
      user_in_charge_id_eq: userId.value,
      loan_plan_start_date_gt: apiDateFormat(startDate.value.from),
      loan_plan_start_date_lt: apiDateFormat(startDate.value.to),
      next_action_in: nextAction.value,
      completion_reason_in: completionReason.value,
      wish_done: wishDone.value,
    };

    setOption({ ...option, searchParams: changedSearchParam });
  };

  const handleDeleteClick = (id: number) => {
    return () => {
      if (window.confirm('削除してもよろしいですか？')) {
        doDelete({ id: id });
      }
    };
  };

  return (
    <>
      <SimpleIndex
        indexHooks={indexHooks}
        deleteHooks={deleteHooks}
        columns={columns}
        tableSearchStates={[
          lastClientId,
          endClientId,
          staffId,
          userId,
          startDate,
          nextAction,
          completionReason,
          wishDone,
        ]}
        handleSearchSubmit={handleSearchSubmit}
        handleDeleteClick={handleDeleteClick}
        resource={props.resource}
        isLoading={isLoading}
      />
    </>
  );
};

export default MMilestonesIndex;
