import { Theme } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';

//TODO: https://qiita.com/tag1216/items/2935c9979b857bb7701f

export const theme: Theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Noto Sans JP',
      'Lato',
      '游ゴシック Medium',
      '游ゴシック体',
      'Yu Gothic Medium',
      'YuGothic',
      'ヒラギノ角ゴ ProN',
      'Hiragino Kaku Gothic ProN',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'MS PGothic',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: { main: colors.blue[800] }, // テーマの色
    secondary: { main: colors.blue[800] }, // テーマの色
    // https://mui.com/customization/palette/#adding-new-colors
    warning: { main: '#ffa726', light: '#ffb74d', dark: '#f57c00' },
    error: { main: '#f44336', light: '#e57373', dark: '#d32f2f' },
    // border: { main: 'rgba(224, 224, 224, 1)', light: 'rgba(224, 224, 224, 1)', dark: 'rgba(224, 224, 224, 1)' },
  },
  mixins: {
    toolbar: {
      minHeight: 56,
    },
    // index.tsで定義してる。Mixinsのオーバーライドをいじることで拡張可能
    detailTable: {
      main: {
        border: '1px solid',
        borderColor: 'rgba(224, 224, 224, 1)',
        borderCollapse: 'collapse',
        width: '100%',
      },
      header: {
        border: '1px solid',
        borderColor: 'rgba(224, 224, 224, 1)',
        backgroundColor: '#FAFAFA',
        padding: '8px',
        width: '40%',
      },
      body: {
        border: '1px solid',
        borderColor: 'rgba(224, 224, 224, 1)',
        padding: '8px',
        width: '60%',
      },
      title: {
        border: '1px solid',
        borderColor: 'rgba(224, 224, 224, 1)',
        backgroundColor: '#FAFAFA',
        padding: '8px',
        width: '100%',
        fontWeight: 700,
        fontSize: '18px',
      },
    },
    formBorder: {
      main: {
        border: '1px solid',
        borderColor: 'rgba(224, 224, 224, 1)',
      },
    },
  },
});
