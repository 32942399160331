import { Control } from 'react-hook-form/dist/types';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CustomFormByType from '../../molecules/form/CustomFormByType';
import { CustomForm } from './CustomValidFormItem';
import { theme } from '../../../assets/styles/ts/theme';

export interface Props {
  control: Control<any, any>;
  register: any;
  setValue: any;
  errors: any;
  rows: CustomForm[];
}

const CustomValidFormChildrenItem: React.FC<Props> = (props) => {
  const classes = makeStyles({
    gridRecord: {
      margin: '12px',
      textAlign: 'right',
      ...theme.mixins.detailTable.main,
    },
  })();
  return (
    <>
      {props.rows?.map((row: any, targetIndex: number) => (
        <Grid container className={classes.gridRecord}>
          <React.Fragment key={targetIndex}>
            <CustomFormByType
              control={props.control}
              register={props.register}
              setValue={props.setValue}
              errors={props.errors}
              rows={row}
            />
          </React.Fragment>
        </Grid>
      ))}
    </>
  );
};

export default CustomValidFormChildrenItem;
