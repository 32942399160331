import { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { PersistStoreContext } from 'stores/persist/store';
import actions from 'stores/persist/masters/actions';

export default function Redux() {
  const { state, dispatch } = useContext(PersistStoreContext);

  const handleInputValue = (value: string) => {
    dispatch(actions.updateTextInputValue(value));
    console.log(state);
    // console.log(value);
  };

  const buttonStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
    },
  })();

  const dispatch2 = (): void => {
    handleInputValue('おろか');
    console.log('store');
  };

  return (
    <div>
      サンドボックス hooksStore ページ
      <br />
      <Button className={buttonStyles.root} onClick={dispatch2}>
        ストア！
      </Button>
      <br />
      users: {state.users.user}
      masters: {state.masters.inputValue}
    </div>
  );
}
