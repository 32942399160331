import React from 'react';
import TextField from '@material-ui/core/TextField';
import { DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Control } from 'react-hook-form/dist/types/form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { Controller } from 'react-hook-form';
import { getDescendant } from '../../../utils/format';

interface Props {
  // children?: React.ReactNode;
  // value: Date | null;
  // onChange: any;
  children?: React.ReactNode;
  control: Control<any, any>;
  errors: FieldErrors<any>;
  name: string;
  label?: string;
  placeholder?: string;
}

const useStyles = makeStyles(
  createStyles({
    dateBox: {
      width: '100%',
      marginTop: '12px',
      marginBottom: '12px',
    },
  }),
);

const DateTimeInput: React.FC<Props> = (props) => {
  const classes = useStyles();
  const error = getDescendant(props.errors, props.name);
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={DateFnsUtils}>
          <DateTimePicker
            label={props.label}
            value={field.value}
            inputFormat="yyyy-MM-dd HH:mm"
            onChange={(e: any) => {
              field.onChange(e);
            }}
            renderInput={(renderProps) => (
              <TextField
                className={classes.dateBox}
                helperText={error?.message}
                error={error}
                {...{ ...renderProps }}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default DateTimeInput;
