import { ApiStates } from './type/ApiStates';
import ApiIndexContents from './type/ApiContents';
import {
  useApiCreateBase,
  useApiDeleteBase,
  useApiEditBase,
  useApiIndexBase,
  useApiShowBase,
} from './generic/apiBaseHooks';
import { ApiIndexOptions } from './type/ApiIndex';
import { ApiShowOptions } from './type/ApiShow';

const resource = 'interviews';
// 一覧
export const useInterviewsIndex = (): ApiStates<
  ApiIndexContents<any>,
  ApiIndexOptions
> => {
  return useApiIndexBase(resource);
};

// 詳細
export const useInterviewsShow = (): ApiStates<any, ApiShowOptions> => {
  return useApiShowBase(resource);
};

// 作成
export const useInterviewsCreate = (): ApiStates<any, any> => {
  return useApiCreateBase(resource);
};

// 更新
export const useInterviewsEdit = (): ApiStates<any, any> => {
  return useApiEditBase(resource);
};

// 削除
export const useInterviewsDelete = (): ApiStates<any, any> => {
  return useApiDeleteBase(resource);
};
