export const REG_KANA = /^[\u3040-\u309F]+$/;
export const MSG_KANA = 'かなで入力してください';

export const REG_TEL = /^0\d{1,2}-\d{1,4}-\d{4}$/;
export const MSG_TEL = '電話番号は次のように入力してください。000-0000-0000';

export const MSG_EMAIL = '正しいメールの形式で入力してください';
export const MSG_REQUIRES = '必須項目です';

export const MSG_IN = 'いずれかを選択してください';
