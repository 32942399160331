import React from 'react';

import DetailTable from '../../molecules/detailTable/DetailTable';
import { ApiStates } from '../../../hooks/api/type/ApiStates';
import { useMasterStaffs } from '../../../hooks/api/mastersHook';

interface Props {
  children?: React.ReactNode;
  id: number;
  resource: string;
  showHooks: ApiStates<any, any>;
}

const UserDefaultInfoTable: React.FC<Props> = (props) => {
  const mastersHooks = useMasterStaffs();

  const [{ contents }] = mastersHooks;
  const columns = [
    [
      { id: 'kana_name', label: 'スタッフ名（ふりがな）' },
      { id: 'name', label: 'スタッフ名' },
      {
        id: 'age',
        label: '年齢',
        option: {
          unit: {
            last: 'ヶ月',
          },
        },
      },
      { id: 'client.name', label: '所属' },
      {
        id: 'contract',
        label: '契約',
        option: { conversion: contents.contract },
      },
      {
        id: 'status',
        label: 'ステータス',
        minWidth: 100,
        option: { conversion: contents.status },
      },
      { id: 'mail', label: 'メールアドレス' },
      { id: 'phone_number', label: '電話番号' },
    ],
    [
      { id: 'address', label: '住所' },
      { id: 'birthday', label: '生年月日' },
      { id: 'nearest_station', label: '最寄り駅' },
      { id: 'hire_date', label: '入社日' },
      { id: 'retirement_date', label: '退職日' },
      { id: 'created_at', label: '作成日' },
      { id: 'updated_at', label: '更新日' },
    ],
  ];

  return (
    <>
      <DetailTable
        columns={columns}
        showHooks={props.showHooks}
        id={props.id}
        resource={props.resource}
        title={'スタッフ基本情報'}
      />
    </>
  );
};

export default UserDefaultInfoTable;
