import React, {
  useReducer,
  useContext,
  createContext,
  Dispatch,
  useEffect,
} from 'react';
import { reducer, initialState, State } from 'stores/persist/reducers';
import { Action } from 'typescript-fsa';
import * as localStorageKey from 'constants/localStorageKey';

interface PersistStoreContextProps {
  state: State;
  dispatch: Dispatch<Action<any>>; //FIXME: いつかユニオンにしたい<string or number or boolean or hogeType>みたいなの作る
}

interface PersistStoreProviderProps {
  children: JSX.Element | JSX.Element[];
}

export const PersistStoreProvider: React.FC<PersistStoreProviderProps> = (
  props: PersistStoreProviderProps,
): JSX.Element => {
  const { children } = props;

  // 永続化しない場合は
  // const [state, dispatch] = useReducer(reducer, initialState);
  // にして、useEffectもなくす。
  const [state, dispatch] = useReducer(
    reducer,
    (() => {
      if (typeof window === 'undefined') {
        return initialState;
      }
      try {
        const item = window.localStorage.getItem(localStorageKey.STORE);
        // console.log({item:item});
        return item ? JSON.parse(item) : initialState;
      } catch (error) {
        // If error also return initialValue
        console.log(error);
        return initialState;
      }
    })(),
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(localStorageKey.STORE, JSON.stringify(state));
    }
  }, [state]);

  return (
    <PersistStoreContext.Provider value={{ state, dispatch }}>
      {children}
    </PersistStoreContext.Provider>
  );
};

export const useAppContext = (): React.ContextType<
  typeof PersistStoreContext
> => useContext(PersistStoreContext);

export const PersistStoreContext = createContext(
  {} as PersistStoreContextProps,
);
