import React, { useContext, useEffect, useState } from 'react';
import baseKy from '../../../kys';
import { initContents } from '../type/ApiContents';
import { PersistStoreContext } from '../../../stores/persist/store';
import { useHistory } from 'react-router-dom';
import userActions from '../../../stores/persist/users/actions';

type ApiHandling = {
  setResponse: React.Dispatch<React.SetStateAction<{}>>;
  setContents: React.Dispatch<React.SetStateAction<any>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useApiBase = (
  option: any,
  setOption: any,
  func: any,
  check?: any,
  initContent?: any,
): any => {
  const [response, setResponse] = useState({});
  const [contents, setContents] = useState(initContent ?? {});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if ((check ?? (() => true))() === false) return;
    apiHandling({ setResponse, setContents, setIsLoading, setIsError }, func);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option, setOption]);

  const history = useHistory();
  const { dispatch: persistDispatch } = useContext(PersistStoreContext);

  useEffect(() => {
    const status = (response as any).status ?? 0;
    if (status === 401) {
      persistDispatch(userActions.userSignout(null));
      history.push('/login');
    }
  }, [response]);

  return [
    { response, contents, option, isLoading, isError },
    { setResponse, setContents, setOption, setIsLoading, setIsError },
  ];
};

export const apiHandling = async (
  { setResponse, setContents, setIsLoading, setIsError }: ApiHandling,
  callback: any,
) => {
  setIsError(false);
  setIsLoading(true);

  try {
    const response: Response = await callback();
    setResponse(response as any);
    setContents((await response.json()) as any);
  } catch (error) {
    const response: Response = await error.response;
    setResponse(response as any);
    setContents((await response.json()) as any);
    setIsError(true);
  }
  setIsLoading(false);
};

export const useApiIndexBase = (url: string): any => {
  const [option, setOption] = useState({
    searchParams: { limit: 20, page: 1 },
  });

  const { state } = useContext(PersistStoreContext);

  const doApi = async () => {
    return new baseKy(state.users?.token).client.get(url, option);
  };

  return useApiBase(option, setOption, doApi, undefined, initContents);
};

export const useApiShowBase = (url: string): any => {
  const [option, setOption] = useState({ id: 0 });

  const check = () => {
    return option.id !== 0;
  };

  const { state } = useContext(PersistStoreContext);

  const doApi = async () => {
    return new baseKy(state.users?.token).client.get(`${url}/${option.id}`);
  };

  return useApiBase(option, setOption, doApi, check, {});
};

export const useApiCreateBase = (url: string): any => {
  const [option, setOption] = useState({
    json: {},
  });

  const check = () => {
    const length = Object.keys(option.json).length;
    return length !== 0;
  };
  const { state } = useContext(PersistStoreContext);
  const doApi = async () => {
    return new baseKy(state.users?.token).client.post(url, option);
  };

  return useApiBase(option, setOption, doApi, check);
};

export const useApiEditBase = (url: string): any => {
  const [option, setOption] = useState({ json: {}, id: 0 });

  const check = () => {
    const length = Object.keys(option.json).length;
    return !(option.id === 0 || length === 0);
  };
  const { state } = useContext(PersistStoreContext);
  const doApi = async () => {
    return new baseKy(state.users?.token).client.patch(
      `${url}/${option.id}`,
      option,
    );
  };

  return useApiBase(option, setOption, doApi, check);
};

export const useApiDeleteBase = (url: string): any => {
  const [option, setOption] = useState({ id: 0 });

  const check = () => option.id !== 0;
  const { state } = useContext(PersistStoreContext);
  const doApi = async () => {
    return new baseKy(state.users?.token).client.delete(`${url}/${option.id}`);
  };

  return useApiBase(option, setOption, doApi, check);
};

export const useApiMasterBase = (url: string, isAuto: boolean = true): any => {
  const [option, setOption] = useState({});
  const { state } = useContext(PersistStoreContext);
  const doApi = async () => {
    return new baseKy(state.users?.token).client.get(url, option);
  };
  const check = isAuto ? undefined : () => !!Object.keys(option).length;

  return useApiBase(option, setOption, doApi, check, []);
};
