import React, { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { CustomChildrenForm } from '../../organisms/page/CustomValidFormItem';
import CustomFormByType from './CustomFormByType';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

interface Props {
  children?: React.ReactNode;
  control: Control<any, any>;
  register: any;
  setValue: any;
  errors: any;
  initAppendValue?: any[];
  rows?: CustomChildrenForm[];
  name: string;
  label?: string;
  xs?: any;
  isStatic?: boolean;
}

const ArrayForm: React.FC<Props> = (props) => {
  const classes = makeStyles({
    card: {
      padding: '12px',
      marginTop: '12px',
      marginBottom: '12px',
    },
    gridItem: {
      paddingLeft: '12px',
      paddingRight: '12px',
    },
    button: {
      paddingLeft: '12px',
      paddingRight: '12px',
      height: '100%',
      width: '100%',
    },
    text: {
      textAlign: 'center',
    },
  })();

  const control = props.control;

  const { fields, append, remove } = useFieldArray({
    control,
    name: props.name,
    keyName: 'key', // デフォルトではidだが、keyに変更。
  } as any);

  const appendValue = props.rows?.reduce(
    (prev: any, current: CustomChildrenForm) => {
      prev[current.name] = current.defaultValue;
      return prev;
    },
    {},
  );

  // FIXME: どうにかしてHOOKに持っていきたい
  useEffect(() => {
    props.initAppendValue?.map((row: any) => {
      append(row);
    });
  }, [props.initAppendValue]);

  const addInputForm = () => {
    append(appendValue);
  };
  const removeForm = (index: number) => {
    remove(index);
  };
  const customFormByType = (index: number, name: string) => {
    return props.rows?.map((form: CustomChildrenForm, i: number) => {
      return (
        <CustomFormByType
          control={props.control}
          register={props.register}
          setValue={props.setValue}
          errors={props.errors}
          rows={form}
          childrenIndex={index}
          childrenName={name}
          key={i}
        />
      );
    });
  };

  return (
    <>
      {fields.map((field: any, index: number) => (
        <Grid item xs={props.xs} className={classes.gridItem} key={field.key}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container>
                <Grid item xs={10}>
                  <Typography variant="h5">
                    {`${props.label} ${index + 1}`}
                  </Typography>
                </Grid>
                {!props.isStatic ? (
                  <Grid item xs={2}>
                    <RemoveCircleOutlineIcon
                      onClick={() => removeForm(index)}
                    />
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
              {customFormByType(index, props.name)}
            </CardContent>
          </Card>
        </Grid>
      ))}
      {!props.isStatic ? (
        <Grid item xs={props.xs} className={classes.gridItem}>
          <Card
            className={`${classes.card} ${classes.text}`}
            onClick={addInputForm}
          >
            {`${props.label}を追加する`}
          </Card>
        </Grid>
      ) : (
        ''
      )}
    </>
  );
};

export default ArrayForm;
