import React from 'react';

import TableBody, { SimpleTableColumn } from '../page/TableBody';
import { CircularProgress } from '@material-ui/core';
import { ApiStates } from '../../../hooks/api/type/ApiStates';

interface Props {
  children?: React.ReactNode;
  id: number;
  resource: string;
  showHooks: ApiStates<any, any>;
}

const LoanHistoryTable: React.FC<Props> = (props) => {
  const [
    { contents, option, isLoading },
    { setOption: sDoFetch },
  ] = props.showHooks;

  const columns: SimpleTableColumn[] = [
    { id: 'id', label: 'ID', minWidth: 100 },
    { id: 'last_client.name', label: '上位', minWidth: 100 },
    { id: 'end_client.name', label: 'エンド', minWidth: 100 },
    { id: 'start_date', label: '出向開始日', minWidth: 100 },
    { id: 'end_date', label: '出向終了日', minWidth: 100 },
    {
      id: 'contract_period',
      label: '出向期間',
      minWidth: 100,
      option: {
        unit: {
          last: 'ヶ月',
        },
      },
    },
  ];

  const rows = contents?.loans ?? [];

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <TableBody
          rows={rows}
          columns={columns}
          option={option}
          setOption={sDoFetch}
          contents={contents}
          resource={props.resource}
          title={'出向履歴'}
        />
      )}
    </>
  );
};

export default LoanHistoryTable;
