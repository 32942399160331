import React, { useEffect } from 'react';
import { BaseProps } from '../../types/componentType';
import useTitle from '../../hooks/generic/titleSet';
import {
  CustomChildrenForm,
  CustomForm,
} from '../../components/organisms/page/CustomValidFormItem';
import * as Yup from 'yup';
import { useMasterSuggestions } from '../../hooks/api/mastersHook';
import * as valids from 'constants/valids';
import StoreErrorDialog from '../../components/organisms/event/StoreErrorDialog';
import CustomEdit from '../../components/organisms/page/CustomEdit';
import useCustomForm from '../../hooks/generic/customForm';
import {
  useSuggestionsEdit,
  useSuggestionsShow,
} from '../../hooks/api/suggestionsHooks';
import { getDescendant } from '../../utils/format';

interface Props extends BaseProps {}

const SuggestionsEdit: React.FC<Props> = (props) => {
  useTitle(props.title);

  const mastersHooks = useMasterSuggestions(false);
  const showHooks = useSuggestionsShow();
  const editHooks = useSuggestionsEdit();

  const [{ isLoading, contents }, { setOption: doMaster }] = mastersHooks;
  const [
    { isLoading: sIsLoading, contents: sContents },
    { setOption: sDoFetch },
  ] = showHooks;
  const [{ option }, { setOption: doSubmit }] = editHooks;

  const forms: CustomForm[][] = [
    [
      {
        name: 'user_in_charge_id',
        label: '営業担当者',
        type: 'select',
        selects: contents.users,
        defaultValue: 0,
        descendant: 'user_in_charge_id',
        valid: Yup.number()
          .required(valids.MSG_REQUIRES)
          .oneOf(
            (contents.users ?? []).map((obj: any) => obj.id),
            valids.MSG_IN,
          ),
      },
      {
        name: 'next_action',
        label: '次回アクション',
        type: 'select',
        selects: contents.next_action,
        defaultValue: '',
        descendant: 'next_action',
        valid: Yup.string()
          .required(valids.MSG_REQUIRES)
          .oneOf(
            (contents.next_action ?? []).map((obj: any) => obj.id),
            valids.MSG_IN,
          ),
      },
      // {
      //   name: 'loan_plan_id',
      //   label: '出向計画ID',
      //   type: 'hidden',
      //   descendant: 'loan_plan_id',
      //   defaultValue: parseInt(props.match.params.loan_plan_id),
      // },
    ],
  ];

  const salesRoutesFormsChildren: CustomChildrenForm[] = [
    {
      name: 'type',
      label: '商流タイプ',
      type: 'select',
      selects: contents.sales_routes_type,
      defaultValue: '',
      descendant: 'type',
      valid: Yup.string()
        .required(valids.MSG_REQUIRES)
        .oneOf(
          (contents.sales_routes_type ?? []).map((obj: any) => obj.id),
          valids.MSG_IN,
        ),
    },
    {
      name: 'client_id',
      label: 'クライアント',
      type: 'select',
      selects: contents.clients,
      defaultValue: 0,
      descendant: 'client_id',
      onChange: (e: any, setValue: any) => {
        console.log(e.target);
        if (e.target.name === 'sales_routes.0.client_id') {
          doMaster({ searchParams: { client_id: e.target.value } });
          setValue('suggestion_projects', []);
        }
      },
      valid: Yup.number()
        .required(valids.MSG_REQUIRES)
        .oneOf(
          (contents.clients ?? []).map((obj: any) => obj.id),
          valids.MSG_IN,
        ),
    },
    {
      name: 'order',
      label: '順番',
      type: 'order',
      descendant: 'order',
      defaultValue: 0,
    },
  ];

  const suggestionProjectsFormsChildren: CustomChildrenForm[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'hidden',
      defaultValue: null,
      descendant: 'id',
    },
    {
      name: 'm_project_id',
      label: '提案案件',
      type: 'select',
      selects: contents.m_project,
      defaultValue: 0,
      descendant: 'm_project_id',
      valid: Yup.number()
        .required(valids.MSG_REQUIRES)
        .oneOf(
          (contents.m_project ?? []).map((obj: any) => obj.id),
          valids.MSG_IN,
        ),
    },
  ];

  const suggestionChecksheetsChildren: CustomChildrenForm[] = [
    {
      name: 'type',
      label: 'タイプ',
      type: 'select',
      selects: contents.suggestion_checksheets_type,
      defaultValue: 0,
      descendant: 'type',
      disabled: true,
      valid: Yup.string()
        .required(valids.MSG_REQUIRES)
        .oneOf(
          (contents.suggestion_checksheets_type ?? []).map(
            (obj: any) => obj.id,
          ),
          valids.MSG_IN,
        ),
    },
    {
      name: 'checked',
      label: 'チェック',
      type: 'single_checkbox',
      defaultValue: 0,
      descendant: 'checked',
      valid: Yup.boolean().required(valids.MSG_REQUIRES),
    },
  ];

  const childrenForms: CustomForm[] = [
    {
      name: 'sales_routes',
      label: '商流',
      type: 'array',
      xs: 3,
      descendant: 'sales_routes',
      params: salesRoutesFormsChildren,
    },
    {
      name: 'suggestion_projects',
      label: '提案案件',
      type: 'array',
      xs: 3,
      descendant: 'suggestion_projects',
      params: suggestionProjectsFormsChildren,
    },
    {
      name: 'suggestion_checksheets',
      label: 'チェックボックス',
      type: 'array',
      xs: 3,
      isStatic: true,
      descendant: 'suggestion_checksheets',
      params: suggestionChecksheetsChildren,
    },
  ];

  // 初期ロード時、のみ実行をする。
  useEffect(() => {
    sDoFetch({ id: props.match.params.id });
  }, [props.match.params.id, sDoFetch]);

  const customForm = useCustomForm(
    forms,
    childrenForms,
    isLoading || sIsLoading,
    sContents,
  );
  const formHooks = customForm.formHooks;

  // 初期ロード時、のみ実行をする。
  useEffect(() => {
    doMaster({
      searchParams: {
        client_id: getDescendant(sContents, 'sales_routes.0.client_id'),
      },
    });
  }, [sContents]);

  return (
    <>
      <StoreErrorDialog />
      <CustomEdit
        id={props.match.params.id}
        createHooks={editHooks}
        formHooks={formHooks}
        submitHandler={customForm.toSubmitHandler(
          doSubmit,
          option,
          props.match.params.id,
        )}
        forms={customForm.value}
        childrenForms={customForm.childrenValue}
        resource={props.resource}
        isLoading={isLoading || sIsLoading}
      />
    </>
  );
};

export default SuggestionsEdit;
