import React from 'react';
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import {
  Link,
  ListItemIcon,
  MenuList,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { theme } from 'assets/styles/ts/theme';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@material-ui/core';
import * as genericTemplateConstants from 'constants/genericTemplateConstants';

const useStyles = makeStyles(
  createStyles({
    link: {
      textDecoration: 'none',
      color: theme.palette.text.secondary,
    },
    menuList: {
      padding: 0,
      width: '100%',
    },
    listItemIcon: {
      minWidth: 36,
    },
    menuItem: {},
    menuItemActive: {
      backgroundColor: 'rgba(0, 0, 0, .10)',
    },
  }),
);

const Accordion = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',

    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: 0,
    overflow: 'hidden',
  },
}))(MuiAccordionDetails);

const SidebarList: React.FC = () => {
  const [menuList, setMenuList] = React.useState(
    genericTemplateConstants.INIT_MENU_LIST,
  );

  //   const handleChange = (index: number) => (event: React.ChangeEvent<{}>, newMenuList: boolean) => {
  const handleChange = (index: number) => () => {
    let newMenuList = menuList.concat();
    newMenuList[index].expanded = !newMenuList[index].expanded;
    setMenuList(newMenuList);
  };

  const classes = useStyles();
  const location = useLocation();

  return (
    <div>
      {menuList.map((data, index) => {
        return (
          <Accordion
            square
            expanded={data.expanded}
            onChange={handleChange(index)}
            key={index}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>{data.text}</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <MenuList className={classes.menuList}>
                {data.children.map((clild, clildIndex) => {
                  return (
                    <Link
                      key={clildIndex}
                      href={clild.href}
                      className={classes.link}
                    >
                      <MenuItem
                        className={
                          location.pathname === clild.href
                            ? classes.menuItemActive
                            : classes.menuItem
                        }
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <clild.component fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit">{clild.text}</Typography>
                      </MenuItem>
                    </Link>
                  );
                })}
              </MenuList>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default SidebarList;
