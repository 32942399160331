import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import { Control } from 'react-hook-form/dist/types/form';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface Props {
  children?: React.ReactNode;
  control: Control<any, any>;
  name: string;
  index?: number;
  setValue: any;
}

const useStyles = makeStyles(
  createStyles({
    hidden: {
      display: 'none',
    },
  }),
);

const Order: React.FC<Props> = (props) => {
  const classes = useStyles();

  props.setValue(props.name, (props.index ?? 0) + 1);

  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={{ required: true }}
      render={({ field }) => (
        <TextField
          className={classes.hidden}
          value={field.value}
          type="number"
        />
      )}
    />
  );
};

export default Order;
