import { Reducer } from 'react';
import combineReducers from 'react-combine-reducers';

import { Action } from 'typescript-fsa';

import {
  reducer as usersReducer,
  initialState as usersInitialState,
  State as UsersState,
} from 'stores/persist/users/reducer';

import {
  reducer as mastersReducer,
  initialState as mastersInitialState,
  State as MastersState,
} from 'stores/persist/masters/reducer';

export interface State {
  users: UsersState;
  masters: MastersState;
}

export const [reducer, initialState]: [
  Reducer<State, Action<any>>,
  State,
] = combineReducers({
  users: [usersReducer, usersInitialState],
  masters: [mastersReducer, mastersInitialState],
});
