import {
  reducerWithInitialState,
  ReducerBuilder,
} from 'typescript-fsa-reducers';
import Actions, { SnackBarOnPayload } from 'stores/unPersist/snackbar/actions';
import { Color } from '@material-ui/lab/Alert';

export interface State {
  isView: boolean;
  severity?: Color;
  message: string;
}

export const initialState: State = {
  isView: false,
  severity: undefined,
  message: '',
};

export const reducer: ReducerBuilder<State> = reducerWithInitialState(
  initialState,
) //@ts-ignore 'state' is declared but its value is never read.
  .case(Actions.snackbarOn, (state: State, payload: SnackBarOnPayload) => {
    return {
      isView: true,
      severity: payload.severity,
      message: payload.message,
    };
  })
  .case(Actions.snackbarOff, () => {
    return initialState;
  });
