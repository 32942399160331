import React from 'react';
import GenericTemplate from 'components/templates/genericTemplate/GenericTemplate';

interface Props {
  children?: React.ReactNode;
}

const Default: React.FC<Props> = (props) => {
  return <GenericTemplate>{props.children}</GenericTemplate>;
};

export default Default;
