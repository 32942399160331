import React, { useEffect } from 'react';
import { BaseProps } from '../../types/componentType';
import useTitle from '../../hooks/generic/titleSet';
import {
  CustomChildrenForm,
  CustomForm,
} from '../../components/organisms/page/CustomValidFormItem';
import * as Yup from 'yup';
import { useMasterInterviews } from '../../hooks/api/mastersHook';
import * as valids from 'constants/valids';
import StoreErrorDialog from '../../components/organisms/event/StoreErrorDialog';
import CustomEdit from '../../components/organisms/page/CustomEdit';
import useCustomForm from '../../hooks/generic/customForm';
import {
  useInterviewsEdit,
  useInterviewsShow,
} from '../../hooks/api/interviewsHooks';

interface Props extends BaseProps {}

const InterviewEdit: React.FC<Props> = (props) => {
  useTitle(props.title);

  const mastersHooks = useMasterInterviews();
  const showHooks = useInterviewsShow();
  const editHooks = useInterviewsEdit();

  const [{ isLoading, contents }] = mastersHooks;
  const [
    { isLoading: sIsLoading, contents: sContents },
    { setOption: sDoFetch },
  ] = showHooks;
  const [{ option }, { setOption: doSubmit }] = editHooks;

  const forms: CustomForm[][] = [
    [
      // {
      //   name: 'loan_plan_id',
      //   label: '出向計画ID',
      //   type: 'hidden',
      //   defaultValue: parseInt(props.match.params.loan_plan_id),
      // },
      // {
      //   name: 'suggestion_id',
      //   label: '提案ID',
      //   type: 'hidden',
      //   defaultValue: parseInt(props.match.params.suggestion_id),
      // },
      {
        name: 'user_in_charge_id',
        label: '営業担当者',
        type: 'select',
        selects: contents.users,
        defaultValue: 0,
        descendant: 'user_in_charge_id',
        valid: Yup.number()
          .required(valids.MSG_REQUIRES)
          .oneOf(
            (contents.users ?? []).map((obj: any) => obj.id),
            valids.MSG_IN,
          ),
      },
      {
        name: 'attendance_user_id',
        label: '面談同席者',
        type: 'select',
        selects: contents.users,
        defaultValue: 0,
        descendant: 'attendance_user_id',
        valid: Yup.number()
          .required(valids.MSG_REQUIRES)
          .oneOf(
            (contents.users ?? []).map((obj: any) => obj.id),
            valids.MSG_IN,
          ),
      },
    ],
    [
      {
        name: 'order',
        label: '面談回数（何回目）',
        type: 'num',
        // defaultValue: 1,
        descendant: 'order',
        disabled: true,
        isNotSend: true,
        valid: Yup.number().required(valids.MSG_REQUIRES),
      },
      {
        name: 'start_datetime',
        label: '面談開始時間',
        type: 'datetime',
        defaultValue: new Date(),
        descendant: 'start_datetime',
        valid: Yup.date().required(valids.MSG_REQUIRES),
      },
      {
        name: 'end_datetime',
        label: '面談終了時間',
        type: 'datetime',
        defaultValue: new Date(),
        descendant: 'end_datetime',
        valid: Yup.date().required(valids.MSG_REQUIRES),
      },
    ],
  ];

  const formsChildren: CustomChildrenForm[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'hidden',
      defaultValue: null,
      descendant: 'id',
    },
    {
      name: 'suggestion_project_id',
      label: '案件名',
      type: 'select',
      selects: contents.suggestion_project,
      defaultValue: 0,
      descendant: 'suggestion_project_id',
      valid: Yup.number()
        .required(valids.MSG_REQUIRES)
        .oneOf(
          (contents.suggestion_project ?? []).map((obj: any) => obj.id),
          valids.MSG_IN,
        ),
    },
  ];

  const childrenForms: CustomForm[] = [
    {
      name: 'interview_projects',
      label: '面談案件',
      type: 'array',
      xs: 4,
      params: formsChildren,
      descendant: 'interview_projects',
    },
  ];

  // 初期ロード時、のみ実行をする。
  useEffect(() => {
    sDoFetch({ id: props.match.params.id });
  }, [props.match.params.id, sDoFetch]);

  const customForm = useCustomForm(
    forms,
    childrenForms,
    isLoading || sIsLoading,
    sContents,
  );
  const formHooks = customForm.formHooks;
  return (
    <>
      <StoreErrorDialog />
      <CustomEdit
        id={props.match.params.id}
        createHooks={editHooks}
        formHooks={formHooks}
        submitHandler={customForm.toSubmitHandler(
          doSubmit,
          option,
          props.match.params.id,
        )}
        forms={customForm.value}
        childrenForms={customForm.childrenValue}
        resource={props.resource}
        isLoading={isLoading}
      />
    </>
  );
};

export default InterviewEdit;
