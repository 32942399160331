import React from 'react';
import { getDescendant } from '../../../utils/format';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

interface Props {
  children?: React.ReactNode;
  column: any;
  content: any;
}

const ListTableCell: React.FC<Props> = (props) => {
  // const option = props.column.option.text;
  // const replaces = option.match(/{[^{]*}/g) ?? [];
  const target = getDescendant(props.content, props.column.option.target) ?? [];
  // const rep = (rowText: string, content: any, { index }: { index: number }) => {
  //   rowText = rowText.replace('{index}', String(index));
  //
  //   replaces.map((text: string) => {
  //     console.log(text);
  //     return (rowText = rowText.replace(
  //       text,
  //       getDescendant(content, text.replace('{', '').replace('}', '')),
  //     ));
  //   });
  //
  //   return rowText;
  // };

  const rep1 = formatDescendant(props.column.option.text);
  const rep2 = formatDescendant(props.column.option.path ?? '');
  function formatDescendant(texts: any) {
    const replaces = texts.match(/{[^{]*}/g) ?? [];
    const rep = (
      rowText: string,
      content: any,
      { index }: { index: number },
    ) => {
      rowText = rowText.replace('{index}', String(index));
      replaces.map((text: string) => {
        return (rowText = rowText.replace(
          text,
          getDescendant(content, text.replace('{', '').replace('}', '')),
        ));
      });

      return rowText;
    };
    return rep;
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography> {target.length}件のアイテム </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid>
          {target.map((r: any, i: number) => {
            return (
              <Grid item xs={12}>
                <Link
                  to={rep2(props.column.option.path ?? '', r, { index: i })}
                >
                  {rep1(
                    JSON.parse(JSON.stringify(props.column.option.text)),
                    r,
                    { index: i },
                  )}
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ListTableCell;
