import React, { useState } from 'react';
import FormSubmit from '../../organisms/page/FormSubmit';
import { useForm } from 'react-hook-form';
import {
  useApiResponseNg,
  useApiResponseOkAndTransition,
} from '../../../hooks/api/generic/apiResponseHandling';
import { useStaffsFiles } from '../../../hooks/api/staffsHooks';
import UploadButton from './UploadButton';
import { StaffFileType } from '../../../constants/staffFiles';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

interface Props {
  children?: React.ReactNode;
  type: StaffFileType;
  staffId: number;
}

interface UploadFile {
  file?: File;
  base64: string;
}

const FileUpload: React.FC<Props> = (props) => {
  const [
    { response, isLoading, isError, option },
    { setOption },
  ] = useStaffsFiles();

  const [uploadFile, setUploadFile] = useState<UploadFile>({
    file: undefined,
    base64: '',
  });

  const { handleSubmit } = useForm({
    mode: 'onBlur',
  });

  const history = useHistory();

  useApiResponseOkAndTransition(
    response,
    '登録に成功しました。',
    history.location.pathname,
  );
  useApiResponseNg(isError, '登録に失敗しました');

  const submitHandler = () => {
    const changedParam = {
      ...option.json,
      base64: uploadFile.base64,
      name: uploadFile.file?.name,
      type: props.type,
      extension: uploadFile.file?.name.split('.').pop(),
    };

    setOption({ ...option, json: changedParam, id: props.staffId });
  };
  return (
    <>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={6}>
            <UploadButton
              uploadFile={uploadFile}
              setUploadFile={setUploadFile}
            />
          </Grid>
          <Grid item xs={6}>
            <FormSubmit isLoading={isLoading}>登録</FormSubmit>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default FileUpload;
