import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../assets/styles/ts/theme';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getDescendant } from '../../utils/format';
import { useUserAuth } from '../../hooks/api/usersHooks';
import actions from '../../stores/unPersist/snackbar/actions';
import userActions from '../../stores/persist/users/actions';
import { StoreContext } from '../../stores/unPersist/store';
import { PersistStoreContext } from '../../stores/persist/store';
import { useHistory } from 'react-router-dom';

const Login: React.FC<any> = () => {
  const { dispatch } = useContext(StoreContext);
  const { dispatch: persistDispatch } = useContext(PersistStoreContext);
  const history = useHistory();
  const authHooks = useUserAuth();
  const [
    { contents, response, isError, isLoading },
    { setOption: doAuth },
  ] = authHooks;

  const classes = makeStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: 400,
      margin: `${theme.spacing(0)} auto`,
    },
    loginBtn: {
      marginTop: theme.spacing(2),
      flexGrow: 1,
    },
    header: {
      textAlign: 'center',
      background: '#212121',
      color: '#fff',
    },
    card: {
      marginTop: theme.spacing(10),
    },
  })();

  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: {
      mail: 'nagashima@roucus.test',
      password: 'pass',
    },
    resolver: yupResolver(
      Yup.object().shape({
        mail: Yup.string().required('必須項目です').email(),
        password: Yup.string().required('必須項目です'),
      }),
    ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formHooks;

  const submitHandler = (data: any) => {
    const changedParam = {
      mail: data.mail,
      password: data.password,
    };

    doAuth({ json: changedParam });
  };

  useEffect(() => {
    if (response.status === 401) {
      if (isError) {
        dispatch(
          actions.snackbarOn({
            severity: 'error',
            message: '認証に失敗しました。',
          }),
        );
      }
    }
  }, [response]);

  useEffect(() => {
    if (response.status === 200) {
      dispatch(
        actions.snackbarOn({
          severity: 'success',
          message: '認証に成功しました。',
        }),
      );

      // storeにtokenを入れる
      persistDispatch(userActions.userSignin(contents));

      history.push('/');
    }
  }, [contents]);

  return (
    <form
      className={classes.container}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(submitHandler)}
    >
      <Card className={classes.card}>
        <CardHeader
          className={classes.header}
          title="ROUCUSにログインしましょう!"
        />
        <CardContent>
          <div>
            <Controller
              control={control}
              name={'mail'}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="username"
                  label={'メールアドレス'}
                  fullWidth
                  margin="normal"
                  helperText={getDescendant(errors, 'mail')?.message}
                  error={getDescendant(errors, 'mail')}
                  variant="outlined"
                />
              )}
            />
            <Controller
              control={control}
              name={'password'}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="password"
                  label={'パスワード'}
                  fullWidth
                  margin="normal"
                  type={'password'}
                  helperText={getDescendant(errors, 'password')?.message}
                  error={getDescendant(errors, 'password')}
                  variant="outlined"
                />
              )}
            />
          </div>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            type="submit"
            className={classes.loginBtn}
            disabled={isLoading}
          >
            Login
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default Login;
