import React from 'react';
import { BaseProps } from '../../types/componentType';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useTitle from '../../hooks/generic/titleSet';
// import * as valids from '../../constants/valids';
import SimpleCreate from '../../components/organisms/page/SimpleCreate';
import { CreateForm } from '../../components/organisms/page/ValidFormItem';
import { useLoansEnd } from '../../hooks/api/loansHooks';
import { formatApiDataAll } from '../../utils/format';

interface Props extends BaseProps {}

const LoansCompleted: React.FC<Props> = (props) => {
  useTitle(props.title);

  const editHooks = useLoansEnd();
  const [{ option }, { setOption: doEdit }] = editHooks;

  const validSchema = Yup.object().shape({
    // end_date: Yup.date().required(valids.MSG_REQUIRES),
  });

  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: {
      end_date: new Date(),
    },
    resolver: yupResolver(validSchema),
  });

  const submitHandler = (data: any) => {
    const changedParam = {
      ...option.json,
      ...formatApiDataAll(data),
    };
    doEdit({ ...option, json: changedParam, id: props.match.params.id });
  };

  const forms: CreateForm[] = [
    {
      name: 'end_date',
      label:
        '出向終了日（こちらを入れると日付を過ぎ次第、出向終了扱いになります）',
      type: 'date',
    },
  ];

  return (
    <>
      <SimpleCreate
        createHooks={editHooks}
        formHooks={formHooks}
        submitHandler={submitHandler}
        forms={forms}
        resource={props.resource}
      />
    </>
  );
};

export default LoansCompleted;
