import SingleTextField from '../../molecules/form/SingleTextField';
import { Control, FieldErrors } from 'react-hook-form/dist/types';
import Selectbox, { FormSelects } from '../../molecules/form/Selectbox';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import MultilineTextArea from '../../molecules/form/MultilineTextArea';
import DateInput from '../../molecules/form/DateInput';

export interface Props {
  control: any;
  errors: any;
  rows: any;
}

export interface CreateForm {
  name: string;
  label: string;
  type: 'text' | 'select' | 'textarea' | 'date';
  selects?: FormSelects[];
  disabled?: boolean;
  defaultValue?: any;
  onChange?: (e: any, setValue: any) => void;
}

export interface EditForms {
  name: string;
  label: string;
  type: string;
  selects?: FormSelects[];
  descendant: string;
  defaultValue?: any;
  disabled?: boolean;
  onChange?: (e: any, setValue: any) => void;
}

const ValidFormItem: React.FC<Props> = (props) => {
  const classes = makeStyles({
    gridRecord: {
      margin: '24px',
      textAlign: 'right',
    },
  })();

  const getForm = (
    control: Control<any, any>,
    errors: FieldErrors,
    row: CreateForm | EditForms,
  ) => {
    switch (row.type) {
      case 'text':
        return (
          <SingleTextField
            control={control}
            errors={errors}
            name={row.name}
            label={row.label}
          />
        );
      case 'select':
        return (
          <Selectbox
            control={control}
            errors={errors}
            name={row.name}
            label={row.label}
            items={row.selects ?? []}
            disabled={row.disabled ?? false}
          />
        );
      case 'date':
        return (
          <DateInput
            control={control}
            errors={errors}
            name={row.name}
            label={row.label}
          />
        );
      case 'textarea':
        return (
          <MultilineTextArea
            control={control}
            errors={errors}
            name={row.name}
            label={row.label}
          />
        );
      default:
        return <div />;
    }
  };

  const gridItems = (
    control: Control<any, any>,
    errors: FieldErrors,
    rows: any,
  ) =>
    rows.map((row: CreateForm | EditForms, index: number) => (
      <React.Fragment key={index}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          {getForm(control, errors, row)}
        </Grid>
        <Grid item xs={2} />
      </React.Fragment>
    ));
  return (
    <Grid container className={classes.gridRecord}>
      {gridItems(props.control, props.errors, props.rows)}
    </Grid>
  );
};

export default ValidFormItem;
