import React from 'react';
import { getDescendant } from '../../../utils/format';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

interface Props {
  children?: React.ReactNode;
  column: any;
  content: any;
}

const useStyles = makeStyles(
  createStyles({
    default: {
      // 改行差し込み
      whiteSpace: 'pre-wrap',
    },
    redColor: {
      color: 'red',
    },
  }),
);

const DefaultTableCell: React.FC<Props> = (props) => {
  const classes = useStyles();

  let value = getDescendant(props.content, props.column.id);

  if (props.column.option?.conversion?.length > 0) {
    const target = props.column.option.conversion.find(
      (e: { id: number; name: string }) => e.id === value,
    );
    if (typeof target !== 'undefined') {
      value = target.name;
    }
  }

  if (props.column.format && typeof value === 'number') {
    value = props.column.format(value);
  }

  if (props.column.isBool) {
    value =
      value === {} || (value.length ?? false) === 0 || value === false
        ? ''
        : '◯';
  }

  if (typeof props.column.option?.explode === 'object') {
    if (Array.isArray(value)) {
      value = value.reduce(
        (p: any, c: any) => p + c[props.column.option.explode.target] + ' / ',
        '',
      );
      value = value.slice(0, -3);
    }
  }

  const colorChangeCallback =
    props.column.option?.colorChange?.callback ?? (() => false);

  const calcCallback =
    props.column.option?.calc?.callback ??
    //@ts-ignore 'contents' is declared but its value is never read.`
    ((contents: any, content: any) => content);

  return (
    <span
      className={clsx(
        classes.default,
        colorChangeCallback(props.content) && classes['redColor'],
      )}
    >
      {calcCallback(props.content, value) +
        (props.column.option?.unit?.last ?? '')}
    </span>
  );
};

export default DefaultTableCell;
