import {
  actionCreatorFactory,
  ActionCreatorFactory,
  ActionCreator,
} from 'typescript-fsa';

export interface UiPayload {
  title: string;
}

export interface Actions {
  setTitle: ActionCreator<UiPayload>;
}

const actionCreator: ActionCreatorFactory = actionCreatorFactory();

const actions: Actions = {
  setTitle: actionCreator('SET_TITLE'),
};

export default actions;
