import React from 'react';
import { Controller } from 'react-hook-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Control } from 'react-hook-form/dist/types/form';
import { FormHelperText } from '@material-ui/core';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import FormControl from '@material-ui/core/FormControl';
import { getDescendant } from '../../../utils/format';

interface Props {
  children?: React.ReactNode;
  control: Control<any, any>;
  errors: FieldErrors<any>;
  name: string;
  label: string;
}

const SingleCheckbox: React.FC<Props> = (props) => {
  const error = getDescendant(props.errors, props.name);

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { value, onChange } }) => (
        <FormControl error>
          <FormControlLabel
            control={
              <Checkbox checked={value} onChange={onChange} color="primary" />
            }
            label={props.label}
          />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default SingleCheckbox;
