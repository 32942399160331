import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

export interface Props {
  children?: React.ReactNode;
  isLoading: boolean;
}

const FormSubmit: React.FC<Props> = (props) => {
  const classes = makeStyles({
    gridRecord: {
      marginTop: '24px',
    },
  })();

  return (
    <Grid container justify="flex-end" className={classes.gridRecord}>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={props.isLoading}
      >
        {props.children}
      </Button>
    </Grid>
  );
};

export default FormSubmit;
