import React from 'react';
import SimpleCreate from '../../components/organisms/page/SimpleCreate';
import { useMSkillsCreate } from '../../hooks/api/mSkillsHook';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useTitle from '../../hooks/generic/titleSet';
import { BaseProps } from '../../types/componentType';
import { useMasterMSkills } from '../../hooks/api/mastersHook';
import { CreateForm } from '../../components/organisms/page/ValidFormItem';

interface Props extends BaseProps {}

const MMilestonesCreate: React.FC<Props> = (props) => {
  useTitle(props.title);

  const createHooks = useMSkillsCreate();
  const mastersHooks = useMasterMSkills();

  const [{ option }, { setOption: doCreate }] = createHooks;

  const [{ isLoading, contents }] = mastersHooks;

  const validSchema = Yup.object().shape({
    name: Yup.string().required('必須項目です'),
    category: Yup.string().oneOf(
      (contents.category ?? []).map((obj: any) => obj.id),
      'いずれかを選択してください',
    ),
  });

  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: '',
      category: '',
    },
    resolver: yupResolver(validSchema),
  });

  const submitHandler = (data: any) => {
    const changedParam = {
      ...option.json,
      name: data.name,
      category: data.category,
    };
    doCreate({ ...option, json: changedParam });
  };

  const forms: CreateForm[] = [
    { name: 'name', label: '名前', type: 'text' },
    {
      name: 'category',
      label: 'カテゴリー',
      type: 'select',
      selects: contents.category,
    },
  ];

  return (
    <>
      <SimpleCreate
        createHooks={createHooks}
        formHooks={formHooks}
        submitHandler={submitHandler}
        forms={forms}
        resource={props.resource}
        isLoading={isLoading}
      />
    </>
  );
};

export default MMilestonesCreate;
