import React from 'react';

import DetailTable from '../../molecules/detailTable/DetailTable';
import { ApiStates } from '../../../hooks/api/type/ApiStates';
import { useMasterSuggestions } from '../../../hooks/api/mastersHook';

interface Props {
  children?: React.ReactNode;
  id: number;
  resource: string;
  showHooks: ApiStates<any, any>;
}

const SuggestionDefaultInfoTable: React.FC<Props> = (props) => {
  const mastersHooks = useMasterSuggestions();
  const [{ contents }] = mastersHooks;

  const columns = [
    [
      { id: 'id', label: 'ID', minWidth: 50 },
      { id: 'user_in_charge.name', label: '担当者', minWidth: 100 },
      { id: 'end_date', label: '終了日', minWidth: 100 },
      { id: 'last_client.name', label: '上位', minWidth: 100 },
      { id: 'end_client.name', label: 'エンド', minWidth: 100 },
      {
        id: 'next_action',
        label: '次回アクション',
        minWidth: 100,
        option: { conversion: contents.next_action },
      },
      {
        id: 'completion_reason',
        label: '完了理由',
        minWidth: 100,
        option: { conversion: contents.completion_reason },
      },
      {
        id: 'completion_memo',
        label: '完了理由詳細',
        minWidth: 100,
        style: { textAlign: 'left' },
      },
    ],
    [
      {
        id: 'suggestion_checksheets.0.checked',
        label: 'suggestion_checksheets.0.type',
        minWidth: 100,
        isBool: true,
        option: { headerConversion: contents.suggestion_checksheets_type },
      },
      {
        id: 'suggestion_checksheets.1.checked',
        label: 'suggestion_checksheets.1.type',
        minWidth: 100,
        isBool: true,
        option: { headerConversion: contents.suggestion_checksheets_type },
      },
      {
        id: 'suggestion_checksheets.2.checked',
        label: 'suggestion_checksheets.2.type',
        minWidth: 100,
        isBool: true,
        option: { headerConversion: contents.suggestion_checksheets_type },
      },
      {
        id: 'suggestion_checksheets.3.checked',
        label: 'suggestion_checksheets.3.type',
        minWidth: 100,
        isBool: true,
        option: { headerConversion: contents.suggestion_checksheets_type },
      },
    ],
  ];

  return (
    <>
      <DetailTable
        columns={columns}
        showHooks={props.showHooks}
        id={props.id}
        resource={props.resource}
        title={'提案基本情報'}
      />
    </>
  );
};

export default SuggestionDefaultInfoTable;
