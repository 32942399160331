import React from 'react';

import TableBody, { SimpleTableColumn } from '../page/TableBody';
import { CircularProgress } from '@material-ui/core';
import { ApiStates } from '../../../hooks/api/type/ApiStates';
import { useMasterSuggestions } from '../../../hooks/api/mastersHook';

interface Props {
  children?: React.ReactNode;
  id: number;
  resource: string;
  showHooks: ApiStates<any, any>;
}

const SuggestionTable: React.FC<Props> = (props) => {
  const [
    { contents, option, isLoading },
    { setOption: sDoFetch },
  ] = props.showHooks;

  const mastersHooks = useMasterSuggestions();

  const [{ contents: mContents }] = mastersHooks;

  const columns: SimpleTableColumn[] = [
    { id: 'id', label: 'ID', minWidth: 50 },
    { id: 'last_client.name', label: '上位', minWidth: 100 },
    { id: 'end_client.name', label: 'エンド', minWidth: 100 },
    { id: 'user_in_charge.name', label: '担当者', minWidth: 100 },
    {
      id: 'next_action',
      label: '次回アクション',
      minWidth: 100,
      option: { conversion: mContents.next_action },
    },
    {
      id: 'isInterviewPractice',
      label: '面談練習したか',
      minWidth: 100,
      isBool: true,
    },
    {
      id: 'completion_reason',
      label: '完了理由',
      minWidth: 100,
      option: { conversion: mContents.completion_reason },
    },
    // { id: 'm_projects.name', label: '案件名 / 最寄り駅', minWidth: 100 },
    { id: 'end_date', label: '終了日', minWidth: 100 },
    { id: 'created_at', label: '作成日', minWidth: 100 },
    {
      id: 'detail_button',
      label: '詳細',
      option: { link: `/suggestions/{:id}` },
      minWidth: 100,
    },
  ];

  const rows = contents?.suggestions ?? [];

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <TableBody
          rows={rows}
          columns={columns}
          option={option}
          setOption={sDoFetch}
          contents={contents}
          resource={props.resource}
          title={'提案状況'}
        />
      )}
    </>
  );
};

export default SuggestionTable;
