import React, { useContext } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { StoreContext } from '../../../stores/unPersist/store';
import actions from '../../../stores/unPersist/snackbar/actions';

interface Props {
  children?: React.ReactNode;
}

const StoreSnackBar: React.FC<Props> = () => {
  const { state, dispatch } = useContext(StoreContext);

  //@ts-ignore 'event' is declared but its value is never read.
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(actions.snackbarOff(undefined));
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <>
      {/*12秒*/}
      <Snackbar
        autoHideDuration={12000}
        open={state.snackbar.isView}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={state.snackbar.severity}>
          {state.snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default StoreSnackBar;
