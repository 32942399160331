import React from 'react';

import SimpleIndex from '../../components/organisms/page/SimpleIndex';
import { useInput } from '../../hooks/generic/inputHook';
import useTitle from '../../hooks/generic/titleSet';
import { BaseProps } from '../../types/componentType';
import { useMasterStaffs } from '../../hooks/api/mastersHook';
import { useStaffsDelete, useStaffsIndex } from '../../hooks/api/staffsHooks';

interface Props extends BaseProps {}

const StaffIndex: React.FC<Props> = (props) => {
  useTitle(props.title);

  const indexHooks = useStaffsIndex();
  const deleteHooks = useStaffsDelete();
  const mastersHooks = useMasterStaffs();

  const [{ isLoading, contents }] = mastersHooks;

  const [{ option }, { setOption }] = indexHooks;
  /* eslint-disable no-empty-pattern */
  const [{}, { setOption: doDelete }] = deleteHooks;

  const columns = [
    { id: 'id', label: 'ID', minWidth: 100 },
    { id: 'name', label: '名前', minWidth: 100 },
    {
      id: 'age',
      label: '年齢',
      minWidth: 100,
      option: {
        unit: {
          last: 'ヶ月',
        },
      },
    },
    { id: 'client.name', label: '所属', minWidth: 100 },
    {
      id: 'status',
      label: 'ステータス',
      minWidth: 100,
      option: { conversion: contents.status },
    },
    {
      id: 'active_loan_plans',
      label: '営業計画あり',
      isBool: true,
      minWidth: 100,
    },
    { id: 'created_at', label: '作成日', minWidth: 100 },
    { id: 'detail_button', label: '詳細', minWidth: 100 },
    // { id: 'edit_button', label: '編集', minWidth: 100 },
    // { id: 'delete_button', label: '削除', minWidth: 100 },
  ];

  const firstName = useInput('', 'text', '性');
  const kanaFirstName = useInput('', 'text', 'せい');
  const lastName = useInput('', 'text', '名');
  const kanaLastName = useInput('', 'text', 'めい');
  const clientId = useInput([], 'select', '所属', {
    selects: contents.clients ?? [],
    isMultiple: true,
  });
  const mOccupationIds = useInput([], 'select', '役職', {
    selects: contents.m_occupations ?? [],
    isMultiple: true,
  });
  const mMilestoneIds = useInput([], 'select', '工程', {
    selects: contents.m_milestones ?? [],
    isMultiple: true,
  });
  const mSkillIds = useInput([], 'select', 'スキル', {
    selects: contents.m_skills ?? [],
    isMultiple: true,
  });

  const activeLoanPlansEx = useInput(false, 'checkbox', '営業計画ありのみ');

  const handleSearchSubmit = () => {
    const changedSearchParam = {
      ...option.searchParams,
      first_name_like: firstName.value,
      last_name_like: lastName.value,
      first_name_kana_like: kanaFirstName.value,
      last_name_kana_like: kanaLastName.value,
      client_id_in: clientId.value,
      staff_occupation_m_occupation_id_in: mOccupationIds.value,
      staff_milestone_m_milestone_id_in: mMilestoneIds.value,
      staff_skill_m_skill_id_in: mSkillIds.value,
      active_loan_plans_ex: activeLoanPlansEx.value,
    };
    setOption({ ...option, searchParams: changedSearchParam });
  };

  const handleDeleteClick = (id: number) => {
    return () => {
      if (window.confirm('削除してもよろしいですか？')) {
        doDelete({ id: id });
      }
    };
  };

  return (
    <>
      <SimpleIndex
        isCreate={true}
        indexHooks={indexHooks}
        deleteHooks={deleteHooks}
        columns={columns}
        tableSearchStates={[
          firstName,
          lastName,
          kanaFirstName,
          kanaLastName,
          clientId,
          mOccupationIds,
          mMilestoneIds,
          mSkillIds,
          activeLoanPlansEx,
        ]}
        handleSearchSubmit={handleSearchSubmit}
        handleDeleteClick={handleDeleteClick}
        resource={props.resource}
        isLoading={isLoading}
      />
    </>
  );
};

export default StaffIndex;
