import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { StoreContext } from '../../../stores/unPersist/store';
import actions from '../../../stores/unPersist/errorDialog/actions';
import { makeStyles } from '@material-ui/core/styles';

interface Props {}
const StoreErrorDialog: React.FC<Props> = () => {
  const classes = makeStyles({
    content: {
      padding: '32px',
      minWidth: '320px',
    },
  })();

  const { state, dispatch } = useContext(StoreContext);
  const closeHandle = () => {
    dispatch(actions.errorDialogOff(undefined));
  };

  return (
    <Dialog
      open={state.errorDialog.isOpen}
      onClose={closeHandle}
      scroll="paper"
    >
      {/* タイトル */}
      <DialogTitle>入力エラーが発生しました</DialogTitle>

      {/* 画像とページ数 */}
      <DialogContent dividers={true} className={classes.content}>
        <DialogContentText tabIndex={-1}>
          {Object.keys(state.errorDialog.errors).map((key) => (
            <Typography key={key}>
              {key}:{' '}
              {state.errorDialog.errors[key].map((row: string) => (
                <div>{row}</div>
              ))}
            </Typography>
          ))}
        </DialogContentText>
      </DialogContent>

      {/* 閉じるボタン */}
      <DialogActions>
        <Button onClick={closeHandle}>閉じる</Button>
      </DialogActions>
    </Dialog>
  );
};

export default StoreErrorDialog;
