import React from 'react';

import DetailTable from '../../molecules/detailTable/DetailTable';
import { ApiStates } from '../../../hooks/api/type/ApiStates';

interface Props {
  children?: React.ReactNode;
  id: number;
  resource: string;
  showHooks: ApiStates<any, any>;
}

const InterviewDefaultInfoTable: React.FC<Props> = (props) => {
  const columns = [
    [
      { id: 'user_in_charge.name', label: '担当営業' },
      { id: 'attendance_user.name', label: '面談同席者' },
      { id: 'last_client.name', label: '上位' },
      { id: 'end_client.name', label: 'エンド' },
    ],
    [
      { id: 'order', label: 'X次面談' },
      { id: 'start_datetime', label: '面談開始時間' },
      { id: 'end_datetime', label: '面談終了時間' },
    ],
  ];

  return (
    <>
      <DetailTable
        columns={columns}
        showHooks={props.showHooks}
        id={props.id}
        resource={props.resource}
        title={'面談基本情報'}
      />
    </>
  );
};

export default InterviewDefaultInfoTable;
