import React from 'react';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../assets/styles/ts/theme';
import { Link } from 'react-router-dom';

interface Props {
  children?: React.ReactNode;
  to: string;
  type: 'warning' | 'info';
}

const useStyles = makeStyles(
  createStyles({
    warning: {
      background: theme.palette.warning.main,
      color: 'white',
      '&:hover': {
        background: theme.palette.warning.dark,
      },
      textDecoration: 'none',
      marginRight: '12px',
    },
    info: {
      background: theme.palette.info.main,
      color: 'white',
      '&:hover': {
        background: theme.palette.info.dark,
      },
      textDecoration: 'none',
      marginRight: '12px',
    },
  }),
);

const LinkButton: React.FC<Props> = (props) => {
  const classes = useStyles();

  const className = () => {
    return classes[props.type];
  };
  return (
    <Link to={props.to}>
      <Button variant="contained" className={className()} size="small">
        {props.children}
      </Button>
    </Link>
  );
};

export default LinkButton;
