import React, { useEffect } from 'react';
import { BaseProps } from '../../types/componentType';
import useTitle from '../../hooks/generic/titleSet';
import CustomCreate from '../../components/organisms/page/CustomCreate';
import {
  CustomChildrenForm,
  CustomForm,
} from '../../components/organisms/page/CustomValidFormItem';
import useCustomForm from '../../hooks/generic/customForm';
import * as Yup from 'yup';
import { useMasterInterviews } from '../../hooks/api/mastersHook';
import * as valids from 'constants/valids';
import StoreErrorDialog from '../../components/organisms/event/StoreErrorDialog';
import { useInterviewsCreate } from '../../hooks/api/interviewsHooks';

interface Props extends BaseProps {}

const InterviewsCreate: React.FC<Props> = (props) => {
  useTitle(props.title);

  const mastersHooks = useMasterInterviews(false);
  const createHooks = useInterviewsCreate();
  const [{ isLoading, contents }, { setOption: doMaster }] = mastersHooks;
  const [{ option }, { setOption: doCreate }] = createHooks;

  useEffect(() => {
    doMaster({
      searchParams: { suggestion_id: props.match.params.suggestion_id },
    });
  }, []);

  const formsChildren: CustomChildrenForm[] = [
    {
      name: 'suggestion_project_id',
      label: '案件名',
      type: 'select',
      selects: contents.suggestion_project,
      defaultValue: 0,
      valid: Yup.number()
        .required(valids.MSG_REQUIRES)
        .oneOf(
          (contents.suggestion_project ?? []).map((obj: any) => obj.id),
          valids.MSG_IN,
        ),
    },
  ];

  const forms: CustomForm[][] = [
    [
      {
        name: 'loan_plan_id',
        label: '出向計画ID',
        type: 'hidden',
        defaultValue: parseInt(props.match.params.loan_plan_id),
      },
      {
        name: 'suggestion_id',
        label: '提案ID',
        type: 'hidden',
        defaultValue: parseInt(props.match.params.suggestion_id),
      },
      {
        name: 'user_in_charge_id',
        label: '営業担当者',
        type: 'select',
        selects: contents.users,
        defaultValue: 0,
        valid: Yup.number()
          .required(valids.MSG_REQUIRES)
          .oneOf(
            (contents.users ?? []).map((obj: any) => obj.id),
            valids.MSG_IN,
          ),
      },
      {
        name: 'attendance_user_id',
        label: '面談同席者',
        type: 'select',
        selects: contents.users,
        defaultValue: 0,
        valid: Yup.number()
          .required(valids.MSG_REQUIRES)
          .oneOf(
            (contents.users ?? []).map((obj: any) => obj.id),
            valids.MSG_IN,
          ),
      },
    ],
    [
      {
        name: 'order',
        label: '面談回数（何回目）',
        type: 'num',
        defaultValue: 1,
        valid: Yup.number().required(valids.MSG_REQUIRES),
      },
      {
        name: 'start_datetime',
        label: '面談開始時間',
        type: 'datetime',
        defaultValue: new Date(),
        valid: Yup.date().required(valids.MSG_REQUIRES),
      },
      {
        name: 'end_datetime',
        label: '面談終了時間',
        type: 'datetime',
        defaultValue: new Date(),
        valid: Yup.date().required(valids.MSG_REQUIRES),
      },
    ],
  ];

  const childrenForms: CustomForm[] = [
    {
      name: 'interview_projects',
      label: '面談案件',
      type: 'array',
      xs: 4,
      params: formsChildren,
    },
  ];

  const customForm = useCustomForm(forms, childrenForms, isLoading);
  const formHooks = customForm.formHooks;
  return (
    <>
      <StoreErrorDialog />
      <CustomCreate
        createHooks={createHooks}
        formHooks={formHooks}
        submitHandler={customForm.toSubmitHandler(doCreate, option)}
        forms={customForm.value}
        childrenForms={customForm.childrenValue}
        resource={props.resource}
        isLoading={isLoading}
      />
    </>
  );
};

export default InterviewsCreate;
