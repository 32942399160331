import { ApiStates } from './type/ApiStates';
import { useApiMasterBase } from './generic/apiBaseHooks';
import { ApiIndexOptions } from './type/ApiIndex';

// 一覧
export const useMasterMSkills = (): ApiStates<any, ApiIndexOptions> => {
  return useApiMasterBase('masters/m_skills');
};

export const useMasterMProjects = (): ApiStates<any, ApiIndexOptions> => {
  return useApiMasterBase('masters/m_projects');
};

export const useMasterClients = (): ApiStates<any, ApiIndexOptions> => {
  return useApiMasterBase('masters/clients');
};

export const useMasterStaffs = (): ApiStates<any, ApiIndexOptions> => {
  return useApiMasterBase('masters/staffs');
};

export const useMasterLoanPlans = (): ApiStates<any, ApiIndexOptions> => {
  return useApiMasterBase('masters/loan_plans');
};

export const useMasterSuggestions = (
  isAuto: boolean = true,
): ApiStates<any, ApiIndexOptions> => {
  return useApiMasterBase('masters/suggestions', isAuto);
};

export const useMasterInterviews = (
  isAuto: boolean = true,
): ApiStates<any, ApiIndexOptions> => {
  return useApiMasterBase('masters/interviews', isAuto);
};

export const useMasterLoans = (): ApiStates<any, ApiIndexOptions> => {
  return useApiMasterBase('masters/loans');
};
