import {
  reducerWithInitialState,
  ReducerBuilder,
} from 'typescript-fsa-reducers';
import Actions, { UiPayload } from 'stores/unPersist/ui/actions';

export interface State {
  title: string;
}

export const initialState: State = {
  title: '',
};

export const reducer: ReducerBuilder<State> = reducerWithInitialState(
  initialState,
) //@ts-ignore 'state' is declared but its value is never read.
  .case(Actions.setTitle, (state: State, payload: UiPayload) => {
    return {
      title: payload.title,
    };
  });
