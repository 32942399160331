import { ApiStates } from './type/ApiStates';
import ApiIndexContents, { initContents } from './type/ApiContents';
import {
  useApiBase,
  useApiCreateBase,
  useApiDeleteBase,
  useApiEditBase,
  useApiIndexBase,
} from './generic/apiBaseHooks';
import { ApiIndexOptions } from './type/ApiIndex';
import { ApiShowOptions } from './type/ApiShow';
import { useContext, useState } from 'react';
import baseKy from '../../kys';
import { PersistStoreContext } from '../../stores/persist/store';

const resource = 'staffs';
// 一覧
export const useStaffsIndex = (): ApiStates<
  ApiIndexContents<any>,
  ApiIndexOptions
> => {
  return useApiIndexBase(resource);
};

// 詳細
export const useStaffsShow = (): ApiStates<any, ApiShowOptions> => {
  const [option, setOption] = useState({ id: 0, searchParams: {} });

  const check = () => {
    return option.id !== 0;
  };
  const { state } = useContext(PersistStoreContext);
  const doApi = async () => {
    return new baseKy(state.users?.token).client.get(
      `${resource}/${option.id}`,
      { searchParams: option.searchParams },
    );
  };

  return useApiBase(option, setOption, doApi, check, initContents);
};

// 作成
export const useStaffsCreate = (): ApiStates<any, any> => {
  return useApiCreateBase(resource);
};

// 更新
export const useStaffsEdit = (): ApiStates<any, any> => {
  return useApiEditBase(resource);
};

// 削除
export const useStaffsDelete = (): ApiStates<any, any> => {
  return useApiDeleteBase(resource);
};

// ファイル登録
export const useStaffsFiles = (): ApiStates<any, any> => {
  const [option, setOption] = useState({ id: 0, searchParams: {} });

  const check = () => {
    return option.id !== 0;
  };
  const { state } = useContext(PersistStoreContext);
  const doApi = async () => {
    return new baseKy(state.users?.token).client.post(
      `${resource}/${option.id}/files`,
      option,
    );
  };

  return useApiBase(option, setOption, doApi, check);
};
