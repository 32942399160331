import ky from 'ky';

// TODO: clientをanyにしない
export default class baseKy {
  client: typeof ky;
  token: string;

  constructor(token: string | null) {
    this.token = token ?? '';
    this.client = ky.create({
      prefixUrl: process.env.REACT_APP_API_HOST,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
      timeout: 5000, // milliseconds
      retry: 3,
    });
  }
}
