import {
  actionCreatorFactory,
  ActionCreatorFactory,
  ActionCreator,
} from 'typescript-fsa';

export interface ErrorDialogOnPayload {
  errors: any;
}

export interface Actions {
  errorDialogOn: ActionCreator<ErrorDialogOnPayload>;
  errorDialogOff: ActionCreator<void>;
}

const actionCreator: ActionCreatorFactory = actionCreatorFactory();

const actions: Actions = {
  errorDialogOn: actionCreator('ERROR_DIALOG_ON'),
  errorDialogOff: actionCreator('ERROR_DIALOG_OFF'),
};

export default actions;
