import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import MSelect from '@material-ui/core/Select';
import { MenuItem } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface Props {
  children?: React.ReactNode;
  row: any;
}

const useStyles = makeStyles(
  createStyles({
    searchTextBox: {
      paddingBottom: '12px',
      paddingLeft: '6px',
      textAlign: 'left',
    },
    selectBox: {
      width: '70%',
    },
  }),
);

const Select: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <InputLabel variant="standard" htmlFor="uncontrolled-native">
        {props.row.label}
      </InputLabel>
      <MSelect
        value={props.row.value}
        onChange={props.row.onChange}
        multiple={props.row.isMultiple ?? false}
        className={classes.selectBox}
      >
        <MenuItem value=""> --- </MenuItem>
        {props.row.selects.map((select: any) => (
          <MenuItem key={select.id} value={select.id}>
            {select.name}
          </MenuItem>
        ))}
      </MSelect>
    </>
  );
};

export default Select;
