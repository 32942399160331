import React from 'react';

import TableBody, { SimpleTableColumn } from '../page/TableBody';
import { CircularProgress } from '@material-ui/core';
import { ApiStates } from '../../../hooks/api/type/ApiStates';

interface Props {
  children?: React.ReactNode;
  id: number;
  resource: string;
  showHooks: ApiStates<any, any>;
}

const InterviewTable: React.FC<Props> = (props) => {
  const [
    { contents, option, isLoading },
    { setOption: sDoFetch },
  ] = props.showHooks;

  const columns: SimpleTableColumn[] = [
    { id: 'id', label: 'ID', minWidth: 50 },
    // { id: 'last_client.name', label: '上位', minWidth: 100 },
    // { id: 'end_client.name', label: 'エンド', minWidth: 100 },
    { id: 'user_in_charge.name', label: '担当者', minWidth: 100 },
    { id: 'attendance_user.name', label: '面談同席者', minWidth: 100 },
    // {
    //   id: 'm_project_status',
    //   label: '案件名 / 最寄り駅',
    //   minWidth: 100,
    //   type: 'list',
    //   option: {
    //     target: 'm_projects',
    //     text: '{index}: {name} / {nearest_station}',
    //   },
    // },
    { id: 'order', label: 'X次面談', minWidth: 100 },
    { id: 'start_datetime', label: '面談開始時間', minWidth: 100 },
    { id: 'end_datetime', label: '面談終了時間', minWidth: 100 },

    { id: 'created_at', label: '作成日', minWidth: 100 },
    {
      id: 'detail_button',
      label: '詳細',
      option: { link: `/interviews/{:id}` },
      minWidth: 100,
    },
  ];

  const rows = contents?.interviews ?? [];

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <TableBody
          rows={rows}
          columns={columns}
          option={option}
          setOption={sDoFetch}
          contents={contents}
          resource={props.resource}
          title={'面談状況'}
        />
      )}
    </>
  );
};

export default InterviewTable;
