import React from 'react';
import { Control } from 'react-hook-form/dist/types/form';
import SingleTextField from './SingleTextField';
import { CustomForm } from '../../organisms/page/CustomValidFormItem';
import { FieldErrors } from 'react-hook-form/dist/types';
import Selectbox from './Selectbox';
import MultilineTextArea from './MultilineTextArea';
import ArrayForm from './ArrayForm';
import DateInput from './DateInput';
import SingleCheckbox from './SingleCheckbox';
import Order from './Order';
import HiddenInput from './Hidden';
import DateTimeInput from './DateTimeInput';

interface Props {
  children?: React.ReactNode;
  control: Control<any, any>;
  register: any;
  setValue: any;
  errors: any;
  rows: any;
  childrenIndex?: number;
  childrenName?: string;
}

const CustomFormByType: React.FC<Props> = (props) => {
  const getForm = (
    control: Control<any, any>,
    errors: FieldErrors,
    row: CustomForm,
  ) => {
    const name =
      typeof props.childrenIndex !== 'undefined' &&
      typeof props.childrenName !== 'undefined'
        ? `${props.childrenName}.${props.childrenIndex}.${row.name}`
        : row.name;
    switch (row.type) {
      case 'text':
        return (
          <SingleTextField
            control={control}
            errors={errors}
            name={name}
            label={row.label}
            disabled={row.disabled ?? false}
          />
        );
      case 'num':
        return (
          <SingleTextField
            control={control}
            errors={errors}
            name={name}
            label={row.label}
            type={'number'}
            disabled={row.disabled ?? false}
          />
        );
      case 'select':
        return (
          <Selectbox
            control={control}
            errors={errors}
            name={name}
            label={row.label}
            items={row.selects ?? []}
            disabled={row.disabled ?? false}
            onChange={row.onChange ?? (() => {})}
            setValue={props.setValue}
          />
        );
      case 'textarea':
        return (
          <MultilineTextArea
            control={control}
            errors={errors}
            name={name}
            label={row.label}
          />
        );
      case 'single_checkbox':
        return (
          <SingleCheckbox
            control={control}
            errors={errors}
            name={name}
            label={row.label}
          />
        );
      case 'date':
        return (
          <DateInput
            control={control}
            errors={errors}
            name={name}
            label={row.label}
          />
        );
      case 'datetime':
        return (
          <DateTimeInput
            control={control}
            errors={errors}
            name={name}
            label={row.label}
          />
        );
      case 'order':
        return (
          <Order
            control={control}
            name={name}
            index={props.childrenIndex}
            setValue={props.setValue}
          />
        );
      case 'hidden':
        return <HiddenInput control={control} name={name} />;

      case 'array':
        return (
          <ArrayForm
            register={props.register}
            control={control}
            setValue={props.setValue}
            errors={errors}
            name={name}
            label={row.label}
            rows={row.params}
            isStatic={row.isStatic}
            initAppendValue={row.initAppendValue}
            xs={row.xs}
          />
        );
      default:
        return <div />;
    }
  };

  return <>{getForm(props.control, props.errors, props.rows)}</>;
};

export default CustomFormByType;
