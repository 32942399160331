import React from 'react';
import { BaseProps } from '../../types/componentType';
import useTitle from '../../hooks/generic/titleSet';
import CustomCreate from '../../components/organisms/page/CustomCreate';
import {
  CustomChildrenForm,
  CustomForm,
} from '../../components/organisms/page/CustomValidFormItem';
import useCustomForm from '../../hooks/generic/customForm';
import * as Yup from 'yup';
import { useClientsCreate } from '../../hooks/api/clientsHooks';
import { useMasterClients } from '../../hooks/api/mastersHook';
import * as valids from 'constants/valids';
import StoreErrorDialog from '../../components/organisms/event/StoreErrorDialog';

interface Props extends BaseProps {}

const ClientsCreate: React.FC<Props> = (props) => {
  useTitle(props.title);

  const mastersHooks = useMasterClients();
  const createHooks = useClientsCreate();
  const [{ isLoading, contents }] = mastersHooks;
  const [{ option }, { setOption: doCreate }] = createHooks;

  const formsChildren: CustomChildrenForm[] = [
    {
      name: 'department',
      label: '部署',
      type: 'text',
      defaultValue: '',
      valid: Yup.string().required(valids.MSG_REQUIRES).max(255),
    },
    {
      name: 'user_in_charge_id',
      label: '担当者',
      type: 'select',
      selects: contents.users,
      defaultValue: '',
      valid: Yup.number()
        .required(valids.MSG_REQUIRES)
        .oneOf(
          (contents.users ?? []).map((obj: any) => obj.id),
          valids.MSG_IN,
        ),
    },
    {
      name: 'first_name_kana',
      label: '苗字(かな)',
      type: 'text',
      defaultValue: '',
      valid: Yup.string()
        .required(valids.MSG_REQUIRES)
        .max(255)
        .matches(valids.REG_KANA, valids.MSG_KANA),
    },
    {
      name: 'last_name_kana',
      label: '名前(かな)',
      type: 'text',
      defaultValue: '',
      valid: Yup.string()
        .required(valids.MSG_REQUIRES)
        .max(255)
        .matches(valids.REG_KANA, valids.MSG_KANA),
    },
    {
      name: 'first_name',
      label: '苗字',
      type: 'text',
      defaultValue: '',
      valid: Yup.string().max(255).required(valids.MSG_REQUIRES),
    },
    {
      name: 'last_name',
      label: '名前',
      type: 'text',
      defaultValue: '',
      valid: Yup.string().max(255).required(valids.MSG_REQUIRES),
    },
    {
      name: 'mail',
      label: 'メールアドレス',
      type: 'text',
      defaultValue: '',
      valid: Yup.string()
        .max(255)
        .required(valids.MSG_REQUIRES)
        .email(valids.MSG_EMAIL),
    },
    {
      name: 'phone_number',
      label: '電話番号',
      type: 'text',
      defaultValue: '',
      valid: Yup.string()
        .max(16)
        .required(valids.MSG_REQUIRES)
        .matches(valids.REG_TEL, valids.MSG_TEL),
    },
    {
      name: 'communication_cycle',
      label: '連絡周期',
      type: 'select',
      selects: contents.communication_cycle,
      defaultValue: '',
      valid: Yup.string()
        .required(valids.MSG_REQUIRES)
        .oneOf(
          (contents.communication_cycle ?? []).map((obj: any) => obj.id),
          valids.MSG_IN,
        ),
    },
    {
      name: 'forte',
      label: '得意領域',
      type: 'text',
      defaultValue: '',
      valid: Yup.string().max(512),
    },
    {
      name: 'trouble',
      label: 'トラブル内容',
      type: 'text',
      defaultValue: '',
      valid: Yup.string().max(512),
    },
  ];

  const forms: CustomForm[][] = [
    [
      {
        name: 'name',
        label: '会社名',
        type: 'text',
        defaultValue: '',
        valid: Yup.string().max(255).required(valids.MSG_REQUIRES),
      },
      {
        name: 'type',
        label: 'タイプ',
        type: 'select',
        selects: contents.type,
        defaultValue: '',
        valid: Yup.string()
          .required(valids.MSG_REQUIRES)
          .oneOf(
            (contents.type ?? []).map((obj: any) => obj.id),
            valids.MSG_IN,
          ),
      },
      {
        name: 'status',
        label: 'ステータス',
        type: 'select',
        selects: contents.status,
        defaultValue: '',
        valid: Yup.string()
          .required(valids.MSG_REQUIRES)
          .oneOf(
            (contents.status ?? []).map((obj: any) => obj.id),
            valids.MSG_IN,
          ),
      },
      {
        name: 'contract',
        label: '契約',
        type: 'select',
        selects: contents.contract,
        defaultValue: '',
        valid: Yup.string()
          .required(valids.MSG_REQUIRES)
          .oneOf(
            (contents.contract ?? []).map((obj: any) => obj.id),
            valids.MSG_IN,
          ),
      },
      {
        name: 'is_attending_interviews',
        label: '面談同席可否',
        type: 'single_checkbox',
        defaultValue: true,
        valid: Yup.boolean(),
      },
    ],
    [
      {
        name: 'phone_number',
        label: '本社電話番号',
        type: 'text',
        defaultValue: '',
        valid: Yup.string()
          .max(16)
          .matches(valids.REG_TEL, valids.MSG_TEL)
          .required(valids.MSG_REQUIRES),
      },
      {
        name: 'fax',
        label: 'ファックス',
        type: 'text',
        defaultValue: '',
        valid: Yup.string()
          .max(16)
          .matches(valids.REG_TEL, valids.MSG_TEL)
          .nullable()
          .transform((value, originalValue) =>
            String(originalValue).trim() === '' ? null : value,
          ),
      },
      {
        name: 'mail',
        label: 'メールアドレス',
        type: 'text',
        defaultValue: '',
        valid: Yup.string()
          .max(255)
          .email(valids.MSG_EMAIL)
          .nullable()
          .transform((value, originalValue) =>
            String(originalValue).trim() === '' ? null : value,
          ),
      },
      {
        name: 'address',
        label: '住所',
        type: 'text',
        defaultValue: '',
        valid: Yup.string()
          .max(255)
          .nullable()
          .transform((value, originalValue) =>
            String(originalValue).trim() === '' ? null : value,
          ),
      },
    ],
    [
      {
        name: 'url',
        label: 'URL',
        type: 'text',
        defaultValue: '',
        valid: Yup.string()
          .max(255)
          .nullable()
          .transform((value, originalValue) =>
            String(originalValue).trim() === '' ? null : value,
          )
          .url(),
      },
      {
        name: 'establishment_date',
        label: '設立年月日',
        type: 'date',
        defaultValue: new Date(),
        valid: Yup.date()
          .nullable()
          .transform((value, originalValue) =>
            String(originalValue).trim() === '' ? null : value,
          ),
      },
      {
        name: 'capital',
        label: '資本金(万円)',
        type: 'text',
        defaultValue: 1000,
        valid: Yup.number()
          .nullable()
          .transform((value, originalValue) =>
            String(originalValue).trim() === '' ? null : value,
          ),
      },
      {
        name: 'settlement_month',
        label: '決算月',
        type: 'text',
        defaultValue: 1,
        valid: Yup.number()
          .min(1)
          .max(12)
          .nullable()
          .transform((value, originalValue) =>
            String(originalValue).trim() === '' ? null : value,
          ),
      },
    ],
  ];

  const childrenForms: CustomForm[] = [
    {
      name: 'client_in_charges',
      label: '担当者',
      type: 'array',
      xs: 4,
      params: formsChildren,
    },
  ];

  const customForm = useCustomForm(forms, childrenForms, isLoading);
  const formHooks = customForm.formHooks;
  return (
    <>
      <StoreErrorDialog />
      <CustomCreate
        createHooks={createHooks}
        formHooks={formHooks}
        submitHandler={customForm.toSubmitHandler(doCreate, option)}
        forms={customForm.value}
        childrenForms={customForm.childrenValue}
        resource={props.resource}
        isLoading={isLoading}
      />
    </>
  );
};

export default ClientsCreate;
