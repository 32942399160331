import React from 'react';
import SimpleCreate from '../../components/organisms/page/SimpleCreate';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useTitle from '../../hooks/generic/titleSet';
import { BaseProps } from '../../types/componentType';
import { useMasterMProjects } from '../../hooks/api/mastersHook';
import { CreateForm } from '../../components/organisms/page/ValidFormItem';
import { useMProjectsCreate } from '../../hooks/api/mProjectsHook';
import { extractKeyValue, extractKeyValueByObject } from '../../utils/format';
import { validMProject } from './valid';

interface Props extends BaseProps {}

const MMilestonesCreate: React.FC<Props> = (props) => {
  useTitle(props.title);

  const createHooks = useMProjectsCreate();
  const mastersHooks = useMasterMProjects();

  const [{ option }, { setOption: doCreate }] = createHooks;

  const [{ isLoading, contents }] = mastersHooks;

  const validSchema = Yup.object().shape(validMProject(contents));

  const forms: CreateForm[] = [
    { name: 'name', label: '名前', type: 'text', defaultValue: '' },
    {
      name: 'client_id',
      label: 'クライアント',
      type: 'select',
      selects: contents.clients,
      defaultValue: 0,
    },
    { name: 'summary', label: '案件概要', type: 'textarea', defaultValue: '' },
    {
      name: 'nearest_station',
      label: '最寄り駅',
      type: 'text',
      defaultValue: '',
    },
    {
      name: 'skill_summary',
      label: 'スキル',
      type: 'textarea',
      defaultValue: '',
    },
    { name: 'memo_', label: '備考', type: 'textarea', defaultValue: '' },
  ];

  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: extractKeyValue(forms, 'name', 'defaultValue'),
    resolver: yupResolver(validSchema),
  });

  const submitHandler = (data: any) => {
    const changedParam = {
      ...option.json,
      ...extractKeyValueByObject(forms, data, 'name'),
      // NOTE: memoだと予約言葉らしいので「_」をつける
      memo: data.memo_,
    };
    doCreate({ ...option, json: changedParam });
  };

  return (
    <>
      <SimpleCreate
        createHooks={createHooks}
        formHooks={formHooks}
        submitHandler={submitHandler}
        forms={forms}
        resource={props.resource}
        isLoading={isLoading}
      />
    </>
  );
};

export default MMilestonesCreate;
