import { useContext, useState } from 'react';
import baseKy from '../../kys';
import { ApiStates } from './type/ApiStates';
import ApiIndexContents, { initContents } from './type/ApiContents';
import { useApiBase } from './generic/apiBaseHooks';
import { ApiIndexOptions } from './type/ApiIndex';
import { ApiShowOptions } from './type/ApiShow';
import { PersistStoreContext } from '../../stores/persist/store';

// 一覧
export const useMOccupationsIndex = (): ApiStates<
  ApiIndexContents<any>,
  ApiIndexOptions
> => {
  const [option, setOption] = useState({
    searchParams: { limit: 20, page: 1 },
  });
  const { state } = useContext(PersistStoreContext);
  const doApi = async () => {
    return new baseKy(state.users?.token).client.get('m_occupations', option);
  };

  return useApiBase(option, setOption, doApi, undefined, initContents);
};

// 詳細
export const useMOccupationsShow = (): ApiStates<any, ApiShowOptions> => {
  const [option, setOption] = useState({ id: 0 });

  const check = () => {
    return option.id !== 0;
  };
  const { state } = useContext(PersistStoreContext);
  const doApi = async () => {
    return new baseKy(state.users?.token).client.get(
      'm_occupations/' + option.id,
    );
  };

  return useApiBase(option, setOption, doApi, check);
};

// 作成
export const useMOccupationsCreate = (): ApiStates<any, any> => {
  const [option, setOption] = useState({
    json: {},
  });

  const check = () => {
    const length = Object.keys(option.json).length;
    return length !== 0;
  };
  const { state } = useContext(PersistStoreContext);
  const doApi = async () => {
    return new baseKy(state.users?.token).client.post('m_occupations', option);
  };

  return useApiBase(option, setOption, doApi, check);
};

// 更新
export const useMOccupationsEdit = (): ApiStates<any, any> => {
  const [option, setOption] = useState({ json: {}, id: 0 });

  const check = () => {
    const length = Object.keys(option.json).length;
    return !(option.id === 0 || length === 0);
  };
  const { state } = useContext(PersistStoreContext);
  const doApi = async () => {
    return new baseKy(state.users?.token).client.patch(
      'm_occupations/' + option.id,
      option,
    );
  };

  return useApiBase(option, setOption, doApi, check);
};

// 削除
export const useMOccupationsDelete = (): ApiStates<any, any> => {
  const [option, setOption] = useState({ id: 0 });

  const check = () => option.id !== 0;
  const { state } = useContext(PersistStoreContext);
  const doApi = async () => {
    return new baseKy(state.users?.token).client.delete(
      'm_occupations/' + option.id,
    );
  };

  return useApiBase(option, setOption, doApi, check);
};
