import React from 'react';
import SimpleCreate from '../../components/organisms/page/SimpleCreate';
import { useMOccupationsCreate } from '../../hooks/api/mOccupationsHook';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { BaseProps } from '../../types/componentType';
import useTitle from '../../hooks/generic/titleSet';
import { CreateForm } from '../../components/organisms/page/ValidFormItem';

interface Props extends BaseProps {}

const MOccupationsCreate: React.FC<Props> = (props) => {
  useTitle(props.title);

  const createHooks = useMOccupationsCreate();
  const [{ option }, { setOption: doCreate }] = createHooks;

  const validSchema = Yup.object().shape({
    name: Yup.string().required('必須項目です'),
  });

  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(validSchema),
  });

  const submitHandler = (data: any) => {
    const changedParam = {
      ...option.json,
      name: data.name,
    };
    doCreate({ ...option, json: changedParam });
  };

  const forms: CreateForm[] = [{ name: 'name', label: '名前', type: 'text' }];

  return (
    <>
      <SimpleCreate
        createHooks={createHooks}
        formHooks={formHooks}
        submitHandler={submitHandler}
        forms={forms}
        resource={props.resource}
      />
    </>
  );
};

export default MOccupationsCreate;
