import React, { useEffect } from 'react';
import TableBody, { SimpleTableColumn } from '../page/TableBody';
import { CircularProgress, Grid } from '@material-ui/core';
import { useInput } from '../../../hooks/generic/inputHook';
import Select from '../../molecules/searchForm/Select';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../assets/styles/ts/theme';
import { ApiStates } from '../../../hooks/api/type/ApiStates';

interface Props {
  children?: React.ReactNode;
  id: number;
  resource: string;
  showHooks: ApiStates<any, any>;
}

const useStyles = makeStyles(
  createStyles({
    tableUpload: theme.mixins.detailTable.main,
    select: {
      margin: '12px',
    },
  }),
);

const MonthlyLoanHistoriesTable: React.FC<Props> = (props) => {
  const classes = useStyles();

  const [
    { contents, option, isLoading },
    { setOption: sDoFetch },
  ] = props.showHooks;

  const columns: SimpleTableColumn[] = [
    { id: 'id', label: 'ID', minWidth: 100 },
    { id: 'loan.last_client.name', label: '上位', minWidth: 100 },
    { id: 'loan.end_client.name', label: 'エンド', minWidth: 100 },
    { id: 'year_month', label: '年月', minWidth: 100 },
    { id: 'charge', label: '金額', minWidth: 100 },
  ];

  //@ts-ignore 'r' is declared but its value is never read.
  const selects = [...Array(10)].map((r: any, i: number) => ({
    id: 2018 + i,
    name: 2018 + i,
  }));
  const year = useInput(
    new Date().getFullYear(),
    'select',
    '確認したい案件の年を選択',
    { selects: selects, isMultiple: false },
  );

  const rows = contents?.monthly_loan_histories ?? [];

  useEffect(() => {
    sDoFetch({
      id: props.id,
      searchParams: { monthly_loan_histories_year: year.value },
    });
  }, [year.value, props.id, sDoFetch]);

  return (
    <>
      {isLoading || !contents.id ? (
        <CircularProgress />
      ) : (
        <>
          <Grid container>
            <div className={classes.tableUpload}>
              <Grid item xs={4} className={classes.select}>
                <Select row={year} />
              </Grid>
              <Grid item xs={8}></Grid>
            </div>
            <Grid item xs={12}>
              <TableBody
                rows={rows}
                columns={columns}
                option={option}
                setOption={sDoFetch}
                contents={contents}
                resource={props.resource}
                title={'月単価履歴'}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default MonthlyLoanHistoriesTable;
