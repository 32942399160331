import React from 'react';

import DateSingle from './DateSingle';
import InputLabel from '@material-ui/core/InputLabel';

interface Props {
  children?: React.ReactNode;
  value: { from: Date | null; to: Date | null };
  onChangeFrom: any;
  onChangeTo: any;
  label: string;
}

const DateBetween: React.FC<Props> = (props) => {
  return (
    <>
      <InputLabel variant="standard" htmlFor="uncontrolled-native">
        {props.label}
      </InputLabel>
      <DateSingle value={props.value.from} onChange={props.onChangeFrom} />
      〜
      <DateSingle value={props.value.to} onChange={props.onChangeTo} />
    </>
  );
};

export default DateBetween;
