import { useEffect, useState } from 'react';
import {
  extractKeyValueByNest,
  formatApiDataAll,
  getDescendant,
} from '../../utils/format';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  CustomChildrenForm,
  CustomForm,
} from '../../components/organisms/page/CustomValidFormItem';

export default function useCustomForm(
  initialValue: any,
  childrenInitialValue: CustomForm[],
  isLoading: boolean,
  contents?: any,
) {
  const init: CustomForm[][] = Array.isArray(initialValue[0])
    ? initialValue
    : [initialValue];

  let [value, set] = useState(init);
  let [childrenValue, childrenSet] = useState(childrenInitialValue);

  const params = extractKeyValueByNest(value, 'name', 'defaultValue');
  let valids = extractKeyValueByNest(value, 'name', 'valid');
  // このバリデーションを抽出する
  let childrenValids: any = {};
  childrenInitialValue.map((row: CustomForm) => {
    childrenValids[row.name] = Yup.array().of(
      Yup.object()
        .required()
        .shape(extractKeyValueByNest([row.params], 'name', 'valid')),
    );
  });

  valids = {
    ...childrenValids,
    ...valids,
  };

  const formHooks = useForm({
    mode: 'onBlur',
    defaultValues: params,
    resolver: yupResolver(Yup.object().shape(valids)),
  });
  useEffect(() => {
    set(initialValue);
  }, [isLoading]);

  useEffect(() => {
    childrenSet(childrenInitialValue);
  }, [isLoading]);

  //edit from
  const { setValue } = formHooks;
  useEffect(() => {
    if (!Object.keys(contents ?? {}).length) {
      return;
    }
    value.map((rows: any) => {
      rows.map((row: any) => {
        let val = getDescendant(contents, row.descendant);
        if (row.type === 'date' || row.type === 'datetime') {
          if (val === '') {
            return;
          }
          val = new Date(val);
        }
        setValue(row.name, val);
      });
    });
  }, [contents]);

  useEffect(() => {
    if (!contents) {
      return;
    }
    const tmpChildrenValue: CustomForm[] = childrenValue.map((row: any) => {
      let fetchedVal = getDescendant(contents, row.descendant);
      fetchedVal = fetchedVal !== '' ? fetchedVal : [];
      const initAppendValue = fetchedVal.map((r: any) => {
        return row.params?.reduce((prev: any, current: CustomChildrenForm) => {
          prev[current.name] = getDescendant(r, `${current.descendant}`);
          return prev;
        }, {});
      });
      return { ...row, initAppendValue: initAppendValue };
    });
    childrenSet(tmpChildrenValue);
  }, [contents]);

  // //edit to

  return {
    value,
    childrenValue,
    set,
    childrenSet,
    formHooks,
    toSubmitHandler: (doApi: any, option: any, id?: number) => {
      // FIXME: これ引数hooksでいいよね
      return (data: any) => {
        const params = [...value, childrenValue].reduce(
          (prev1: any, rows: CustomForm[]) => {
            const result = rows?.reduce((prev2: any, row: CustomForm) => {
              if (!row.isNotSend) {
                prev2[row.name] = data[row.name];
              }
              return prev2;
            }, {});
            return { ...prev1, ...result };
          },
          {},
        );

        const changedParam = {
          ...option.json,
          ...formatApiDataAll(params),
        };
        // console.log({ ...option, json: changedParam, id: id });
        // return;
        doApi({ ...option, json: changedParam, id: id });
      };
    },
  };
}
