import React from 'react';

import {
  useMMilestonesIndex,
  useMMilestonesDelete,
} from 'hooks/api/mMilestonesHook';
import SimpleIndex from '../../components/organisms/page/SimpleIndex';
import { useInput } from '../../hooks/generic/inputHook';
import useTitle from '../../hooks/generic/titleSet';
import { BaseProps } from '../../types/componentType';

interface Props extends BaseProps {}

const MMilestonesIndex: React.FC<Props> = (props) => {
  useTitle(props.title);

  const indexHooks = useMMilestonesIndex();
  const deleteHooks = useMMilestonesDelete();

  const [{ option }, { setOption }] = indexHooks;
  /* eslint-disable no-empty-pattern */
  const [{}, { setOption: doDelete }] = deleteHooks;

  const columns = [
    { id: 'id', label: 'ID', minWidth: 170 },
    { id: 'name', label: '名前', minWidth: 100 },
    { id: 'created_at', label: '作成日', minWidth: 100 },
    { id: 'edit_button', label: '編集', minWidth: 100 },
    { id: 'delete_button', label: '削除', minWidth: 100 },
  ];

  const name = useInput('', 'text', '名前');

  const handleSearchSubmit = () => {
    const changedSearchParam = {
      ...option.searchParams,
      name_like: name.value,
    };
    setOption({ ...option, searchParams: changedSearchParam });
  };

  const handleDeleteClick = (id: number) => {
    return () => {
      if (window.confirm('削除してもよろしいですか？')) {
        doDelete({ id: id });
      }
    };
  };

  return (
    <>
      <SimpleIndex
        isCreate={true}
        indexHooks={indexHooks}
        deleteHooks={deleteHooks}
        columns={columns}
        tableSearchStates={[name]}
        handleSearchSubmit={handleSearchSubmit}
        handleDeleteClick={handleDeleteClick}
        resource={props.resource}
      />
    </>
  );
};

export default MMilestonesIndex;
